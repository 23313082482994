import { forwardRef } from 'react';
import '../../design/inputs.css';

const Select = forwardRef((props, ref) => {

    const {
        data,
        label,
        direction, // row || column
        align, // start || center || end
        width,
        height,
        defaultValue,
        placeHolder,
        onChange,
        error
    } = props;

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: direction || 'column',
            alignItems: align || 'start',
            maxWidth: width || 300,
            BorderColor: '#9A7AD8',
            gap: 5
        },
        select: {
            height: height || 40
        }
    }


    const onChangeHandler = (e) => {
        onChange && onChange(e.target.value);
    }

    return (
        <div className="form-input" style={styles.wrapper}>
            {label && (<label>{label}</label>)}

            <div className={`input-wrap ${error ? 'validation-error' : ''}`}>
                <select
                    onChange={onChangeHandler}
                    style={styles.select}
                    defaultValue={defaultValue ?? placeHolder ? "placeholder-value" : null}
                    ref={ref}
                >
                    {(placeHolder && !defaultValue) && (
                        <option value="placeholder-value" disabled>{placeHolder}</option>
                    )}
                    {data.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.value}
                            >
                                {item.label}
                            </option>
                        )
                    })}
                </select>
                {error && (<div className='input-error-message'>{error}</div>)}
            </div>
        </div>
    )

})

Select.displayName = 'Select';

export default Select;