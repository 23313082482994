import React from 'react';
import Content from 'utils/Content.js';

const globalConf = require("global_conf.json");
const websiteConnection = globalConf.website_connection;


const host = websiteConnection.frontend_host;

class Breadcrumb extends React.Component {

    constructor(props) {
        super(props);
        this.title = props.title;
        this.path = props.path;
    }

    render() {
        return (
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                {
                    "@type": "ListItem",
                "position": 1,
                "name": ${Content.getValue("brand_name")},
                "item": "${host}"
                },
                {
                    "@type": "ListItem",
                "position": 2,
                "name": "${this.title}",
                "item": "${host}${this.path}"
                }
                ]
                }`}
            </script>
        )
    }
}

export default Breadcrumb;