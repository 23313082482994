import Content from 'utils/Content.js';
import 'design/reviews.css';
import StartRating from "components/widgets/StartRating";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

const ReviewsList = (props) => {

    const { reviews, onReviewEdit, onReviewDelete, mine } = props;

    const [menuToggle, setMenuToggle] = useState();
    const [currentOffer, setCurrentOffer] = useState(null);


    const onToggleMenu = (idx) => {
        setCurrentOffer(idx);
        setMenuToggle(!menuToggle);
    }


    const onEdit = (review) => {
        setMenuToggle(false);
        onReviewEdit(review)
    }

    const onDelete = (review) => {
        setMenuToggle(false);
        onReviewDelete(review);
    }


    return (
        <>
            {reviews.map((review, idx) => {

                return (
                    <div className="review-wrap flex-row" key={idx}>
                        <div className="review-img">
                            <img src={review.ImageUrl} />
                        </div>
                        <div className="flex-grow flex-column">
                            <div className="flex-row items-center space-between">
                                <div className="opacity-5 text10">{review.ReviewTopicName}</div>
                                <div className="review-action-btn relative">
                                    {mine ? <>
                                            <div className="horizontal-dots-btn" onClick={() => onToggleMenu(idx)}></div>
                                            <ul className={`dots-action-menu ${menuToggle && currentOffer == idx ? "show" : ""}`}  >
                                                <>
                                                {onReviewEdit && review.canEdit == 1 && (
                                                    <li onClick={() => onEdit(review)}>
                                                            <EditIcon sx={{ width: 18, height: 18 }} />
                                                            <span>
                                                                {Content.getValue('reviews_edit')}
                                                            </span>
                                                        </li>
                                                    )}
                                                    <li onClick={() => onDelete(review)}>
                                                        <DeleteIcon sx={{ fill: '#D13C3C', width: 18, height: 18 }} />
                                                        <span className='reviews-delete'>
                                                            {Content.getValue('reviews_delete')}
                                                        </span>
                                                    </li>
                                                </>
                                        </ul>
                                    </>
                                        : <></>
                                    }
                                    <div className="opacity-5 text10">{review.dateCreated}</div>
                                        </div>
                                
                            </div>
                            <div className="flex-row items-center space-between">
                                <div className="review-title">{review.Title}</div>
                            </div>
                            <div className="flex review-score">
                                <StartRating score={review.Rating} />
                            </div>
                            <div className="review-text">{review.ReviewText}</div>
                            <p className={`review-status status-${review.status}`}>{Content.getValue(`review_status_id_${review.status}`)}</p>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default ReviewsList;