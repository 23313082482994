import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Content from 'utils/Content.js';
const globalConf = require("global_conf.json");
const configuration = require('configuration.json');
const langData = configuration.languages

const websiteConnection = globalConf.website_connection;

const host = websiteConnection.frontend_host;

class MetaTags extends React.Component {

    constructor(props) {
        super(props);
        this.title = props.title;
        this.description = props.description;
        this.url = props.url;
    }

    render() {
        if (this.description !== '')
            return (
                <HelmetProvider>
                    <Helmet>
                        <meta name="description" content={this.description} />
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:site" content={`@${Content.getValue("brand_name")}`} />
                        <meta name="twitter:title" content={this.title} />
                        <meta name="twitter:description" content={this.description} />
                        <meta name="twitter:image" content={`${host}/images/treatcoin-social-image.png`} />
                        <meta property="og:title" content={this.title} />
                        <meta property="og:description" content={this.description} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`${host}${this.url}`} />
                        <meta property="og:image" content={`${host}/images/treatcoin-social-image.png`} />
                        <link rel="canonical" href={`${host}`} />
                        {langData.slice(2).map(language => (
                                (<link rel="alternate" key={language.url_code} hrefLang={language.url_code} href={`${host}${this.url}${language.url_code}`} />)
                        ))}
                        <link rel="alternate" hrefLang="x-default" href={host} />
                        <title>{this.title}</title>
                    </Helmet>
                </HelmetProvider>
            );
        return (
            <HelmetProvider>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                    <title>{this.title}</title>
                </Helmet>
            </HelmetProvider>
        );

    }

}

export default MetaTags;