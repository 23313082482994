import React from 'react';

function IntroSurveyQuizLayout({ children }) {

    return (
        <div className='intro-survey-quiz-layout desktop'>
            { children }
        </div>
    )
}

export default IntroSurveyQuizLayout;