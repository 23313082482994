import React from 'react';

import Content from 'utils/Content.js';

class BackToThePlayground extends React.Component {

    render() {
        return (
            <label className="checkbox-container allow-survey-data-sharing-container" htmlFor="allow3rdPartySharing">{Content.getValue("allow_third_party_survey_details_sharing")}
                <input type="checkbox" id="allow3rdPartySharing" name="allow3rdPartySharing" className="subscription-settings-checkbox" value="allow3rdPartySharing" />
                <span className="checkmark-checkbox"></span>
            </label>
        );
    }

}

export default BackToThePlayground;