import { forwardRef, useMemo, useState } from 'react';
import '../../design/inputs.css';
import OpenScreenSelect from 'components/options/Open_Screen_Select';

const globalConf = require('global_conf.json');

const countryPhonePrefixes = {"BR": "+55" }

if (globalConf.website_connection.environment === 'Development' || 
    globalConf.website_connection.environment === 'Test' || 
    globalConf.website_connection.environment === 'Stg') {
    countryPhonePrefixes["HR"]  = "+385";
}

const PhoneWithCountryCodeInput = forwardRef((props, ref) => {

    const {
        label,
        direction, // row || column
        align, // start || center || end
        width,
        height,
        placeholder,
        onChange,
        autoFocus,
        error,
        value,
        style
    } = props;

    const userPhonePrefix = countryPhonePrefixes['BR'];

    const [phone, setPhone] = useState(value || '')
    const [phonePrefix, setPhonePrefix] = useState(userPhonePrefix)

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: direction || 'row',
            alignItems: align || 'start',
            maxWidth: width || '100%',
            gap: 5,
        },
        input: {
            height: height || 40,
            width: '75%',
            textAlign: 'left',
            borderRadius: style?.borderRadius || 8,
            background: style?.background || 'transparent',
        },
        select: {
            width: '25%',
            border: 0,
            background: style?.background || 'transparent',
            padding: 0,
            height: height || 40,
            textIndent: 5
        },
        inputWrapper: {
            borderColor: '#9A7AD8',
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: style?.borderRadius || 8
        }
    }


    const onChangeHandler = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPhone(e.target.value)
            onChange && onChange({
                prefix: phonePrefix,
                phone: e.target.value,
            });
        }
    }

    const onCountryChangeHandler = (value) => {
        setPhonePrefix(value)
        onChange && onChange({
            prefix: value,
            phone: phone,
        });
    }

    const selectWithCountries = useMemo(() => (
        <OpenScreenSelect
            items={countryPhonePrefixes}
            value={phonePrefix}
            optionChangeHandler={onCountryChangeHandler}
        />
    ), [phonePrefix])

    return (
        <div className='form-input-phone' style={styles.wrapper}>
            {label && (<label>{label}</label>)}

            <div style={styles.inputWrapper} className={`input-wrap ${error ? 'validation-error' : ''}`}>
                <input type='hidden' ref={ref} value={phonePrefix + phone} />
                <div className='phone-select-with-countries'>
                    {selectWithCountries}
                </div>
                <input
                    type='tel'
                    onChange={onChangeHandler}
                    style={styles.input}
                    placeholder={placeholder}
                    value={phone}
                    autoFocus={autoFocus}
                    maxLength={14}
                />
                {error && (<div className='input-error-message'>{error}</div>)}
            </div>

        </div>
    )

})

PhoneWithCountryCodeInput.displayName = 'PhoneWithCountryCodeInput';

export default PhoneWithCountryCodeInput;