import React from 'react';
import Content from 'utils/Content.js';

class ConvertButton extends React.Component {
    render() {
        const clickHandler = this.props.clickHandler;

        return (
            <span>
                <button
                    className="convert-button"
                    onClick={clickHandler}
                >
                    { Content.getValue("convert_now_button_text") }
                </button>
            </span >
        );
    }
}

export default ConvertButton;