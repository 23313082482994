import React, { useState, useEffect } from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Payments from 'utils/Payments.js';
import Content from 'utils/Content.js';
import UserData from 'User_Data.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const paymentMethodTypes = configuration.payment_method_types;


const PaymentMethodsList = props => {

    const { onChange, defaultPaymentMethod } = props;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isRestricted, setIsRestricted] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(defaultPaymentMethod);

    
    useEffect(() => {
        getPaymentMethods();
    }, [])

    useEffect(() => {
        onChange(selectedMethod)
    }, [selectedMethod])


    const getPaymentMethods = async () => {
        try {
            const res = await ApiFacade.paymentMethodsList();

            if (res.ok) {
                const result = res.response;
                setPaymentMethods(result.paymentMethods);
                if(defaultPaymentMethod != paymentMethodTypes.plum){
                    setSelectedMethod(defaultPaymentMethod)
                }else{
                    setSelectedMethod(result.paymentMethods.plum ? paymentMethodTypes.plum : null)
                }
                
            } else if (res.unauthorized) {
                setIsRestricted(true);
            } else {
                setError(res.error);
            }

        } catch (error) {
            setError(error);
        }

        setIsLoaded(true);

    }


    const onSelect = (method) => {
        
        if(!paymentMethods.plum && selectedMethod != paymentMethodTypes.plum && method == paymentMethodTypes.plum){
            new Payments().addPaymentMethod(paymentMethodTypes.plum, paymentMethods, 'list', (e)=> {
                setPaymentMethods(e);
                setSelectedMethod(paymentMethodTypes.plum);
            });
        }

        if(method == paymentMethodTypes.plum && !paymentMethods.plum){
            return;
        }

        setSelectedMethod(prev => prev != method && method);
    }


    return (
        error
            ? (<div className="error-message red-error">{Backend.loadBackendMessages().errorMessage}</div>)
            : isRestricted
                ? (<div className="error-message red-error">{Backend.loadBackendMessages().restrictedMessage}</div>)
                : !isLoaded
                    ? (<div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>)
                    : (<>
                        <div  className="payment-methods-list-container">
                            <div className={`payment-method-container bg-gradient-2 ${selectedMethod == paymentMethodTypes.plum && paymentMethods.plum && "selected"}`} onClick={()=>onSelect(paymentMethodTypes.plum)}>
                                <img src={Images.imageURL(`payment-methods/giftbox.png`)} alt="Plum account" height="54"/>
                                <div className="capitalize font-14">{Content.getValue("payment_method_plum_aliase")}</div>
                                <div className="payment-method-data-text">{paymentMethods.plum ? paymentMethods.plum : Content.getValue("not_connected_label")}</div>
                            </div>
                            {UserData.getItem(userDataKeys.country) == "BR" && (
                                <div className={`payment-method-container bg-gradient-3 ${selectedMethod == paymentMethodTypes.pix && "selected"}`} onClick={()=>onSelect(paymentMethodTypes.pix)}>
                                    <img src={Images.imageURL(`payment-methods/pix-icon.png`)} alt="Pix account" height="54"/>
                                    <div className="payment-method-desc capitalize font-14 flex justify-center"><img src={Images.imageURL(`payment-methods/flag-brazil.png`)} alt="brazil" height="20"/>Pix</div>
                                </div>
                            )}
                        </div>
                    </>)
    )
}

export default PaymentMethodsList;