import React from 'react';
import QuizOptionWithLabel from 'components/options/Quiz_Option_With_Label.js';
import Content from 'utils/Content.js';

function OpenScreen({ data, changeHandler, prefix: phonePrefix }) {
    const openScreenQuestions = Object.values(Content.getValue("intro_survey_open_screen").questions);
    const openScreenRadio = Content.getValue("intro_survey_open_screen").radio;
    const openScreenRadioOptions = Object.values(openScreenRadio.options);
    const configuration = require('configuration.json');
    const surveyRadioValues = configuration.survey_radio_values;

    return (
        <>
            { openScreenQuestions.map(({ label, type, placeholder, optional_label, prefix }) => (
                <QuizOptionWithLabel
                    key={type}
                    type={type}
                    label={label}
                    placeholder={placeholder}
                    optional={optional_label}
                    data={data[type]}
                    prefix={phonePrefix}
                    changeTextHandler={(value) => changeHandler(type, value)}
                    changePhonePrefixHandler={(value) => changeHandler(prefix, value)}
                />
            ))}

            <label className="quiz-option-label desktop">{ openScreenRadio.label }</label>
            <form name={openScreenRadio.type} className='open-screen-radio-container desktop'>
                <div className='quiz-option-container open-screen'>
                    {
                        openScreenRadioOptions.map((each, idx) => (
                            <div 
                                key={each} 
                                className='open-screen-radio-item desktop'
                                onClick={() => changeHandler(openScreenRadio.type, surveyRadioValues[openScreenRadio.type][(idx + 1).toString()]) }    
                            >
                                <input 
                                    type='radio' 
                                    id={idx} 
                                    name={openScreenRadio.type} 
                                />
                                <label htmlFor={idx}>{ each }</label>
                            </div>
                        ))
                    }
                </div>
            </form>
        </>
    )
}

export default OpenScreen;