import React from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';

function CardProgram({ index, maximumHeight }) {
  return (
    <li className="affiliate-program-card" style={{ height: maximumHeight ? `${maximumHeight}px` : 'auto' }}>
      <img src={Images.imageURL(`affiliates/new-program-card-${index}.png`)} alt="" />
      <h3 className="affiliate-program-card-head">{Content.getValue(`affiliates_2_cards_head_${index}`)}</h3>
      <p className="text-desc-white">{Content.getValue(`affiliates_2_cards_desc_${index}`)}</p>
    </li>
  );
}

export default CardProgram;