export const UpperSVG = () => <svg className='how-it-works-popup-upper-svg' xmlns="http://www.w3.org/2000/svg" width="351" height="411" viewBox="0 0 351 411" fill="none">
    <g filter="url(#filter0_f_1134_91401)">
        <circle cx="306.031" cy="41.9248" r="111.536" fill="#5FC88F" />
    </g>
    <defs>
        <filter id="filter0_f_1134_91401" x="-62.8049" y="-326.911" width="737.672" height="737.672" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="128.65" result="effect1_foregroundBlur_1134_91401" />
        </filter>
    </defs>
</svg>;
export const BottomSVG = () => <svg className='how-it-works-popup-bottom-svg' xmlns="http://www.w3.org/2000/svg" width="351" height="599" viewBox="0 0 351 599" fill="none">
    <g filter="url(#filter0_f_1134_91400)">
        <circle cx="73.2412" cy="367.461" r="111.536" fill="url(#paint0_linear_1134_91400)" />
    </g>
    <defs>
        <filter id="filter0_f_1134_91400" x="-338.295" y="-44.0752" width="823.072" height="823.071" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_1134_91400" />
        </filter>
        <linearGradient id="paint0_linear_1134_91400" x1="17.4732" y1="249.22" x2="43.6894" y2="499.198" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4AABF5" />
            <stop offset="0.973958" stopColor="#EA4661" />
        </linearGradient>
    </defs>
</svg>;