import Images from 'utils/Images.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';

function TopRightCloseIcon(props) {

    return (
        <RedirectWithLang className={'intro-survey-starting-section-close'} isButton to={props.url}
        >
                <img
                    src={Images.imageURL('intro-survey-starting-section-close.png')}
                    alt='Close'
                    width='24'
                    height='24'
                /> 
        </RedirectWithLang >
    )
}

export default TopRightCloseIcon;