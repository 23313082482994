import React from 'react';
import Swal from 'sweetalert2';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import withReactContent from 'sweetalert2-react-content';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import DataValidator from 'utils/Data_Validator.js';
import AuthCode from 'react-auth-code-input';
import { format } from "date-fns";
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import ConvertText from 'components/text/Convert_Text.js';
import ConversionCalculator from 'components/payouts/Conversion_Calculator.js';
import JWTUtils from 'utils/JWT_Utils.js';


const configuration = require('configuration.json');

const businessLogic = configuration.business_logic;
const mixpanelEvents = configuration.mixpanel_events;

const plumCodeValidityTime = businessLogic.plum_code_validity_time;
const plumVerificationAttempts = businessLogic.plum_verification_attempts;
const plumVerificationCodeLength = businessLogic.plum_verification_code_length;

const paymentMethodTypes = configuration.payment_method_types;

let paxumPaymentMethodType = paymentMethodTypes.paxum;
let plumPaymentMethodType = paymentMethodTypes.plum;
let faucetPayPaymentMethodType = paymentMethodTypes.faucetpay;
let allPaymentMethodTypes = paymentMethodTypes.all;
let pixPaymentMethodTypes = paymentMethodTypes.pix;

const MySwal = withReactContent(Swal);

let thisComponent;

class Payments extends React.Component {


    constructor(props) {
        super(props);
        this.plumEmail = null;
        this.plumAttemptsLeft = plumVerificationAttempts;
        this.countdownInterval = null;
        thisComponent = this;
    }

    static showHint(type) {
        type = type?.toLowerCase();
        if (type === plumPaymentMethodType.toLowerCase()) {
            let swalHTML = "<p class='swal-payment-methods-text green-text'>" + Content.getValue("plum_hint_subtitle_1") + "</p>";
            swalHTML += "<ol class='swal-payment-methods-text'>" + Content.getValue("plum_hint_content_1") + "</ol>";
            swalHTML += "<p class='swal-payment-methods-text green-text'>" + Content.getValue("plum_hint_subtitle_2") + "</p>";
            swalHTML += "<ol class='swal-payment-methods-text'>" + Content.getValue("plum_hint_content_2") + "</ol>";

            MySwal.fire({
                title: '<h1 class="swal-payment-methods-title">' + Content.getValue("plum_title") + '</h1>',
                html: swalHTML,
                background: '#141629',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: Content.getValue("got_it_button_text"),
                customClass: {
                    confirmButton: 'payment-method-hint-confirm-button',
                    closeButton: 'swal2-payment-method-hint-close'
                }
            });
        }

        else if (type === paxumPaymentMethodType.toLowerCase()) {
            let swalHTML = "<p class='swal-payment-methods-text green-text'>" + Content.getValue("paxum_hint_subtitle_1") + "</p>";
            swalHTML += "<ol class='swal-payment-methods-text'>" + Content.getValue("paxum_hint_content_1") + "</ol>";
            swalHTML += "<p class='swal-payment-methods-text green-text'>" + Content.getValue("paxum_hint_subtitle_2") + "</p>";
            swalHTML += "<ol class='swal-payment-methods-text'>" + Content.getValue("paxum_hint_content_2") + "</ol>";

            MySwal.fire({
                title: '<h1 class="swal-payment-methods-title">' + Content.getValue("paxum_title") + '</h1>',
                html: swalHTML,
                background: '#141629',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: Content.getValue("got_it_button_text"),
                customClass: {
                    confirmButton: 'payment-method-hint-confirm-button',
                    closeButton: 'swal2-payment-method-hint-close'
                }
            });
        }

        else if (type === faucetPayPaymentMethodType.toLowerCase()) {
            let swalHTML = "<p class='swal-payment-methods-text green-text'>" + Content.getValue("faucetpay_hint_subtitle_1") + "</p>";
            swalHTML += "<ol class='swal-payment-methods-text'>" + Content.getValue("faucetpay_hint_content_1") + "</ol>";
            swalHTML += "<p class='swal-payment-methods-text green-text'>" + Content.getValue("faucetpay_hint_subtitle_2") + "</p>";
            swalHTML += "<ol class='swal-payment-methods-text'>" + Content.getValue("faucetpay_hint_content_2") + "</ol>";

            MySwal.fire({
                title: '<h1 class="swal-payment-methods-title">' + Content.getValue("faucetpay_title") + '</h1>',
                html: swalHTML,
                background: '#141629',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: Content.getValue("got_it_button_text"),
                customClass: {
                    confirmButton: 'payment-method-hint-confirm-button',
                    closeButton: 'swal2-payment-method-hint-close'
                }
            });
        }

        else if (type === allPaymentMethodTypes.toLowerCase()) {
            let swalHTML = "<p class='swal-payment-methods-text large-margin'>" + Content.getValue("payment_methods_hint_text_1") + "</p>";
            swalHTML += "<p class='swal-payment-methods-text green-text'>" + Content.getValue("payment_methods_hint_subtitle_1") + "</p>";
            swalHTML += "<p class='swal-payment-methods-text'>" + Content.getValue("payment_methods_hint_text_2") + "</p>";
            swalHTML += "<p class='swal-payment-methods-text'>" + Content.getValue("payment_methods_hint_text_5") + "</p>";
            swalHTML += "<p class='swal-payment-methods-text'>" + Content.getValue("payment_methods_hint_text_6") + "</p>";
            swalHTML += "<p class='swal-payment-methods-text'>" + Content.getValue("payment_methods_hint_text_7") + "</p>";

            MySwal.fire({
                title: '<h1 class="swal-payment-methods-title">' + Content.getValue("how_to_use_treatcoins_title") + '</h1>',
                html: swalHTML,
                background: '#141629',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: Content.getValue("got_it_button_text"),
                customClass: {
                    confirmButton: 'payment-method-hint-confirm-button',
                    closeButton: 'swal2-payment-method-hint-close'
                }
            });
        }

        else if (type === pixPaymentMethodTypes.toLowerCase()) {
            
            MySwal.fire({
                title: '<h1 class="swal-payment-methods-title">Pix</h1>',
                html: Content.getValue("payment_methods_pix_hint_text_1"),
                background: '#141629',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: Content.getValue("got_it_button_text"),
                customClass: {
                    confirmButton: 'payment-method-hint-confirm-button',
                    closeButton: 'swal2-payment-method-hint-close'
                }
            });
        }
    }

    static showConvertHint() {
        const popup = withReactContent(Swal);

        const html = <>
            <ConvertText />
        </>

        popup.fire({
            title: '<h1 class="swal-convert-title">' + Content.getValue("convert_alert_title") + '</h1>',
            html,
            background: '#141629',
            showCloseButton: true,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: Content.getValue("got_it_button_text"),
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                container: 'z-1300',
                title: 'margin-top-0',
                htmlContainer: 'swal2-html-convert-container',
                confirmButton: 'nfts-confirm-button',
                cancelButton: 'convert-cancel-button',
                closeButton: 'swal2-nfts-close margin-right-0'
            }
        });
        
    }

    static showConvertScreen() {
        const popup = MySwal;
        const html = <div>
            <div className="withdraw-text-container margin-top-0">
                <span className="withdraw-text">{Content.getValue("convert_to_competitions_label")}</span>
                <img src={Images.imageURL('withdraw-hint.png')} alt="Withdrawal hint" width="20" className="withdraw-hint" onClick={() => Payments.showConvertHint()} />
            </div>
            <ConversionCalculator />
        </div>;

        popup.fire({
            html,
            background: '#141629',
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                container: 'padding-0 radius-0',
                popup: 'fullscreen radius-0',
                title: 'margin-top-0',
                htmlContainer: 'margin-0',
                confirmButton: 'nfts-confirm-button',
                closeButton: 'swal2-nfts-close'
            }
        });
    }

    static paymentMethodAddCTA(type, paymentMethods, callback) {
        let ctaText = null;
        let ctaImageURL = null;
        if (type === plumPaymentMethodType) {
            ctaText = Content.getValue("add_plum_cta");
            ctaImageURL = Images.imageURL('payment-methods/plum-icon-purple.png');
        }
        else if (type === paxumPaymentMethodType) {
            ctaText = Content.getValue("add_paxum_cta");
            ctaImageURL = Images.imageURL('payment-methods/paxum-icon-purple.png');
        }
        else if (type === faucetPayPaymentMethodType) {
            ctaText = Content.getValue("add_faucetpay_cta");
            ctaImageURL = Images.imageURL('payment-methods/faucetpay-icon-purple.png');
        }
        if (ctaText !== null) {
           
            let oldCtaDiv = document.getElementById('payment-method-cta-div');
            if (oldCtaDiv !== null) document.getElementById('content').removeChild(oldCtaDiv);
            let ctaDiv = document.createElement('div');
            ctaDiv.id = 'payment-method-cta-div';
            ctaDiv.className = 'payment-method-cta-div desktop';
            ctaDiv.innerHTML = '<img class="payment-method-cta-image" width="64" height="64" alt="Payment method CTA" src="' + ctaImageURL + '"/><div class="payment-method-cta-text desktop">' + 
                ctaText + 
                `<span><img width="16" height="16" alt="Arrow" src="${Images.imageURL('arrow-forward.png')}" /></span>` + 
                '</div>';
            ctaDiv.onclick = function () { new Payments().addPaymentMethod(type, paymentMethods, 'withdrawal', callback); };
            document.getElementById('content').appendChild(ctaDiv);
        }
    }

    addPaymentMethod(type, paymentMethods, mode, callback) {
        if (type === plumPaymentMethodType) {
            Mixpanel.trackByArgs(mixpanelEvents.connect_payment_method, { connect_plum_clicked: true });
            this.addPlumMethod(paymentMethods, mode, callback);
        }
    }

    setDate(date) {

        let dateDisplay = format(date, "yyyy-MM-dd");
        document.getElementById('paxum-birth-date').value = dateDisplay;
        thisComponent.setState = { date: dateDisplay };

    }

    addPlumMethod(paymentMethods, mode, callback) {
        thisComponent.plumAttemptsLeft = plumVerificationAttempts;
        let swalHTML =
            <>
                <img alt='Add plum method' className='swal-payment-methods-icon' src={Images.imageURL('payment-methods/plum-process-icon.png')} height='49' />
                <p className='swal-add-payment-method-title'> {Content.getValue("payment_methods_add_plum_text")} </p>
                <input id='email' className='swal-payment-methods-input' type='email' maxLength='250' placeholder={Content.getValue("enter_email_address_placeholder")} />
                <div id='email-error' className='swal-invisible-message'>{Content.getValue("invalid_email_address_error")}</div>
            </>;
        MySwal.fire({
            html: swalHTML,
            background: '#ffffff',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("get_verification_code_button_text"),
            customClass: {
                actions: 'w-full margin-top-10',
                popup: 'payment-method-popup',
                confirmButton: 'payment-method-get-verification-button',
                 
            },
            preConfirm: function () {
                let emailInput = document.getElementById('email');
                let emailError = document.getElementById('email-error');
                let email = emailInput.value;

                if (DataValidator.isValidEmail(email) === false) {
                    emailInput.className = 'swal-payment-methods-input error';
                    emailError.className = 'swal-payment-methods-input-error';
                    setTimeout(function () {
                        emailInput.className = 'swal-payment-methods-input';
                        emailError.className = 'swal-invisible-message';
                    }, 3000);
                    return false;
                }
                else {

                    Mixpanel.trackByArgs(mixpanelEvents.email_verification_send_code, {});
                    const req = ApiFacade.requestPlum(email);
                    req.then(function (res) {
                        let result = res.response;
                        if (res.ok){
                            thisComponent.plumEmail = email;
                            thisComponent.plumAttemptsLeft = thisComponent.plumAttemptsLeft - 1;
                            thisComponent.confirmAddPlumMethod(paymentMethods, mode, callback);
                        } else {
                            emailInput.className = 'swal-payment-methods-input error';
                            emailError.className = 'swal-payment-methods-input-error';

                            let text = null;
                            if (result && result.message){
                                text = Content.getValue(result.message);
                            } else {
                                text = Backend.loadBackendMessages().errorMessage;
                            }
                            emailError.innerHTML = text;

                            setTimeout(function () {
                                emailInput.className = 'swal-payment-methods-input';
                                emailError.className = 'swal-invisible-message';
                                emailError.innerHTML = Content.getValue("invalid_email_address_error");
                            }, 3000);
                        }
                    });
                }
                return false;
            }
        });
    }

    confirmAddPlumMethod(paymentMethods, mode, callback) {
        let myAuthCode = '';
        thisComponent.plumAttemptsLeft = plumVerificationAttempts;
        thisComponent.setCountdownInterval();

        let timeLeft = plumCodeValidityTime;
        let timeExpression = new Date(timeLeft * 1000).toISOString().substring(14, 19);

        let swalHTML =
            <>
                <img alt='Swal withdrawl' className='swal-payment-methods-icon' src={Images.imageURL("payment-methods/plum-process-icon.png")} height='49'></img>
                <p className='swal-add-payment-method-title'>{Content.getValue("verification_code_label")}</p>
                <div>
                    <AuthCode
                        id='auth-code'
                        length={plumVerificationCodeLength}
                        onChange={(code) => { myAuthCode = code; }}
                        containerClassName='verification-code-input'
                        inputClassName='verification-code-input-item'
                    />
                    <p className='swal-auth-code-error' id='swal-auth-code-error'></p>
                </div>
                <div className="verification-code-expire-time" id="countdown-timer">{Formatting.format(Content.getValue("code_will_expire_in_label"), ["(time)"], [timeExpression])}</div>
            </>;

        MySwal.fire({
            html: swalHTML,
            background: '#ffffff',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("submit_button_text"),
            customClass: {
                actions: 'w-full margin-top-10',
                popup: 'payment-method-popup',
                confirmButton: 'payment-method-get-verification-button',
                 
            },
            preConfirm: function () {

                let swalAuthCodeError = document.getElementById('swal-auth-code-error');

                if (myAuthCode.length !== plumVerificationCodeLength) {
                    thisComponent.setAuthCodeError();
                } else {

                    clearInterval(thisComponent.countdownInterval);

                    document.getElementById('countdown-timer').innerHTML = '';

                    thisComponent.removeAuthCodeError();

                    const req = ApiFacade.addPlum(thisComponent.plumEmail, myAuthCode);
                    req.then(function (res) {
                        if (res.ok){
                            //let result = res.response;
                            if (paymentMethods !== null) {
                                callback({...paymentMethods , 'plum':thisComponent.plumEmail})
                            }
                            thisComponent.addPaymentMethodSuccess(mode);
                        } else if (res.badRequest){
                            //let result = res.response;
                            thisComponent.setAuthCodeError();
                            swalAuthCodeError.className = 'swal-auth-code-error error';
                            swalAuthCodeError.innerHTML = Content.getValue("plum_wrong_code") + ' <a id="plum-send-code-again">' + Content.getValue("plum_send_again") + '</a>';
                            document.getElementById('plum-send-code-again').onclick = function () { thisComponent.resendPlumCode(); };
                            let swalButton = document.getElementsByClassName('swal2-confirm payment-method-get-verification-button swal2-styled')[0];
                            swalButton.disabled = true;
                        } else {
                            swalAuthCodeError.className = 'swal-auth-code-error error';
                            swalAuthCodeError.innerHTML = Backend.loadBackendMessages().errorMessage;
                            setTimeout(function () {
                                swalAuthCodeError.className = 'swal-auth-code-error';
                                swalAuthCodeError.innerHTML = '';
                            }, 3000);
                        }
                    });
                }
                return false;
            }
        });
    }

    resendPlumCode() {
        if (thisComponent.plumAttemptsLeft === 0) {
            let swalAuthCodeError = document.getElementById('swal-auth-code-error');
            let swalButton = document.getElementsByClassName('swal2-confirm payment-method-get-verification-button swal2-styled')[0];
            swalAuthCodeError.className = 'swal-auth-code-error error';
            swalAuthCodeError.innerHTML = Content.getValue("allowed_verification_attempts_exceeded");
            swalButton.disabled = true;
        }
        else {
            const req = ApiFacade.requestPlum(thisComponent.plumEmail);
            req.then(function (res) {
                let swalAuthCodeError = document.getElementById('swal-auth-code-error');
                if (res.ok){
                    let swalButton = document.getElementsByClassName('swal2-confirm payment-method-get-verification-button swal2-styled')[0];
                    swalButton.disabled = false;
                    swalAuthCodeError.className = 'swal-auth-code-error';
                    swalAuthCodeError.innerHTML = '';
                    thisComponent.plumAttemptsLeft = thisComponent.plumAttemptsLeft - 1;
                    thisComponent.removeAuthCodeError();
                } else if (res.badRequest || res.forbidden){
                    let result = res.response;

                    swalAuthCodeError.className = 'swal-auth-code-error error';
                    swalAuthCodeError.innerHTML = Content.getValue(result.message);
                    let swalButton = document.getElementsByClassName('swal2-confirm payment-method-get-verification-button swal2-styled')[0];
                    swalButton.disabled = true;

                    setTimeout(function () {
                        swalAuthCodeError.className = 'swal-auth-code-error';
                        swalAuthCodeError.innerHTML = '';
                    }, 3000);
                } else {
                    swalAuthCodeError.className = 'swal-auth-code-error error';
                    swalAuthCodeError.innerHTML = Backend.loadBackendMessages().errorMessage;

                    setTimeout(function () {
                        swalAuthCodeError.className = 'swal-auth-code-error';
                        swalAuthCodeError.innerHTML = '';
                    }, 3000);
                }
            });
        }
    }

    setCountdownInterval() {

        let timeLeft = plumCodeValidityTime;
        let timeExpression = new Date(timeLeft * 1000).toISOString().substring(14, 19);

        thisComponent.countdownInterval = setInterval(function () {
            let swalButton = document.getElementsByClassName('swal2-confirm payment-method-get-verification-button swal2-styled')[0];
            if (swalButton === null) {
                clearInterval(thisComponent.countdownInterval);
            }
            let countdownTimer = document.getElementById('countdown-timer');
            let swalAuthCodeError = document.getElementById('swal-auth-code-error');
            timeLeft--;
            timeExpression = new Date(timeLeft * 1000).toISOString().substring(14, 19);
            countdownTimer.innerHTML = Formatting.format(Content.getValue("code_will_expire_in_label"), ["(time)"], [timeExpression]);
            if (timeLeft === 0) {
                clearInterval(thisComponent.countdownInterval);
                countdownTimer.innerHTML = '';
                swalAuthCodeError.className = 'swal-auth-code-error error';
                swalAuthCodeError.innerHTML = Content.getValue("plum_code_expired") + ' <a id="plum-send-code-again">' + Content.getValue("plum_send_again") + '</a>';
                document.getElementById('plum-send-code-again').onclick = function () { thisComponent.resendPlumCode(); };
                swalButton.disabled = true;
            }
        }, 1000);
    }

    setAuthCodeError() {

        let authCodeInputs = document.getElementsByClassName('verification-code-input-item');

        for (let authCodeInput of authCodeInputs) {
            authCodeInput.className = 'verification-code-input-item error';
        }
    }

    removeAuthCodeError() {

        let authCodeInputs = document.getElementsByClassName('verification-code-input-item');

        for (let authCodeInput of authCodeInputs) {
            authCodeInput.classList.remove('error');
        }
    }

    addPaymentMethodSuccess(mode) {

        if (mode === 'withdrawal') {
            let ctaDiv = document.getElementById('payment-method-cta-div');
            if (ctaDiv !== null) document.getElementById('content').removeChild(ctaDiv);
        }
        MySwal.fire({
            title: '<h1 class="swal-payment-methods-title">' + Content.getValue("add_payment_method_alert_title") + '</h1>',
            html: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '" /> <p class="payment-method-deletion-warning">' + Content.getValue("payment_method_added_text_1") + '</p>',
            background: '#141629',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("got_it_button_text"),
            customClass: {
                confirmButton: 'payment-method-add-confirm-button',
                 
            }
        });
    }

    static processWithdrawal(options) {
        
        Mixpanel.trackByArgs(mixpanelEvents.withdraw_request_confirmed, options.mixpanelObject);

        const req = ApiFacade.withdrawCoins(options);
        req.then(function (res) {
            let result = res.response;
            if (res.ok) {
                setTimeout(function () { window.location.reload(); }, 250000);
                MySwal.fire({
                    html: '<p>'+Content.getValue("withdrawal_successful_message")+'</p><p class="green-text">'+Formatting.format(Content.getValue("treat_coins_withdraw"), ["(amount)"], [Formatting.formatTreatCoinAmount(options.treatCoinsAmount)])+'</p>',
                    iconHtml: '<img src="' + Images.imageURL('check_circle_colorful.png') + '"/>',
                    background: '#fff',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("continue_button_text"),
                    customClass: {
                        confirmButton: 'standard-confirm-button',
                        htmlContainer: 'withdrawal-message',
                        icon: 'withdrawal-message-icon',
                        popup: 'website-swal-modal'
                    }
                });
            }
            else {
                const message = (result && result?.message) || Content.getValue('api_response_general_error');
                MySwal.fire({
                    html: '<p class="red-text">'+ message +'</p>',
                    iconHtml: '<img src="' + Images.imageURL('close_circle_colorful.png') + '"/>',
                    background: '#fff',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("withdrawal_button_failed"),
                    customClass: {
                        confirmButton: 'standard-confirm-button',
                        htmlContainer: 'withdrawal-message',
                        icon: 'withdrawal-message-icon',
                        popup: 'website-swal-modal'
                    }
                });
            }

        });
    }

    static processConversion(options) {
        Mixpanel.trackByArgs(mixpanelEvents.withdraw_request_confirmed, options.mixpanelObject);

        const req = ApiFacade.convertCoins(options.treatCoinAmount);
        req.then(function (res) {
            let result = res.response;

            let confirmationTitle;
            let confirmationText;
            if (res.ok) {
                setTimeout(function () { window.location.reload(); }, 2500);
                confirmationTitle = Content.getValue("coins_withdrawal_successful_title");
                confirmationText = Content.getValue("coins_withdrawal_successful_message");
            }
            else {
                confirmationTitle = Content.getValue("coins_withdrawal_failed_title");
                confirmationText = Content.getValue(result.message);
            }
            MySwal.fire({
                title: '<p>' + confirmationTitle + '</p>',
                html: '<p class="swal2-content">' + confirmationText + '</p>',
                iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
                background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                showCancelButton: false,
                confirmButtonText: Content.getValue("close_button_text"),
                customClass: {
                    
                    confirmButton: 'standard-confirm-button'
                }
            });
        });
    }

    static confirmWithdrawal(options) {
        MySwal.fire({
            title: '<p>' + Content.getValue("are_you_sure_title") + '</p>',
            html: '<p class="swal2-content">' + Formatting.format(Content.getValue("withdraw_coins_question"), ["(treatCoinsAmount)", "(usdAmount)", "(platform)"], [options.treatCoinsAmount.toLocaleString(), options.usdAmount.toLocaleString(), options.platform]) + '</p>',
            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            showCancelButton: true,
            confirmButtonText: Content.getValue("continue_button_text"),
            cancelButtonText: Content.getValue("cancel_button_text"),
            customClass: {
                confirmButton: 'standard-confirm-button',
                cancelButton: 'underlined-cancel-button',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.processWithdrawal(options);
            }
        });
    }

    static confirmConversion(options) {
        MySwal.fire({
            title: '<p>' + Content.getValue("are_you_sure_title") + '</p>',
            html: '<p class="swal2-content">' + Formatting.format(Content.getValue("convert_coins_question"), ["(treatCoinsAmount)", "(convertedTreatCoinsAmount)"], [(+options.treatCoinAmount).toLocaleString(), (+options.convertedTreatCoinsAmount).toLocaleString()]) + '</p>',
            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            showCancelButton: true,
            confirmButtonText: Content.getValue("continue_button_text"),
            cancelButtonText: Content.getValue("cancel_button_text"),
            customClass: {
                container: 'z-1300',
                confirmButton: 'standard-confirm-button',
                cancelButton: 'underlined-cancel-button',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.processConversion(options);
            }
        });
    }

    static defaultPaymentMethod() {
        let currentCountry = JWTUtils.getJWTCountry();
        return (currentCountry == "BR") ? paymentMethodTypes.pix: paymentMethodTypes.plum;
    }
}

export default Payments;