const hasFalseValues = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (value === '' || value === null || value === false || value === undefined ) {
                return true;
            }

            if (typeof value === 'object') {
                if (hasFalseValues(value)) {
                    return true;
                }
            }
        }
    }

    return false;
};

export default hasFalseValues;