import Backend from 'Backend.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Images from 'utils/Images.js';

import Content from 'utils/Content.js';

const MySwal = withReactContent(Swal);

class Logout {

    static showLogoutPopup(navigate) {
        let swalHTML = '<div class="log-out-popup-container">';
        swalHTML += '<h1 class="log-out-popup-title">' + Content.getValue("are_you_sure_title") + '</h1>';
        swalHTML += '<h4 class="log-out-popup-description">' + Content.getValue("log_out_description") + '</h4>';
        swalHTML += '</div>';

        MySwal.fire({
            html: swalHTML,
            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon a3" src="' + Images.imageURL('robot-suspended.png') + '"/>',
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            showCancelButton: true,
            confirmButtonText: Content.getValue("log_out_button_text"),
            cancelButtonText: Content.getValue("cancel_button_text"),
            customClass: {
                popup: 'padding-top-30',
                confirmButton: 'standard-confirm-button',
                cancelButton: 'underlined-cancel-button margin-top-0',
                actions: 'flex-column',
            }
        })
            .then(res => res.isConfirmed && Backend.logOut(navigate));
    }

}

export default Logout;