import React from 'react';
import Images from 'utils/Images.js';
import NewsTrack from 'components/live_news/News_Track.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

class AccountSuspended extends React.Component {

    componentDidMount() {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.account_suspended });
    }

    render() {

        return (
            <>
                <div id="main" className='suspended'>
                    <div id="content">
                        <ContentPageHeader showLoginButton={false} />
                        <NewsTrack />
                        <section className="content-page-section">
                            <div>
                                <div className="title-container">
                                    <h1 className="page-title">{Content.getValue('menu_title_account_suspended')}</h1>
                                </div>
                                <div className="page-container">
                                    <img src={Images.imageURL('robot-suspended.png')} alt="Homepage robot" width="475" className="suspended-robot-image" />
                                    <div className="account-suspended-text">
                                        {Content.getValue("account_suspended_description")}
                                    </div>
                                    <RedirectWithLang
                                        text={Content.getValue("log_out_button_text")}
                                        to={configuration.paths.home}
                                        props={{ className: "contact-support-suspended-button" }}
                                    />
                                    <footer>
                                        <a href={configuration.paths.help_center} target="_blank" className="contact-support-link">
                                            {Content.getValue("contact_support_button_text")}
                                        </a>
                                    </footer>
                                </div>
                            </div>
                        </section>
                    </div>
                    <footer className={(this.lightMode) ? "light-mode" : ""}>
                        <div className="copyright-div">&copy; {Content.getValue("brand_name")}</div>
                        <div className="rights-reserved">{Content.getValue("all_rights_reserved")}</div>
                    </footer>
                </div>
            </>
        );
    }
}

export default AccountSuspended;