import React from 'react';
import Joining from 'utils/Joining.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import { useNavigate } from 'react-router-dom';
import ApiFacade from 'ApiFacade.js';

const configuration = require('configuration.json');

const GetTreatCoinsByFunActivities = (props) => {
    const navigate = useNavigate();
    const HandleRedirect = () => { redirectWithLangFunc(configuration.paths.playground, navigate); };
    const handleClick = (ApiFacade.isLoggedIn()) ? (() => HandleRedirect()) : (() => Joining.showPopup(navigate));
    return (
        <button
            data-event={props.dataEvent}
            className="providers-homepage-card-button"
            onClick={(event) => {
                Mixpanel.trackByEvent(event);
                return handleClick();
            }}
        >
            {(ApiFacade.isLoggedIn()) ? Content.getValue("to_the_playground") : Content.getValue("join_treatcoin")}
        </button>
    );
};

export default GetTreatCoinsByFunActivities;