import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Alerts from 'utils/Alerts.js';
import Content from 'utils/Content.js';

let thisComponent;

class SubscriptionSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            subscriptionSettings: null
        };
        thisComponent = this;
    }

    componentDidMount() {
        const req = ApiFacade.subscriptionSettings();
        req.then(
            function (res) {
                if (res.ok){
                    let result = res.response;
                    thisComponent.setState({
                        isLoaded: true,
                        subscriptionSettings: result.subscriptionSettings
                    });
                } else if (res.unauthorized){
                    thisComponent.setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else {
                    thisComponent.setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    render() {
        const { error, isLoaded, isRestricted, subscriptionSettings } = this.state;
        if (error) {
            return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
        } else if (isRestricted) {
            return (null);
        } else if (!isLoaded) {
            return <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>;
        } else {
            return (
                <div className="subscription-settings-section">
                    <div className="subscription-settings-options-section">
                        <div className='account-settings'>
                            {
                                (subscriptionSettings.receiveNotificationsTelegram !== undefined && subscriptionSettings.receiveNotificationsEmail === undefined) ?
                                    (
                                        <div className='telegram-setting'>
                                            <h2 className="account-settings-subtitle">{Content.getValue("subscription_settings_telegram")}</h2>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_notifications_reminders")}</span>
                                                    <input type="checkbox" id="receiveNotificationsTelegram" name="receiveNotificationsTelegram" className="subscription-settings-checkbox" value="receiveNotificationsTelegram" defaultChecked={subscriptionSettings.receiveNotificationsTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_offer_completion_notifications")}</span>
                                                    <input type="checkbox" id="receiveOfferCompletionTelegram" name="receiveOfferCompletionTelegram" className="subscription-settings-checkbox" value="receiveOfferCompletionTelegram" defaultChecked={subscriptionSettings.receiveOfferCompletionTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_referral_offer_completion_notifications")}</span>
                                                    <input type="checkbox" id="receiveReferralOfferCompletionTelegram" name="receiveReferralOfferCompletionTelegram" className="subscription-settings-checkbox" value="receiveReferralOfferCompletionTelegram" defaultChecked={subscriptionSettings.receiveReferralOfferCompletionTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_referral_registration_notifications")}</span>
                                                    <input type="checkbox" id="receiveReferralRegistrationTelegram" name="receiveReferralRegistrationTelegram" className="subscription-settings-checkbox" value="receiveReferralRegistrationTelegram" defaultChecked={subscriptionSettings.receiveReferralRegistrationTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                        </div>
                                    ) :
                                    null
                            }
                            {
                                (subscriptionSettings.receiveNotificationsEmail !== undefined && subscriptionSettings.receiveNotificationsTelegram === undefined) ?
                                    (
                                        <div className='email-setting'>
                                            <h2 className="account-settings-subtitle">{Content.getValue("subscription_settings_email")}</h2>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_notifications_reminders")}</span>
                                                    <input type="checkbox" id="receiveNotificationsEmail" name="receiveNotificationsEmail" className="subscription-settings-checkbox" value="receiveNotificationsEmail" defaultChecked={subscriptionSettings.receiveNotificationsEmail} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                        </div>
                                    ) :
                                    null
                            }
                            {
                                (subscriptionSettings.receiveNotificationsEmail !== undefined && subscriptionSettings.receiveNotificationsTelegram !== undefined) ?
                                    (<>
                                        <div className='email-setting'>
                                            <h2 className="account-settings-subtitle">{Content.getValue("subscription_settings_email")}</h2>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_notifications_reminders")}</span>
                                                    <input type="checkbox" id="receiveNotificationsEmail" name="receiveNotificationsEmail" className="subscription-settings-checkbox" value="receiveNotificationsEmail" defaultChecked={subscriptionSettings.receiveNotificationsEmail} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='telegram-setting'>
                                            <h2 className="account-settings-subtitle">{Content.getValue("subscription_settings_telegram")}</h2>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_notifications_reminders")}</span>
                                                    <input type="checkbox" id="receiveNotificationsTelegram" name="receiveNotificationsTelegram" className="subscription-settings-checkbox" value="receiveNotificationsTelegram" defaultChecked={subscriptionSettings.receiveNotificationsTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_offer_completion_notifications")}</span>
                                                    <input type="checkbox" id="receiveOfferCompletionTelegram" name="receiveOfferCompletionTelegram" className="subscription-settings-checkbox" value="receiveOfferCompletionTelegram" defaultChecked={subscriptionSettings.receiveOfferCompletionTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_referral_offer_completion_notifications")}</span>
                                                    <input type="checkbox" id="receiveReferralOfferCompletionTelegram" name="receiveReferralOfferCompletionTelegram" className="subscription-settings-checkbox" value="receiveReferralOfferCompletionTelegram" defaultChecked={subscriptionSettings.receiveReferralOfferCompletionTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox-container subscription-settings-container"><span className="notification-setting-label">{Content.getValue("receive_referral_registration_notifications")}</span>
                                                    <input type="checkbox" id="receiveReferralRegistrationTelegram" name="receiveReferralRegistrationTelegram" className="subscription-settings-checkbox" value="receiveReferralRegistrationTelegram" defaultChecked={subscriptionSettings.receiveReferralRegistrationTelegram} />
                                                    <span className="checkmark-checkbox left-checkbox"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </>) :
                                    null
                            }
                        </div>
                    </div>
                    <div className='d-flex w-auto-small-desktop'>
                        <button className="account-settings-button" onClick={this.updateSubscriptionSettings}>{Content.getValue("update_subscription_settings_button_text")}</button>
                    </div>
                </div>
            );
        }
    }

    static getCheckedValue(elementId){
        let result = null;
        const input = document.getElementById(elementId);
        if (input !== null) {
            result = input.checked;
        }
        return result;
    } 

    updateSubscriptionSettings() {


        const receiveNotificationsTelegram = SubscriptionSettings.getCheckedValue('receiveNotificationsTelegram');
        const receiveOfferCompletionTelegram = SubscriptionSettings.getCheckedValue('receiveOfferCompletionTelegram');
        const receiveReferralOfferCompletionTelegram = SubscriptionSettings.getCheckedValue('receiveReferralOfferCompletionTelegram');
        const receiveReferralRegistrationTelegram = SubscriptionSettings.getCheckedValue('receiveReferralRegistrationTelegram');
        const receiveNotificationsEmail = SubscriptionSettings.getCheckedValue('receiveNotificationsEmail');

        const req = ApiFacade.updateSubscriptionSettings(receiveNotificationsTelegram,
             receiveOfferCompletionTelegram,
             receiveReferralOfferCompletionTelegram,
             receiveReferralRegistrationTelegram,
             receiveNotificationsEmail);

        req.then( function (res) {
                if (res.ok || res.unauthorized){
                    let result = res.response;
                    if (res.ok) {
                        Alerts.showSuccessAlert(Content.getValue("subscription_settings_updated"));
                    }
                    else {
                        Alerts.showErrorAlert(result.message);
                    }
                }
                else {
                    Alerts.showErrorAlert(Backend.loadBackendMessages().errorMessage);
                }
            });
    }
}

export default SubscriptionSettings;