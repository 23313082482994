import React from 'react';
import Images from 'utils/Images.js';

import JoinTreatCoinButton from 'components/buttons/Join_TreatCoin_Button.js';

import Content from 'utils/Content.js';
import ShowMore from 'components/wrappers/Show_More';

class HowDoesItWorkSection extends React.Component {

    render() {
            return <section>
                <div className="broken-line-image-container">
                    <picture className="broken-line-image" id="how-does-it-work-title-2">
                    <source media="(max-width:1023)" srcSet={Images.imageURL('broken-line.png')} />
                    <source media="(min-width: 1024px)" srcSet={Images.imageURL('broken-line-desktop.png')} />
                    <img src={Images.imageURL('broken-line.png')} alt="Broken line" width="375" height="13" className="broken-line-image" />
                    </picture>
                </div>
                <div className="steps-div-container"  >
                <h2 className="how-does-it-work"  >{Content.getValue("how_does_it_work_subtitle")}</h2>
                    <ShowMore sectionName={'howDoesItWork'}>
                    <div className="w-fit inline-block"  >
                        <div className="steps-div">
                            <div className="step-number-image">
                                <span className="step-number">1</span>
                                    <img src={Images.imageURL('how-it-works-1.png')} alt="Step 1" width="129" height="129" className="step-image translate-85 step-image-position" />
                            </div>
                            <span className="step-content">{Content.getValue("how_does_it_work_step_1_title")}</span>
                            <span className="step-description" dangerouslySetInnerHTML={{ __html: Content.getValue("how_does_it_work_step_1_text") }} />
                        </div>
                        <div className="steps-div">
                            <div className="step-number-image">
                                <span className="step-number">2</span>
                                    <img src={Images.imageURL('how-it-works-2.png')} alt="Step 2" width="83" height="93" className="step-image-last step-image-position" />
                            </div>                        
                            <span className="step-content">{Content.getValue("how_does_it_work_step_2_title")}</span>
                            <span className="step-description" dangerouslySetInnerHTML={{ __html: Content.getValue("how_does_it_work_step_2_text") }} />
                        </div>
                    </div>
                    <div className="w-fit inline-block">
                        <div className="steps-div">
                            <div className="step-number-image">
                                <span className="step-number">3</span>
                                    <img src={Images.imageURL('how-it-works-3-new.png')} alt="Step 3" width="104" height="100" className="step-image-last step-image-position" />
                            </div>
                            <span className="step-content">{Content.getValue("how_does_it_work_step_3_title")}</span>
                            <span className="step-description" dangerouslySetInnerHTML={{ __html: Content.getValue("how_does_it_work_step_3_text") }} />
                        </div>
                        <div className="steps-div">
                            <div className="step-number-image">
                                <span className="step-number">4</span>
                                    <img src={Images.imageURL('how-it-works-4.png')} alt="Step 4" width="76" height="96" className="step-image-last translate-120 step-image-position" />
                            </div>                        
                            <span className="step-content">{Content.getValue("how_does_it_work_step_4_title")}</span>
                            <span className="step-description" dangerouslySetInnerHTML={{ __html: Content.getValue("how_does_it_work_step_4_text") }} />
                        </div>
                    </div>
                    </ShowMore>
                </div>
                <JoinTreatCoinButton />
            </section>;
    }

}

export default HowDoesItWorkSection;