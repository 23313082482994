import React from 'react';
import ApiFacade from 'ApiFacade.js';
import { useNavigate } from 'react-router-dom';
import Joining from 'utils/Joining.js';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;

const JoinTreatCoinButton = ({ showTriangle, includeNow, addClass }) => {
    const navigate = useNavigate();
    if (!ApiFacade.isLoggedIn()) {
        const triangleClass = showTriangle ? 'join-triangle' : 'hidden-join-triangle';
        return (
            <button
                className={`join-treatcoin-button ${addClass}`}
                data-event={mixpanelEvents.join_now_click}
                onClick={(event) => {
                    Mixpanel.trackByEvent(event);
                    return Joining.showPopup(navigate);
                }}
            >
                <img
                    src={Images.imageURL('join-triangle.png')}
                    alt="Join TreatCoin"
                    width="38"
                    height="38"
                    className={triangleClass}
                />
                {includeNow ? Content.getValue("join_treatcoin_now") : Content.getValue("join_treatcoin")}
            </button>
        );
    }
    return null;
};

export default JoinTreatCoinButton;
