import React, { useRef } from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import ApiFacade from 'ApiFacade.js';

function OfferReportStatus({
    ReportStatus,
    possibleToSubmit = false,
    handleOpenSubmitForm,
    reportContentKey,
    iconSize = 24,
    ForeignTicketID,
    disableBtn,
}) {
    const formRef = useRef(null);
    const inputRef = useRef(null);
    const handleZendeskCreds = () => {
        if (ReportStatus !== null) {
            const tokenReq = ApiFacade.getReportToken();
            tokenReq.then((res) => {
                const response = res.response;
                formRef.current.action = response?.ssoUrl + ForeignTicketID;
                inputRef.current.value = response?.jwt;
                if (response?.jwt) {
                    formRef.current.submit();
                }
            });
        }
    };

    return (
        <button
            style={{ opacity: disableBtn ? 0.5 : 1 }}
            disabled={disableBtn}
            onClick={() => {
                handleZendeskCreds();
                handleOpenSubmitForm({ open: true });
            }}
            className={`offer-popup-grey-container offer-report-status border-purple  ${disableBtn ? 'disabled-btn' : ''}`}>
            <div
                style={{ maxHeight: ReportStatus !== null ? 75 : 'fit-content' }}
                className="offer-popup-report-status">
                <img
                    style={{ width: iconSize, height: iconSize }}
                    src={Images.imageURL(`offers/report-status-new-${reportContentKey}.png`)} alt="" className='offer-popup-status-icon' />
                <div className="offer-popup-report-info">
                    <p className="offer-popup-white-heading-14">{Content.getValue(`report_status_heading_${reportContentKey}`)}</p>
                    {ReportStatus !== null && <p className="offer-popup-grey-text-12">{Content.getValue(`report_status_text_${reportContentKey}`)}</p>}
                </div>
            </div>
            {ReportStatus === null &&
                <button
                    className={`offer-popup-report-btn button-hidden disable-submit
                   ${disableBtn ? 'disabled-btn' : ''}
                 ${possibleToSubmit ? 'possible-submit' : 'disable-submit'}`}>
                    <span className={possibleToSubmit ? 'offer-popup-white-heading-14' : "offer-popup-grey-text-12"}>{Content.getValue('submit_report')}</span>
                </button>
            }
            <form target="_blank" ref={formRef} id="jwtForm" method="post" >
                <input ref={inputRef} id="jwtInput" type="hidden" name="jwt" />
            </form>
        </button>
    );
}

export default OfferReportStatus;