import Swal from 'sweetalert2';
import ApiFacade from 'ApiFacade.js';
import withReactContent from 'sweetalert2-react-content';
import jwt from 'jwt-decode';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import Consent from 'utils/Consent.js';
import Images from 'utils/Images.js';
import { Mixpanel } from 'utils/User_Events_Util';
import Platform from 'utils/Platform.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';

const MySwal = withReactContent(Swal);

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');


const accessPoints = configuration.access_points;
const serverHeaders = configuration.server_headers;
const methods = configuration.methods;
const responseCodes = configuration.response_codes;
const paths = configuration.paths;
const loginTypes = configuration.login_types;
const userDataKeys = configuration.user_data_keys;
const queryVariables = configuration.query_variables;
const broadcastChannels = configuration.broadcast_channels;
const languageItems = configuration.languages;
const tokenSourceToLoginType = configuration.token_source_to_login_type;

const botDetails = globalConf.bot_details;

const websiteConnection = globalConf.website_connection;

const gatewayHost = websiteConnection.gateway_host;
const cloudfrontHost = websiteConnection.cloudfront_host;

const getMethod = methods.get;
const postMethod = methods.post;
const putMethod = methods.put;

const OKCode = responseCodes.OK;
const badRequestCode = responseCodes.bad_request;
const unauthorizedCode = responseCodes.unauthorized;
const forbiddenCode = responseCodes.forbidden;
const internalBackendErrorCode = responseCodes.internal_server_error;
const serviceUnavailableCode = responseCodes.service_unavailable;

const botProtocol = botDetails.bot_protocol;
const telegramDomain = botDetails.telegram_domain;
const botName = botDetails.bot_name;
const joinSuffix = botDetails.join_suffix;

const userPath = paths.user_path;
const guestPath = paths.guest_path;

const liveNewsPreferencesChannel = new BroadcastChannel(broadcastChannels.live_news_preferences_channel);

window.expiredShown = false;

let backendURLs = {
    
};

const ANONYMOUS_API_LEVEL = 1;
const MEMBER_API_LEVEL = 2;

let backendMessages = {

};

const backendResponseCodes = {
    OKCode: OKCode,
    badRequestCode: badRequestCode,
    unauthorizedCode: unauthorizedCode,
    forbiddenCode: forbiddenCode,
    internalBackendErrorCode: internalBackendErrorCode,
    serviceUnavailableCode: serviceUnavailableCode
};

const backendMethods = {
    get: getMethod,
    post: postMethod,
    put: putMethod
};

class ApiLevels {
    static get ANONYMOUS() {
        return ANONYMOUS_API_LEVEL;
    }

    static get MEMBER() {
        return MEMBER_API_LEVEL;
    }
}

class Backend {

    static buildUrls() {

        let pathSuffix = ApiFacade.isLoggedIn() ? userPath : guestPath;
        let dynamicHost = ApiFacade.isLoggedIn() ? gatewayHost : cloudfrontHost;

        // Different URL for anonymous and guest
        this.backendURLs.top100URL = dynamicHost + pathSuffix + accessPoints.top_100_users;
        this.backendURLs.offersURL = dynamicHost + pathSuffix + accessPoints.offers;
        this.backendURLs.top12OffersURL = dynamicHost + pathSuffix + accessPoints.top_12_offers;
        this.backendURLs.additionalOffersURL = dynamicHost + pathSuffix + accessPoints.additional_offers;
        this.backendURLs.updateLanguageURL = gatewayHost + pathSuffix + accessPoints.update_language;
        // Same URL for anonymous and guest
        this.backendURLs.currentRewardsURL = cloudfrontHost + accessPoints.current_rewards;
        this.backendURLs.fetchContentURL = cloudfrontHost + accessPoints.fetch_content;
        this.backendURLs.faqURL = cloudfrontHost + accessPoints.faq;
        this.backendURLs.recommendedOfferURL = gatewayHost + accessPoints.recommended_offer;
        // Anonymous only
        this.backendURLs.startRegistrationURL = gatewayHost + accessPoints.start_registration;
        this.backendURLs.fetchUserDataURL = gatewayHost + accessPoints.fetch_user_data;
        this.backendURLs.createCookieURL = gatewayHost + accessPoints.create_cookie;
        this.backendURLs.recordThirdPartyConsentURL = gatewayHost + accessPoints.record_third_party_consent;
        this.backendURLs.signInURL = gatewayHost + accessPoints.sign_in;
        this.backendURLs.registerURL = gatewayHost + accessPoints.register;
        this.backendURLs.googleLoginURL = gatewayHost + accessPoints.google_login;
        this.backendURLs.facebookLoginURL = gatewayHost + accessPoints.facebook_login;
        this.backendURLs.discordLoginURL = gatewayHost + accessPoints.discord_login;
        // User only
        this.backendURLs.completedOffersURL = gatewayHost + accessPoints.completed_offers;
        this.backendURLs.incomingNotificationsUrl = gatewayHost + accessPoints.incoming_notifications;
        this.backendURLs.saveDeliveredNotificationsUrl = gatewayHost + accessPoints.save_delivered_notifications;
        this.backendURLs.x2OfferUrl = gatewayHost + accessPoints.x2_offer;
        this.backendURLs.userStatsUrl = gatewayHost + accessPoints.user_stats;
        this.backendURLs.monthlyTeamStatsUrl = gatewayHost + accessPoints.monthly_team_stats;
        this.backendURLs.lifetimeTeamStats = gatewayHost + accessPoints.lifetime_team_stats;
        this.backendURLs.myCoinsURL = gatewayHost + accessPoints.my_coins;
        this.backendURLs.convertCoinsURL = gatewayHost + accessPoints.convert_coins;
        this.backendURLs.conversionDataURL = gatewayHost + accessPoints.conversion_data;
        this.backendURLs.withdrawCoinsURL = gatewayHost + accessPoints.withdraw_coins;
        this.backendURLs.myEarningsHistoryURL = gatewayHost + accessPoints.my_earnings_history;
        this.backendURLs.myPayoutHistoryURL = gatewayHost + accessPoints.my_payout_history;
        this.backendURLs.subscriptionSettingsURL = gatewayHost + accessPoints.subscription_settings;
        this.backendURLs.updateSubscriptionSettingsURL = gatewayHost + accessPoints.update_subscription_settings;
        this.backendURLs.deleteAccountURL = gatewayHost + accessPoints.delete_account;
        this.backendURLs.mergeDiscordURL = gatewayHost + accessPoints.merge_discord;
        this.backendURLs.connectedToBotURL = gatewayHost + accessPoints.connected_to_bot;
        this.backendURLs.recordOfferEventURL = gatewayHost + accessPoints.record_offer_event;
        this.backendURLs.paymentMethodsListURL = gatewayHost + accessPoints.payment_methods_list;
        this.backendURLs.requestPlumURL = gatewayHost + accessPoints.request_plum;
        this.backendURLs.addPlumURL = gatewayHost + accessPoints.add_plum;
        this.backendURLs.removePlumURL = gatewayHost + accessPoints.remove_plum;
        this.backendURLs.refreshTokenURL = gatewayHost + accessPoints.refresh_token;
        this.backendURLs.logOutURL = gatewayHost + accessPoints.log_out;
        this.backendURLs.receiveUploadLinksURL = gatewayHost + accessPoints.receive_upload_links;
        this.backendURLs.userUpdateLiveNewsPrefURL = gatewayHost + accessPoints.user_update_live_news_preferences;
        this.backendURLs.verifyBotURL = gatewayHost + accessPoints.verify_bot;
        this.backendURLs.offerEventsURL = gatewayHost + userPath + accessPoints.offer_events;
        this.backendURLs.offersClickedURL = gatewayHost + accessPoints.offers_clicked;
        this.backendURLs.getTicketReportPropertiesURL = gatewayHost + accessPoints.user_get_ticket_report_properties;
        this.backendURLs.getTicketReportImgLinksURL = gatewayHost + accessPoints.user_get_ticket_report_presigned_links;
        this.backendURLs.getTicketReportTokenURL = gatewayHost + accessPoints.user_get_ticket_report_token;
        this.backendURLs.submitTicketReportURL = gatewayHost + accessPoints.user_submitt_ticket_report;
        this.backendURLs.getLinkedOffersURL = gatewayHost + accessPoints.linked_offers;
        this.backendURLs.getStockPropertiesURL = gatewayHost + accessPoints.stock_properties;
        this.backendURLs.userReviewURL = gatewayHost + accessPoints.user_review;
        this.backendURLs.getUserReferralsURL = gatewayHost + accessPoints.user_my_referrals;
        this.backendURLs.userAcceptTermsURL = gatewayHost + accessPoints.user_accept_terms;
        this.backendURLs.userGetMergeCodeURL = gatewayHost + accessPoints.user_get_merge_code;
        this.backendURLs.userStartPhoneVerificationURL = gatewayHost + accessPoints.user_start_phone_number_verification;
        this.backendURLs.userVerifyPhoneNumberURL = gatewayHost + accessPoints.user_verify_phone_number;
        this.backendURLs.notifyOfferAvailURL = gatewayHost + accessPoints.notify_offer_avail;
    }

    static get backendURLs() {
        return backendURLs;
    }

    static get backendMessages() {
        return backendMessages;
    }

    static get backendResponseCodes() {
        return backendResponseCodes;
    }

    static get backendMethods() {
        return backendMethods;
    }

    static get apiLevels(){
        return ApiLevels;
    }

    static get serverHeaders(){
        return serverHeaders;
    }

    static loadBackendMessages() {
        backendMessages = {
            loadingMessage: Content.getValue("loading_message"),
            errorMessage: Content.getValue("error_message"),
            restrictedMessage: Content.getValue("restricted_message"),
            requestsExceededMessage: Content.getValue("requests_exceeded_message")
        };
        return backendMessages;
    }

    
    static generateHeaderApi(apiLevel, alwaysSendGuest = false) {

        let items = { 'Content-Type': 'application/json' };
        if (UserData.getItem(userDataKeys.jwt_token) !== null && !alwaysSendGuest) {
            items[serverHeaders.authorization] = serverHeaders.jwt_prefix + ' ' + UserData.getItem(userDataKeys.jwt_token);
        }
        else if ((apiLevel<=ANONYMOUS_API_LEVEL) && (UserData.getItem(userDataKeys.guest_token) !== null)) {
            items[serverHeaders.guest_auth] = serverHeaders.jwt_prefix + ' ' + UserData.getItem(userDataKeys.guest_token);
        }

        return new Headers(items);
    }

    static generateHeader(alwaysSendGuest = false) {

        let items = { 'Content-Type': 'application/json' };
        if (UserData.getItem(userDataKeys.jwt_token) !== null && !alwaysSendGuest) {
            items[serverHeaders.authorization] = serverHeaders.jwt_prefix + ' ' + UserData.getItem(userDataKeys.jwt_token);
        }
        else if (UserData.getItem(userDataKeys.guest_token) !== null) {
            items[serverHeaders.guest_auth] = serverHeaders.jwt_prefix + ' ' + UserData.getItem(userDataKeys.guest_token);
        }

        return new Headers(items);
    }

    static generateVerificationHeader(verificationToken) {
        let items = { 'Content-Type': 'application/json' };
            items[serverHeaders.verification_auth] = serverHeaders.jwt_prefix + ' ' + verificationToken;
            items[serverHeaders.guest_auth] = serverHeaders.jwt_prefix + ' ' + UserData.getItem(userDataKeys.guest_token);

        return new Headers(items);
    }

    static clearTokenIfRequired(tokenValid, accountSuspended){
        Backend.clearToken(tokenValid, accountSuspended);
    }

    static clearToken(tokenValid, accountSuspended, navigate) {


        if (tokenValid === false && accountSuspended === false) {
            let botLinkAddress = botProtocol + "://" + telegramDomain + "/" + botName;
            botLinkAddress += joinSuffix;
            if (window.expiredShown === false) {
                let loginType = UserData.getItem(userDataKeys.login_type);
                if (loginType === loginTypes.email || loginType === loginTypes.google || loginType === loginTypes.facebook || loginType === loginTypes.discord) {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("session_expired_alert_title") + '</p>',
                        html: '<p class="swal2-content">' + Content.getValue("session_expired_alert_text_email") + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: true,
                        confirmButtonText: Content.getValue("sign_in_button_text"),
                        cancelButtonText: Content.getValue("continue_as_guest_button_text"),
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'standard-confirm-button',
                            cancelButton: 'standard-cancel-button',
                        }
                    }).then((result) => {
                        window.expiredShown = false;
                        if (result.isConfirmed) {
                            redirectWithLangFunc(configuration.paths.home, navigate)
                        }
                        else {
                            redirectWithLangFunc(configuration.paths.home, navigate);
                        }
                    });
                }
                else if (loginType === loginTypes.telegram) {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("session_expired_alert_title") + '</p>',
                        html: '<p class="swal2-content">' + Content.getValue("session_expired_alert_text_telegram") + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: true,
                        confirmButtonText: Content.getValue("go_to_treatsbot_button_text"),
                        cancelButtonText: Content.getValue("continue_as_guest_button_text"),
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'standard-confirm-button',
                            cancelButton: 'standard-cancel-button',
                        }
                    }).then((result) => {
                        window.expiredShown = false;
                        if (result.isConfirmed) {
                            this.history.push(botLinkAddress);
                        }
                        else {
                            redirectWithLangFunc(configuration.paths.home, navigate);
                        }
                    });
                }
                Backend.clearSessionData();
                window.expiredShown = true;
            }
        }
        else if (accountSuspended) {
            Backend.clearSessionData();
            redirectWithLangFunc(configuration.paths.account_suspended, navigate)
        }
    }

    static fetchUserData(renderFunc, loginToken, tokenSource) {

        const platform = Platform.getPlatformName();
        let fetchURL = `${this.backendURLs.fetchUserDataURL}?platformName=${encodeURIComponent(platform)}&loginToken=${encodeURIComponent(loginToken)}&tokenSource=${encodeURIComponent(tokenSource)}`;
        fetch(fetchURL, {
            credentials: 'include',
            method: this.backendMethods.get,
            headers: this.generateHeader()
        })
            .then(async function (res) {
                let result = await res.json();
                const currentUrl = window.location.href;
                const url = new URL(currentUrl);
                url.searchParams.delete(queryVariables.token);
                url.searchParams.delete(queryVariables.source);
                window.history.replaceState({}, document.title, url.href);
                if (res.status === Backend.backendResponseCodes.OKCode) {
                    await Backend.setUserSessionData(result, tokenSourceToLoginType[tokenSource.toString()], true, null );
                }
                renderFunc();
            }
            );
    }

    static async fetchContent() {
        let preferredLanguage = Content.getPreferredLanguage();
        let fetchURL = `${this.backendURLs.fetchContentURL}?language=${encodeURIComponent(preferredLanguage)}`;

        const response = await fetch(fetchURL, {
            method: this.backendMethods.get,
            headers: this.generateHeader(true)
        });
        const result = await response.json();
        const content = result.content;
        Content.storeContent(content);

    }

    static createGuestCookie(renderFunc, shouldFetchAuthTokenData, loginToken, tokenSource) {
        fetch(this.backendURLs.createCookieURL, {
            method: this.backendMethods.get,
            headers: this.generateHeader()
        })
            .then(async function (res) {
                let result = await res.json();
                await Backend.setGuestData(result);
                if (shouldFetchAuthTokenData) {
                    const fetchUserDataAfterAnalytics = function () {
                        Backend.fetchUserData(renderFunc, loginToken, tokenSource);
                    };
                    Backend.startAfterAnalyticsInitialized(fetchUserDataAfterAnalytics);
                }
                else {
                    renderFunc();
                }
            },
                () => {
                }
            );
    }

    static async setGuestData(result) {
        let token = result.token;
        let country = result.country;
        let language = result.language;
        UserData.setItem(userDataKeys.guest_token, token);
        UserData.setItem(userDataKeys.country, country);
        UserData.setItem(userDataKeys.language, language);
    }

    static setRefreshTokenData(result) {
        let token = result.token;
        Backend.setUserDataFromJwt(token)
    }

    static setUserDataFromJwt(token){
        let data = jwt(token);
        UserData.setItem(userDataKeys.jwt_token, token);
        UserData.setItem(userDataKeys.username, data.username);
        UserData.setItem(userDataKeys.country, data.country);
        UserData.setItem(userDataKeys.language, data.language);
        UserData.setItem(userDataKeys.kyc_status, data.KYCStatus);
        UserData.setItem(userDataKeys.uniqueUserID, data.uniqueUserID);
        UserData.setItem(userDataKeys.terms, data.terms);
    }

    static startAfterAnalyticsInitialized(actionToStart) {
        const thirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings)) || {};
        const mixpanelAccepted = thirdPartySettings[userDataKeys.third_party_cookies.mixpanel] || false;
        const googleAnalyticsAccepted = thirdPartySettings[userDataKeys.third_party_cookies.google_analytics] || false;

        if (!mixpanelAccepted) {
            Consent.addMixpanel();
        }

        if (!googleAnalyticsAccepted) {
            window.onAnalyticsInitialize = function () {
                setTimeout(function() {
                    actionToStart();
                }, 300);
            };
            Consent.addGoogleAnalyticsScripts();
        }
        else {
            actionToStart();
        }
    }

    static async setUserSessionData(result, loginType, redirectToLoginOnFailure = false, navigate = null) {
        await Backend.logOutIfRequired();
        let token = result.token;
        let country = result.country;
        let registrationMonth = result.registrationMonth;
        let registrationYear = result.registrationYear;
        let username = result.username;
        let language = result.language;
        let websiteReferralLink = result.websiteReferralLink;
        let telegramReferralLink = result.telegramReferralLink;
        let registrationTime = result.registrationTime;
        let KYCStatus = result.KYCStatus;
        let receiveLiveNews = result.receiveLiveNews;
        let connectedToBot = result.connectedToBot;
        UserData.removeItem(userDataKeys.referrer_code);
        UserData.removeItem(userDataKeys.email_signup);
        UserData.removeItem(userDataKeys.redirect_to_discord_community_after_auth);

        if (token) {
            UserData.setItem(userDataKeys.connected_to_bot, connectedToBot);
            UserData.setItem(userDataKeys.jwt_token, token);
            UserData.setItem(userDataKeys.registration_month, registrationMonth);
            UserData.setItem(userDataKeys.registration_year, registrationYear);
            UserData.setItem(userDataKeys.username, username);
            UserData.setItem(userDataKeys.country, country);
            UserData.setItem(userDataKeys.language, language);
            UserData.setItem(userDataKeys.website_referral_link, websiteReferralLink);
            UserData.setItem(userDataKeys.telegram_referral_link, telegramReferralLink);
            UserData.setItem(userDataKeys.registration_time, registrationTime);
            UserData.setItem(userDataKeys.kyc_status, KYCStatus);
            UserData.setItem(userDataKeys.receive_live_news, receiveLiveNews);
            Backend.setUserDataFromJwt(token);
            Backend.allowAllThirdPartyForRegisteringUser();
            UserData.setItem(userDataKeys.enforce_live_news, 1);
            UserData.setItem(userDataKeys.login_type, loginType);
            if (loginType === loginTypes.discord) {
                UserData.setItem(userDataKeys.connected_to_discord, 1);
            }
            if (receiveLiveNews) {
                liveNewsPreferencesChannel.postMessage({receiveLiveNews: true});
            }
        }
        else if (!redirectToLoginOnFailure) {
            UserData.removeItem(userDataKeys.jwt_token, token);
            UserData.setItem(userDataKeys.language, languageItems[0].code);
        }
        Backend.buildUrls();
        await Backend.fetchContent(false); 
        if (redirectToLoginOnFailure && !token && !ApiFacade.isLoggedIn()) {
            redirectWithLangFunc(configuration.paths.home, navigate);
        }
    }

    static allowAllThirdPartyForRegisteringUser() {
        const thirdPartySettings = {};
        thirdPartySettings[userDataKeys.third_party_cookies.mixpanel] = true;
        thirdPartySettings[userDataKeys.third_party_cookies.google_analytics] = true;
        UserData.setItem(userDataKeys.third_party_settings, JSON.stringify(thirdPartySettings));
    }

    static async refreshToken(navigate) {
        let res = null;
        try{
            res = await fetch(this.backendURLs.refreshTokenURL, {
                credentials: "include",
                method: this.backendMethods.get,
                headers: this.generateHeader()
            });

            let result = await res.json();
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.clearToken(false, false, navigate);
                return false;
            }
            else if (res.status === Backend.backendResponseCodes.forbiddenCode) {
                Backend.clearToken(false, true, navigate);
                return false;
            }
            else if (res.status === Backend.backendResponseCodes.OKCode) {
                Backend.setRefreshTokenData(result);
                return true;
            }
            return false;
        }
        catch (ex){
            return false;
        }
    }

    static logOut(navigate) {

        Mixpanel.reset();

        fetch(this.backendURLs.logOutURL, {
            credentials: "include",
            method: this.backendMethods.post,
            headers: this.generateHeader()
        }).then(() => {
            Backend.clearSessionData();
            redirectWithLangFunc(configuration.paths.home, navigate);
        });
    }

    static async logOutIfRequired() {

        if (UserData.getItem(userDataKeys.jwt_token)) {

            Mixpanel.reset();

            await fetch(this.backendURLs.logOutURL, {
                credentials: "include",
                method: this.backendMethods.post,
                headers: this.generateHeader()
            }).then(async () => {
                let utmSource = UserData.getItem(userDataKeys.utm_source);
                let utmMedium = UserData.getItem(userDataKeys.utm_medium);
                let utmCampaign = UserData.getItem(userDataKeys.utm_campaign);
                let utmContent = UserData.getItem(userDataKeys.utm_content);
                let utmTerm = UserData.getItem(userDataKeys.utm_term);
                let fbclid = UserData.getItem(userDataKeys.fbclid);
                let oldDeviceId = UserData.getItem(userDataKeys.device_id)
                Backend.clearSessionData();
                UserData.setItemWithValueCheck(userDataKeys.utm_source, utmSource);
                UserData.setItemWithValueCheck(userDataKeys.utm_medium, utmMedium);
                UserData.setItemWithValueCheck(userDataKeys.utm_campaign, utmCampaign);
                UserData.setItemWithValueCheck(userDataKeys.utm_content, utmContent);
                UserData.setItemWithValueCheck(userDataKeys.utm_term, utmTerm);
                UserData.setItemWithValueCheck(userDataKeys.fbclid, fbclid);
                UserData.setItemWithValueCheck(userDataKeys.device_id, oldDeviceId);
            });
        }
    }

    static clearSessionData() {
        let language = UserData.getItem(userDataKeys.language);
        let content = UserData.getItem(userDataKeys.content);
        let contentVersion = UserData.getItem(userDataKeys.content_version);
        let guestToken = UserData.getItem(userDataKeys.guest_token);
        let receiveLiveNews = UserData.getItem(userDataKeys.receive_live_news);
        let thirdPartyAccepted = JSON.parse(UserData.getItem(userDataKeys.third_party_settings));
        let homeShownSections = UserData.getItem(userDataKeys.home_show_sections);
        let gclient_id = UserData.getItem(userDataKeys.gclient_id);
        UserData.clear();
        UserData.setItem(userDataKeys.language, language);
        UserData.setItem(userDataKeys.content, content);
        UserData.setItem(userDataKeys.content_version, contentVersion);
        UserData.setItem(userDataKeys.guest_token, guestToken);
        UserData.setItem(userDataKeys.receive_live_news, receiveLiveNews);
        UserData.setItem(userDataKeys.third_party_settings, JSON.stringify(thirdPartyAccepted));
        UserData.setItem(userDataKeys.home_show_sections, homeShownSections);
        UserData.setItem(userDataKeys.gclient_id, gclient_id);
        Backend.buildUrls();
        liveNewsPreferencesChannel.postMessage({receiveLiveNews: false});
    }

    static async receiveUploadEndpoints(setStep) {
        await fetch(this.backendURLs.receiveUploadLinksURL, {
            method: this.backendMethods.get,
            headers: this.generateHeader(),
        }).then(async res => {
            const result = await res.json();
            UserData.setItem(userDataKeys.upload_links, JSON.stringify(result.uploadLinks));
            await setStep(2)
        }).catch(() => {});
    }

    static async sendFileViaLink(file, link) {
        await fetch(link, {
            method: this.backendMethods.put,
            headers: { "Content-Type": file.type },
            body: file,
        }).then().catch(e => {
            alert(e.message);
            window.location.reload();
        });
    }
    
}

export default Backend;