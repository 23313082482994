import * as queryString from 'query-string';
import Formatting from 'utils/Formatting.js';
import UserData from 'User_Data.js';

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');

const botDetails = globalConf.bot_details;
const websiteConnection = globalConf.website_connection;
const discordConnection = globalConf.discord_connection;

const botSuffixes = configuration.bot_suffixes;
const userDataKeys = configuration.user_data_keys;
const queryVariables = configuration.query_variables;
const urls = configuration.urls;

const botProtocol = botDetails.bot_protocol;
const telegramDomain = botDetails.telegram_domain;
const botName = botDetails.bot_name;

const joinSuffix = botSuffixes.join_suffix;
const refSuffix = botSuffixes.ref_suffix;
const mergeSuffix = botSuffixes.merge_suffix;

const websiteURL = websiteConnection.website_url;

const discordClientID = discordConnection.client_id;
const discordRedirectUrl = discordConnection.redirect_url;

const discordAuthURL = urls.discord_auth_url;

const stringifiedDiscordParams = queryString.stringify({
    client_id: discordClientID,
    response_type: 'code',
    redirect_uri: discordRedirectUrl,
    scope: ['identify', 'email', 'guilds.join', 'guilds.members.read'].join(' '),
    prompt: 'none'
});

const discordLoginUrl = Formatting.format(discordAuthURL, ["(stringifiedParams)"], [stringifiedDiscordParams]);

class LinkCreator {

    static createTelegramLink(accountMergeCode = null) {

        let botLinkAddress;
        let basicBotLinkAddress = botProtocol + "://" + telegramDomain + "/" + botName;

        if (UserData.getItem(userDataKeys.referrer_code) !== null) {
            botLinkAddress = basicBotLinkAddress + Formatting.format(refSuffix, ["(referrerCode)"], [UserData.getItem(userDataKeys.referrer_code)]);
        }
        else if (accountMergeCode !== null) {
            botLinkAddress = basicBotLinkAddress + Formatting.format(mergeSuffix, ["(accountMergeCode)"], [accountMergeCode]);
        }
        else {
            botLinkAddress = basicBotLinkAddress + joinSuffix;
        }

        return botLinkAddress;
    }

    static createOfferShareLink(recommendedOffer, offerRecommender) {
        let offerShareLink = websiteURL + '?' + queryVariables.recommended_offer + '=' + recommendedOffer + '&' + queryVariables.offer_recommender + '=' + offerRecommender;
        return offerShareLink;
    }

    static createDiscordLink() {
        return discordLoginUrl;
    }
   

}

export default LinkCreator;