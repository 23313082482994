import React from 'react';
import { useState } from 'react';
import Content from 'utils/Content';
import Images from 'utils/Images';
import UserData from 'User_Data.js';
import { useEffect } from 'react';
import ApiFacade from 'ApiFacade.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
let homeShownSections = JSON.parse(UserData.getItem(userDataKeys.home_show_sections) ? UserData.getItem(userDataKeys.home_show_sections) : '{}');

function ShowMore({ children, sectionName }) {
    const [isExpanded, setIsExpanded] = useState(homeShownSections !== null ? homeShownSections[sectionName] : true);
   
    const isAuthorized = ApiFacade.isLoggedIn();

    useEffect(() => {
        if (homeShownSections === null) {
            UserData.setItem(userDataKeys.home_show_sections, JSON.stringify({}));
        }
        homeShownSections[sectionName] = isExpanded;
        UserData.setItem(userDataKeys.home_show_sections, JSON.stringify(homeShownSections));
    }, [isExpanded, sectionName])


    return (
        <div className='show-more'>
            {
            (isAuthorized) ?
            (
            <>
            <button className='hidden-button show-more-button'
                onClick={() => {
                    setIsExpanded(prev => !prev);
                }}
            >
                <span className='show-more-text'>
                {Content.getValue(isExpanded ? 'show_less' : 'show_more')}
                </span>
                <img src={Images.imageURL(isExpanded ? 'btn-up.png' : 'btn-down.png')} alt="" className="show-more-arrow" />
            </button>
            </>
            ) :
            (null)
            }
            <div className={`show-more-container  ${(!isAuthorized || isExpanded) ? 'expanded' : 'collapsed'}`}>
                {children}
            </div>
        </div>
    );
}

export default ShowMore;
