import { useState, useEffect } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const now = new Date();
const utcDate =  Date.UTC(now.getUTCFullYear(), 
                          now.getUTCMonth(), 
                          now.getUTCDate(), 
                          now.getUTCHours(), 
                          now.getUTCMinutes(),
                          now.getUTCSeconds(),
                          now.getUTCMilliseconds());

export default function useTimer(deadline, interval = SECOND) {
  const [timespan, setTimespan] = useState(new Date(deadline) - new Date(utcDate));

  const days = Math.floor((timespan / DAY));
  const hours = Math.floor((timespan / HOUR) % 60);
  const minutes = Math.floor((timespan / MINUTE) % 60);
  const seconds = Math.floor((timespan / SECOND) % 60);
  useEffect(() => {
    let intervalId;
    if (hours + minutes + seconds > 0) {
      intervalId = setInterval(() => {
          setTimespan(_timespan => _timespan - interval);
      }, interval);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [hours, interval, minutes, seconds]);

  useEffect(() => {
    setTimespan(new Date(deadline) - Date.now());
  }, [deadline]);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}