import React from 'react';
import Images from 'utils/Images';

function LinkButton({ onClick, imageRoute, text, disabled, id, tg }) {
    return (
        <div className="link-button-container">
            <button
                id={id}
                disabled={disabled}
                onClick={() => onClick()}
                className={`hidden-button link-button ${disabled ? 'disabled-btn' : ''}`}
            >
                <img src={Images.imageURL(imageRoute)} alt="Link Button" className='link-button-image' />
                <p className="link-button-text">{text}</p>
            </button>
            {disabled ?
                <div className={`${tg} link-button-hint`}>👆</div>
                : <></>}
        </div>

    );
}

export default LinkButton;