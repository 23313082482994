import React, { useContext } from 'react';
import Images from 'utils/Images';
import Formatting from 'utils/Formatting';
import Joining from 'utils/Joining';
import { OfferPopupContext } from 'context/Offer_Popup_Context';
import { useNavigate } from 'react-router-dom';
import ApiFacade from 'ApiFacade.js';
import Content from '../../../utils/Content';

function AdditionalOfferCard({ categoryID, offer }) {
  const navigate = useNavigate();
  const { handleOfferIsOpened } = useContext(OfferPopupContext)
  const { imageURL, appName, description, coins, treatCoinValue, ID, OfferHeading } = offer;

  return (
    <li
      onClick={() => {
        if (ApiFacade.isLoggedIn()) {
          handleOfferIsOpened({isOpened: true, offer})
        } else {
          Joining.showPopup(navigate);
        }
      }}
      key={ID} className={`additional-offer-card additional-offer-card-${categoryID}`}>
      <div className='additional-offer-card-img'
        style={{ backgroundImage: `url(${imageURL})`, backgroundSize: 'contain' }}
      />
      <h4 className="additional-offer-card-name">{appName}</h4>
      <p className="additional-offer-card-desc">{Formatting.shortenText(description, 200) }</p>
      <div>
        <div className="top-offer-homepage-payout items-start-i margin-bottom-5 ">
          {offer?.isPercentage ?
            <>
              <p className="top-offer-homepage-tc-value ">{Formatting.format(Content.getValue('offer_popup_percentage_value'), ['(value)'], [coins])}</p>
            </> :
            <>
              <img
                // width={20} height={20}
                src={Images.imageURL('offers/stats-header-money-icon-new.png')}
                className="top-offer-homepage-treat-coin margin-right-2" />
              <p className="top-offer-homepage-tc-value">{coins?.toLocaleString()}</p>
              <div className='top-offer-homepage-usd-value-container margin-bottom-2 padding-top-2'>
                <p className="top-offer-homepage-usd-value">= {Formatting.currencySymbol()}{(coins * treatCoinValue).toFixed(2)}</p>
                <p className="additional-offer-card-usd-desc">{Formatting.currencyLabel()}</p>
              </div>
            </>}
        </div>
        {offer?.isPercentage ? <></> :
          <p className="additional-offer-card-tc-desc">Treat Coins</p>
        }
      </div>
      <div className="additional-offer-card-border"></div>
      <p className="additional-offer-card-details">
        {Formatting.shortenText(OfferHeading, 88)}
      </p>
    </li>
  )
}

export default AdditionalOfferCard