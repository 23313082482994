import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';

import Content from 'utils/Content.js';

const TotalRewardsFund = (props) => {
        let prizeFundAmount = props.prizeFundAmount;
        let month = props.month;
        return (
            <>
            <h2 id="leaderboards-subtitle">{month} </h2>
            <div className="total-reward-wrapper leaderboards">
                        <div className="treatsbot-rewards-description-container">
                     </div>
                    <div className="total-reward-description-div">
                        <div className="total-reward-title"></div>
                        <div className="total-reward-description-wrapper leaderboards"></div>
                        <div className="divided-between-text">{Content.getValue("divided_between_top_100")}</div>
                        <div className="total-rewards-text-wrapper leaderboards">
                            <div className="total-reward-text-leaderboards">{`${Formatting.formatTreatCoinAmount(prizeFundAmount)}`}</div>
                            <div className="leaderboards-fund-image-container"><img src={Images.imageURL('ethereum-image.png')} alt="Ethereum" width="74" className="ethereum-image-small" /></div>
                        </div>
                    </div>
            </div>
            </>
        )
}

export default TotalRewardsFund;