import React from 'react';
import Images from 'utils/Images.js';
import OfferDifficultyIndicator from 'components/offers/Offer_Difficulty_Indicator.js';
import Offers from 'utils/Offers.js';
import Formatting from 'utils/Formatting.js';
import OfferThemeIndicator from 'components/offers/Offer_Theme_Indicator.js'

import Content from 'utils/Content.js';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
const configuration = require('configuration.json');

const featuredOfferModes = configuration.featured_offer_modes;

class FeaturedOffer extends React.Component {

    constructor(props) {
        super(props);
        this.offer = props.offer;
        this.mode = props.mode;
        this.isSpecial = props.isSpecial;
        this.selectedHashTags = props.selectedHashTags;
    }

    static contextType = OfferPopupContext;

    render() {
        const { handleOfferIsOpened } = this.context;
        let isSpecial = this.isSpecial;
        let offer = this.offer;
        let multiSteps = offer && JSON.parse(offer.multiSteps || null);
        let multiStepsSum = Offers.getMultiStepsSum(multiSteps);
        let isTodaysBest = this.mode === featuredOfferModes.todays_best;

        let titleText = null;

        if (isTodaysBest) {
            titleText = Content.getValue("todays_best");
        }
        else if (this.mode === featuredOfferModes.system) {
            titleText = Content.getValue("treatcoins_offer");
        }

        return <div className={`${isSpecial ? 'special-offer-root desktop' : 'featured-offer-root desktop'}`}>
            <div className="featured-offer-text">{titleText}</div>
            <div className={`list-offer-container featured-offer ${isTodaysBest ? "todays-best" : ""} ${isSpecial ? "special" : ""} `} key='offer_todays_best'>
                <div className="list-offer-container-image" onClick={() => handleOfferIsOpened({ offer })}>
                    <OfferDifficultyIndicator difficultyLevel={offer.difficultyLevel} difficultyLevelID={offer.difficultyLevelID} imageType="white" />
                    <img className="list-offer-image" src={offer.imageURL} alt={offer.description} width="36" height="auto" />
                    <OfferThemeIndicator offer={offer} />

                </div>
                <div className="list-offer-container-titles desktop">
                    <div>
                        <div className="list-offer-app-name" onClick={() => handleOfferIsOpened({ offer })}>{offer.appName}</div>
                        <div className="list-offer-description" onClick={() => handleOfferIsOpened({ offer })}>{offer.MainTitle}</div>
                        <div className="list-offer-hash-tags featured-offer"></div>
                    </div>
                </div>
                <div className="list-offer-container-button desktop" onClick={() => handleOfferIsOpened({ offer })}>
                    <button className="list-offer-button desktop">
                        {
                            multiStepsSum && multiStepsSum.current === 0 ?
                            (
                                <p className="margin-bottom-5">{Content.getValue("up_to")}</p>
                            ) :
                            null
                        }
                        <p className="coins-amount">
                            {
                                multiStepsSum ?
                                    multiStepsSum.current === 0 ?
                                    Formatting.formatTreatCoinAmount(multiStepsSum.total) :
                                    Formatting.formatTreatCoinAmount(multiStepsSum.current) + "/" + Formatting.formatTreatCoinAmount(multiStepsSum.total) :
                                        offer.isPercentage ? 
                                        (offer.coins + '%') : 
                                            !offer.allowsUsableAndCompetition ? 
                                            Formatting.formatTreatCoinAmount(offer.coinsCompetition) : 
                                            Formatting.formatTreatCoinAmount(offer.coins)
                            }
                        </p>
                        <p>{Content.getValue("treat_coins")}</p>
                        <div className="flex justify-center">
                            {
                                (offer.isUnlimited) ? 
                                (
                                    <>
                                        <img className="unlimited-indicator-list mobile" alt="Unlimited offer" width="18" height="auto" src={Images.imageURL('unlimited-indicator-white.png')} />
                                        <img className="unlimited-indicator-list desktop" alt="Unlimited offer" width="18" height="auto" src={Images.imageURL('unlimited-indicator-purple.png')} />
                                    </>
                                ) :
                                (null)
                            }
                            {
                                (offer.isDaily) ? 
                                (
                                    <div className="daily-indicator-list-container">
                                        <span className="daily-indicator-list-text featured">{offer.dailyCap}</span>
                                        <>
                                            <img className="daily-indicator-list mobile" alt="Daily offer" width="18" height="auto" src={Images.imageURL('daily-indicator-white.png')} />
                                            <img className="daily-indicator-list desktop" alt="Daily offer" width="18" height="auto" src={Images.imageURL('daily-indicator-purple.png')} />
                                        </>
                                    </div>
                                ) :
                                (null)
                            }
                            {
                                (!offer.allowsUsableAndCompetition) ? 
                                (
                                    <>
                                        <img

                                            width="18"
                                            height="18"
                                            className="award-indicator-list mobile"
                                            src={Images.imageURL("award-indicator-purple.png")}
                                        />
                                        <img

                                            width="18"
                                            height="18"
                                            className="award-indicator-list desktop"
                                            src={Images.imageURL("award-indicator-purple.png")}
                                        />
                                    </>
                                ) :
                                (null)
                            }
                        </div>
                    </button>
                </div>
            </div>
        </div>
    }



}

export default FeaturedOffer;