import Images from "utils/Images.js";

function OffersClickedCheckbox({ text, checked, setIsChecked }) {
    const handleInputChange = () => {
        setIsChecked(!checked);
    };
    return (
        <label className="offers-clicked-checkbox-label">
            <div className='offer-popup-report-input checkbox'>
                <label onClick={handleInputChange} className='offer-popup-grey-text-12'>{text}</label>
                <label className='offer-popup-report-checkbox-label'>
            <input
                        className='offer-popup-report-yes-no-toggle-checkbox'
                        type="checkbox"
                        value={checked}
                        onChange={handleInputChange}
                    />
                    <img
                        style={{ opacity: checked ? 0 : 1 }}
                        src={Images.imageURL('checkbox-circle4x.png')} alt="" />
                    <img
                        width={21} height={21} 
                        className={`offer-popup-report-checked ${checked ? 'displayed filter-by' : ''}`}
                        src={Images.imageURL('checkbox-circle-checked4x.png')} alt="" />

                </label>
            </div>
        </label>
    );
}

export default OffersClickedCheckbox;
