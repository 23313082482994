import React from 'react';
import Content from 'utils/Content.js';

const OfferThemeIndicator = ({ offer }) => {
    const { offerThemeID, offerTheme } = offer;
    return (
        <>
            <span className={`list-offer-theme-indicator-text ${offerTheme.toLowerCase().replaceAll('&', '')}`}>
                {Content.getValue("offer_themes")[offerThemeID.toString()]}
            </span>
        </>
    );
};

export default OfferThemeIndicator;