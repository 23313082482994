import Images from 'utils/Images';
import Content from 'utils/Content.js';
import { useEffect, useRef, useState } from 'react';
import PhoneWithCountryCodeInput from 'components/inputs/PhoneWithCountryCodeInput';
import VerificationCodeInput from 'components/inputs/VerificationCodeInput';
import ApiFacade from 'ApiFacade';
import Countdown from 'utils/Countdown';
import Alerts from 'utils/Alerts';
import Formatting from 'utils/Formatting.js';

const globalConf = require('global_conf.json');
const configuration = require('configuration.json');

const captchaConnection = globalConf.captcha_connection;
const urls = configuration.urls;

const captchaSiteKey = captchaConnection.site_key;
const googleCaptchaURL = urls.google_captcha_url;

const PhoneVerificationPopup = (props) => {

    const existingScript = document.getElementById('google-captcha');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = Formatting.format(googleCaptchaURL, ["(captchaSiteKey)"], [captchaSiteKey]);
        script.id = 'google-captcha';
        document.body.appendChild(script);
    }

    const { onComplete, setPhoneVerificationSuccessful } = props;

    const [step, setStep] = useState(1);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [showCounter, setShowCounter] = useState(false);
    const [randonKey, setRandonKey] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [verifyErrorMsg, setVerifyErrorMsg] = useState(null);
    const [isInvalidCode, setIsInvalidCode] = useState(null);

    const codeRef = useRef([]);

    useEffect(() => {
        if(step !== 3){
            setContinueDisabled(true);
        }
        else {
            setPhoneVerificationSuccessful(true);
        }
    }, [step, randonKey])


    const onVerificationCodeHandle = (code) => {
        setContinueDisabled(code ? false : true);
    }

    const onPhoneNumberChange = ({ phone, prefix }) => {
        setContinueDisabled( (phone?.length >= 6 && phone?.length <= 14) ? false : true);
        setPhoneNumber(`${prefix}_${phone}`);
    }

    const verifyCode = async () => {
        
        const code = codeRef.current.map(obj => obj.value).join('');

        if (!/^[0-9A-Z]+$/.test(code)) {
            Alerts.showErrorAlert(Content.getValue('verify_phone_message_wrong_code'));
            setIsInvalidCode(true);
        }

        else {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(captchaSiteKey, { action: 'submit' }).then(async function (captchaToken) {
            
                const res = await ApiFacade.verifyVerificationCode(code, phoneNumber, captchaToken);

                if (!res?.response) {
                    return Alerts.showErrorAlert(Content.getValue('verify_phone_message_something_went_wrong'));
                }

                if (res.response.code == 1) {
                    setStep(3)
                    setContinueDisabled(false);
                } else {
                    const message = Content.getValue(res.response.message);
                    if (res.response.code == 55) {
                        Alerts.showErrorAlert(message);
                        setIsInvalidCode(true);
                    } else if (res.response.code == 54) {
                        setVerifyErrorMsg(message);
                    } else {
                        Alerts.showErrorAlert(message);
                    }
                    setContinueDisabled(true);
                }
                });
            });
        }

    }

    const sendVerificationCode = async () => {
        if (!phoneNumber) {
            return;
        }

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(captchaSiteKey, { action: 'submit' }).then(async function (captchaToken) {
                const res = await ApiFacade.sendVerificationCode(phoneNumber, captchaToken);

                if (!res?.response) {
                    return Alerts.showErrorAlert(Content.getValue('verify_phone_message_something_went_wrong'));
                }
        
                if (res.response.code == 1) {
                    setShowCounter(true);
                    if (step != 2) {
                        setStep(2);
                    } else {
                        setRandonKey(randonKey + 1);
                    }
                } else {
                    Alerts.showErrorAlert(`<span class='remove-br'>${Content.getValue(res.response.message)}</span>`);
                }
            });
        });
    }


    return (
        <div className='whatsapp-popup-container'>
            {step == 1 && (
                <>
                    <img height={85} src={Images.imageURL('icons/colorful-sms-icon.png')} alt="whatsapp" />
                    <p className='whatsup-popup-main-title'>{Content.getValue("verify_phone_insert_phone_number")}</p>
                    <div className='phone-number-wrap'>
                        <PhoneWithCountryCodeInput
                            style={{ borderRadius: 99 }}
                            placeholder={Content.getValue("phone_number_placeholder")}
                            onChange={onPhoneNumberChange}
                            autoFocus
                        />
                    </div>
                    <p className='whatsup-popup-sub-title'>{Content.getValue("verify_phone_hint")}</p>
                    <button
                        className="hidden-btn confirm-button"
                        onClick={sendVerificationCode}
                        disabled={continueDisabled}
                    >
                        {Content.getValue("continue_button_text")}
                    </button>
                </>
            )}
            {step == 2 && (
                <>
                    <img height={85} src={Images.imageURL('icons/colorful-sms-icon.png')} alt="whatsapp" />
                    <p className='whatsup-popup-main-title'>{Content.getValue("verify_phone_verify_phone_number")}</p>
                    <p className='whatsup-popup-sub-title'>{Content.getValue("verify_phone_code_sent_message")}</p>
                    <VerificationCodeInput
                        onChange={onVerificationCodeHandle}
                        ref={codeRef}
                        key={randonKey}
                        errorMsg={verifyErrorMsg}
                        disabled={Boolean(verifyErrorMsg)}
                        isInvalidCode={isInvalidCode}
                    />
                    <button
                        className="hidden-btn confirm-button"
                        onClick={verifyCode}
                        disabled={continueDisabled}
                    >
                        {Content.getValue("verify_button_text")}
                    </button>
                    {!verifyErrorMsg && (
                        <span
                            className={`whatsup-popup-request-new ${showCounter > 0 ? 'text-disabled' : ''}`}
                            onClick={() => showCounter == 0 ? sendVerificationCode() : ''}
                        >
                            {Content.getValue("verify_phone_request_new_code")}
                            {showCounter && (
                                <>
                                    <> (</><Countdown seconds={60} onComplete={() => setShowCounter(false)} /><>s)</>
                                </>
                            )}
                        </span>
                    )}
                </>
            )}
            {step == 3 && (
                <>
                    <img height={85} src={Images.imageURL('check_circle_colorful.png')} alt="whatsapp" />
                    <p className='whatsup-popup-sub-title'>{Content.getValue("verify_phone_complete_message")}</p>
                    <p className='whatsup-popup-sub-title'>{Content.getValue("verify_phone_complete_message_2")}</p>

                    <button
                        className="hidden-btn confirm-button"
                        onClick={() => onComplete(true)}
                        disabled={continueDisabled}
                    >
                        {Content.getValue("close_button_text")}
                    </button>
                </>
            )}
        </div>
    )

}


export default PhoneVerificationPopup;