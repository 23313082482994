import OfferDifficultyIndicator from 'components/offers/Offer_Difficulty_Indicator.js';
import OfferThemeIndicator from 'components/offers/Offer_Theme_Indicator.js';
import BlurProvider from 'components/offer_providers/Blur_Provider.js';
import { memo, useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Content from 'utils/Content.js';
import Formatting from 'utils/Formatting.js';
import useTimer from 'utils/hooks/useTimer.js';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import { useContext } from 'react';

const configuration = require('configuration.json');
const featuredOfferTypes = configuration.featured_offer_types;

const secondToMillisecond = 1000;

const X2OfferHomepage = ({
    offers,
    type,
    isFirstTime,
    setIsFirstTime
}) => {
    const [state, setState] = useState({
        time: null,
        limited: false,
    });
    const [activeindex, setActiveIndex] = useState(0);
    const { handleOfferIsOpened } = useContext(OfferPopupContext);
    const offer = type !== featuredOfferTypes.x2_offer ? offers[activeindex] : offers;

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const handleSlideChange = (data) => setActiveIndex(data.realIndex);

    const ordinalSuffixOf = (i) => {
        const j = i % 10,
        k = i % 100;
        if (j === 1 && k !== 11) {
        return i + "st";
        }
        if (j === 2 && k !== 12) {
        return i + "nd";
        }
        if (j === 3 && k !== 13) {
        return i + "rd";
        }
        return i + "th";
    }

    const { hours, minutes, seconds } = useTimer(offer?.expireTime * secondToMillisecond);

    useEffect(() => {
        const isLimited = (hours <= 0)  && (minutes <= 0) && (seconds <= 0);
        if (isLimited) {
            setState(prev => ({
                ...prev,
                limited: true
            }));
            return;
        }
        const time = hours > 0 ?
            (padTo2Digits(hours) + ':' + padTo2Digits(minutes) + ':' + padTo2Digits(seconds)) :
            (padTo2Digits(minutes) + ':' + padTo2Digits(seconds));
        setState(prev => ({
            ...prev,
            time
        }))
        if (time && !isFirstTime) setIsFirstTime(true);
    }, [seconds]);

    const MemoizedSwiperSlide = memo(
        () => (<Swiper
            grabCursor
            centeredSlides
            spaceBetween={0}
            slidesPerView={'auto'}
            initialSlide={activeindex}
            slideToClickedSlide={true}
            onSlideChange={handleSlideChange}
            className='hot-offer-swiper desktop new'
        >
            {
                offers.map((item, idx) => (
                    <SwiperSlide key={idx} className={"mobile-width-auto desktop"}>
                        <div className='hot-offer-slider-slide-container desktop new'>
                            <div className='hot-offer-carousel-item new'>
                                {idx === 0 ? 'Most recent' : ordinalSuffixOf(idx + 1) + ' most recent'}
                            </div>
                            <h2 className="x2-offer-container-first-description-new">
                                {Content.getValue("x2offer_award")}
                            </h2>
                            <h2 id="hot-offer-time" className="hot-offer-time-new">
                                {state.time}
                            </h2>
                            <h2 className="hot-offer-left-new desktop">
                                {Content.getValue("minutes_left")}
                            </h2>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        ),
        (prevProps, nextProps) => prevProps.idx === nextProps.idx
    );

    MemoizedSwiperSlide.displayName = "MemoizedSwiperSlide";

    const offerElement = (
        <>
            <div className='x2-offer-container-second-container desktop new'>
                <div className='x2-offer-container-second new'>
                    <div className="list-offer-container-image x2-offer-container-image" onClick={() => { handleOfferIsOpened({ offer }); }}>
                        <OfferDifficultyIndicator difficultyLevel={offer.difficultyLevel} difficultyLevelID={offer.difficultyLevelID} imageType="white" />
                        <img className="hot-offer-image" src={offer.imageURL} alt={offer.description} width="36" height="auto" />
                        <OfferThemeIndicator offer={offer} />
                    </div>
                    <div className="list-offer-container-titles x2-offer-container-titles">
                        <div>
                            <div className="list-offer-app-name" onClick={() => { handleOfferIsOpened({ offer }); }}>{offer.appName}</div>
                            <div className="list-offer-description hot-offer-description" onClick={() => { handleOfferIsOpened({ offer }); }}>{offer.MainTitle}</div>
                            <div className='list-offer-hash-tags'></div>
                        </div>
                    </div>
                </div>
                <button 
                    className={`hot-offer-button new ${type !== featuredOfferTypes.hot_offer ? 'x2' : ''}`}
                    onClick={() => { handleOfferIsOpened({ offer }); }} >
                        <div className="hot-offer-button-div">
                            <span>
                                {
                                    type !== featuredOfferTypes.hot_offer ?
                                        !offer?.allowsUsableAndCompetition ?
                                        offer.coinsCompetition * 2 :
                                        offer.coins * 2 :
                                            !offer?.allowsUsableAndCompetition ? 
                                            offer?.newCoinsCompetition : 
                                            offer?.newCoins
                                }
                            </span>
                            {
                                (type === featuredOfferTypes.hot_offer && offer.coins < offer.newCoins) ?
                                <span className='hot-offer-button-div-inactive'>
                                    {
                                            !offer?.allowsUsableAndCompetition ? 
                                            Formatting.formatTreatCoinAmount(offer?.coinsCompetition) : 
                                            Formatting.formatTreatCoinAmount(offer?.coins)
                                    } 
                                </span> :
                                null
                            }
                        </div>
                    <span className="hot-offer-button-span">
                        {offer.isPercentage ? "% " : ""}
                        {type === featuredOfferTypes.hot_offer ? Content.getValue("treat_coin_abbreviation") : Content.getValue("treat_coins")}
                        </span>
                </button>
            </div>
        </>
    );

    return (
        <>
            {
                <div className="x2-offer-container desktop desktop-min-height-320 new relative">
                    <div id="x2-offer-container-first new" className="x2-offer-container-first desktop new homepage">
                        <h2 className="x2-offer-container-first-header-new">
                                { type !== featuredOfferTypes.x2_offer ?
                                    (
                                        offers?.length>0 ?
                                        <MemoizedSwiperSlide/>
                                        :
                                        null
                                    ) :
                                    Content.getValue("x2offer")
                                }
                            </h2>

                            {
                                type !== featuredOfferTypes.hot_offer ?
                                <>
                                    <h2 className="x2-offer-container-first-description-new">
                                        {Content.getValue("x2offer_award")}
                                    </h2>
                                    <h2 id="hot-offer-time" className="hot-offer-time-new margin-top-10">
                                        {state.time}
                                    </h2>
                                    <h2 className="hot-offer-left-new">
                                        {Content.getValue("minutes_left")}
                                    </h2>
                                </> :
                                null
                            }
                        </div>

                        {
                            !offer.isAvailable ?
                            (
                                <BlurProvider count={1}>
                                    { offerElement }
                                </BlurProvider>
                            ) :
                            offerElement
                        }
                    </div>
            }
        </>
    );
}

export default X2OfferHomepage;