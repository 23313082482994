import React, { createContext, useState } from 'react';
import UserData from 'User_Data.js';
import jwt from 'jwt-decode';

const GlobalContext = createContext();

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const GlobalContextProvider = ({ children }) => {

    const jwt_token = UserData.getItem(userDataKeys.jwt_token);
    let isGold = jwt_token && jwt(jwt_token) && jwt(jwt_token).isGold;

    let layout = null;
    if(isGold == 1){
        layout = "gold"
    }
    
    const [siteLayout, setSiteLayout] = useState(layout);

    return (
        <GlobalContext.Provider value={{ 
            siteLayout, 
            setSiteLayout 
        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalContextProvider };