import React from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Content from '../../../utils/Content';

function ReviewItem({ review }) {
    const { ImageUrl, ReviewTopicName, Title, ReviewText, dateCreated, mine } = review;
    return (
        <li className='offer-popup-reviews-list-item'>
            <div className="offer-popup-reviews-list-image">
                <img src={ImageUrl} alt="" className="image" />
            </div>

            <div className="offer-popup-reviews-list-item-desc">
                {mine ?
                    <p className='is-mine'>{Content.getValue('my_review')}</p>
                    : <></>}
                <p className="gray-8">{ReviewTopicName}</p>
                <div className="flex">
                    <h6 className="title">{Title}</h6>
                    <p className="gray-8">{dateCreated}</p>
                </div>
                <Rating
                    size="small"
                    emptyIcon={<StarIcon sx={{ color: '#D9D9D9' }} fontSize="inherit" />}
                    precision={0.05} name="read-only" value={review?.Rating} readOnly defaultValue={0} />
                <p className="text">{ReviewText}</p>
            </div>
        </li>
    );
}

export default ReviewItem;