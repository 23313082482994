const configuration = require('configuration.json');
const globalConf = require('global_conf.json');

const websiteConnection = globalConf.website_connection;
const paths = configuration.paths;

const host = websiteConnection.frontend_host;
const imagesPath = paths.images_path;

class Images {

  static imageURL(fileName) {
      return `${host}/${imagesPath}/${fileName}`;
    }

    static async createImageFile(url, fileName) {

      const response = await fetch(url);
      const data = await response.blob();

      const metadata = {
        type: data.type
      };
      const file = new File([data], fileName, metadata);
      return file;
    }


}

export default Images;