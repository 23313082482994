import React, { useEffect, useState, useMemo } from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Earnings from 'utils/Earnings.js';
import Formatting from 'utils/Formatting.js';
import CoinEarning from 'components/earnings/Coin_Earning.js';
import Content from 'utils/Content.js';
import CoinPayout from 'components/payouts/Coin_Payout.js';
import { Spinner } from 'react-spinners-css';
import { Waypoint } from 'react-waypoint';



const date = new Date();
const initialFilter = {
    month: '',
    year: '',
    minutesOffset: date.getTimezoneOffset() * (-1),
    excludeList: 0,
    filter: 0,
    page: 1,
    all: 1
}

const CoinEarningsList = () => {
    

    const filterOptionsAll = Content.getValue("earning_filter_options_all");

    const [coinsMonthDisplay, setCoinsMonthDisplay] = useState(filterOptionsAll)
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isRestricted, setIsRestricted] = useState(false);
    const [earningsHistory, setEarningsHistory] = useState(null);
    const [coinsEarnings, setCoinsEarnings] = useState([]);

    const [filter, setFilter] = useState(initialFilter);
    const [isAllFetched, setIsAllFetched] = useState(false);


    const earningStatuses = [
        { id: 0, value: Content.getValue("earning_filter_options_all") },
        { id: 3, value: Content.getValue("earning_filter_options_validated") },
        { id: 4, value: Content.getValue("earning_filter_options_cooking") },
    ];
    const earningCardsColors = Content.getValue("earning_cards_colors");
    const earningCardsColorsArray = Object.values(earningCardsColors);


    useEffect(() => {

        setError(false);
        setIsAllFetched(false);
        fetchScoresHistory();

    }, [filter])




    const fetchScoresHistory = async () => {

        try {
            if(filter.page == 1){
                setIsLoaded(false);
            }
            let res = await ApiFacade.myEarningsHistory(filter);
            setIsLoaded(true);
            if (res.ok) {
                if (res.response.coinsEarnings.length + coinsEarnings.length == res.response.total || res.response.coinsEarnings.length < res.response.pageSize) {
                    setIsAllFetched(true);
                }
                setEarningsHistory(res.response);
                if(filter.page == 1){
                    setCoinsEarnings(res.response.coinsEarnings);
                }else{
                    setCoinsEarnings(prev => [...prev, ...res.response.coinsEarnings]);
                }
            } else if (res.unauthorized) {
                setIsRestricted(true)
            } else {
                setError(true);
            }

        } catch (error) {
            setError(true);
        }

    }

    const changeCoinsMonth = (event) => {
        let newFilter = {...initialFilter};
        let dateSelected = event.target.value;
        let coinsMonthDisplay = Content.getValue('earning_filter_options_all');

        if (dateSelected !== 'all') {
            let month = dateSelected.split('-')[0]
            let year = dateSelected.split('-')[1]
            newFilter = {...newFilter, ...{
                month: parseInt(month),
                year: parseInt(year),
                all: 0,
            }}
            coinsMonthDisplay = new Date(parseInt(year), parseInt(month) - 1, 1).toLocaleString(Content.getValue("date_localization"), { month: 'long' });
        } else {
            newFilter = { ...newFilter, all: 1 }
        }
        
        setFilter(newFilter);
        setCoinsMonthDisplay(coinsMonthDisplay);

    }
    
    


    const generateMonthsList = (firstMonth, firstYear) => {
        let lastYear = date.getUTCFullYear();
        let lastMonth = date.getUTCMonth() + 1;
        let months = [];
        while (lastYear > firstYear || (lastYear === firstYear && lastMonth >= firstMonth)) {
            months.push({ monthAndYear: lastMonth + '-' + lastYear, monthName: new Date(lastYear, lastMonth - 1, 1).toLocaleString(Content.getValue("date_localization"), { month: 'long' }) + ' ' + lastYear });
            if (lastMonth > 1) {
                lastMonth--;
            }
            else {
                lastMonth = 12;
                lastYear--;
            }
        }
        return (
            <>
                <option value='all' key='all'>{filterOptionsAll}</option>
                {months.map(item => (
                    <option value={item.monthAndYear} key={item.monthAndYear}>{item.monthName}</option>
                ))}
            </>
        );
    }

    const monthsSelectOptions = useMemo(() => {
        return generateMonthsList(earningsHistory?.registrationMonth, earningsHistory?.registrationYear)
    },[earningsHistory?.registrationMonth, earningsHistory?.registrationYear])


    const refreshCoinsList = () => {
        setIsLoaded(false);
        fetchScoresHistory();
    }

    const filterHandler = (e) => {
        if (filter === e.target.value) return;
        setFilter(prev => ({ ...prev, 
            filter: parseInt(e.target.value),
            page: 1
        }))
    }

    


    if (error) {
        return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
    } else if (isRestricted) {
        return <div className="error-message">{Backend.loadBackendMessages().restrictedMessage}</div>;
    } else if (!isLoaded) {
        return (
            <>
                <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>
                <Spinner color="#6BC6F5" />
            </>
        )
    } else {
        let monthSelectValue = filter?.month && filter?.year ? `${filter.month}-${filter.year}` : Content.getValue('earning_filter_options_all');

        return (
            <div className="coins-history-section desktop">
                <div className="coins-div desktop">
                    <div className="coins-month-selection-wrapper desktop">
                        <select
                            className="coins-month-selection desktop"
                            value={filter?.all ? 'all' : monthSelectValue}
                            onChange={changeCoinsMonth}
                        >
                            {monthsSelectOptions}
                        </select>
                    </div>

                    <div className="month-total-container earning">
                        <div className='treat-coins-total-wrap'>
                            <button className="button-hidden">
                                <img className="treat-coins-validity-hint-icon " src={Images.imageURL('earnings/info-icon.png')} alt="Treat Coins validity hint" height="20" width="auto" onClick={() => Earnings.showTreatCoinsValidityHint()} />
                            </button>
                            <div className="lifetime-treat-coins-earned-label ">{Formatting.format(Content.getValue("lifetime_treat_coins_earned_label"), ["(lifetimeCoinsEarned)"], [Formatting.formatTreatCoinAmount(earningsHistory?.lifetimeCoinsEarned)])}</div>
                            <div className="monthly-treat-coins-label ">{filter?.all ? Content.getValue("treat_coins_available_to_use_now") : Formatting.format(Content.getValue("total_treat_coins_earned_in_month"), ["(month)"], [coinsMonthDisplay])}</div>
                            <div className="monthly-treat-coins-amount-container ">
                                <div className="monthly-treat-coins-amount-number">{Formatting.formatTreatCoinAmount(filter?.all ? earningsHistory?.availableToUse : earningsHistory?.monthTotal)}</div>
                                <div className="monthly-treat-coins-amount-tc">{Content.getValue("treat_coins")}</div>
                            </div>
                        </div>
                        <div className='treat-coins-detailed-wrap'>
                            <div className="monthly-treat-coins-breakdown-container  gap-20 dashed-top">
                                <div className="monthly-treat-coins-breakdown-item ">
                                    <div className="treatcoins-breakdown-title">{Content.getValue("treat_coins_cooking")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item ">
                                    <div className="treatcoins-breakdown-title">{filter?.all ? Content.getValue('total_treat_coins_earned_in_all') : Content.getValue("treat_coins_available_to_use_now")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item ">
                                    <div className="treatcoins-breakdown-title">{Content.getValue("competition_only_treat_coins")}</div>
                                </div>
                            </div>
                            <div className="monthly-treat-coins-breakdown-container gap-20 bottom-breakdown">
                                <div className="monthly-treat-coins-breakdown-item ">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(earningsHistory?.waitingToBeValidated)}  {Content.getValue("treat_coin_abbreviation")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item ">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(filter?.all ? earningsHistory?.lifetimeCoinsEarned : earningsHistory?.availableToUse)}  {Content.getValue("treat_coin_abbreviation")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item ">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(earningsHistory?.competitionValid)} {Content.getValue("treat_coin_abbreviation")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="coins-month-selection-wrapper desktop filtered margin-bottom-30">
                    <p className='coins-month-selection-wrapper-desc'>{Content.getValue("filtered_by")}</p>
                    <select
                        className="coins-month-selection desktop"
                        onChange={filterHandler}
                        value={filter?.filter || ""}
                    >
                        {earningStatuses.map((status, idx) => (
                            <option className='options-list-item' value={status.id} key={idx}>{status.value}</option>
                        ))}
                    </select>
                </div>

                <div className="coins-list-swipe desktop">
                    <button className="swipe-down-container desktop hidden-button" onClick={() => refreshCoinsList()}>
                        <img className="refresh-my-treats-icon" alt="Refresh" width="28" height="28" src={Images.imageURL('refresh-icon.png')} ></img>
                    </button>
                </div>

                <div>
                    {coinsEarnings.map((item, index) => item.isConversion === 1 ?
                        (
                            <CoinPayout
                                key={index}
                                isEarning={true}
                                amount={item.amount}
                                targetAmount={item.targetAmount}
                                USDAmount={item.USDAmount}
                                status={item.status}
                                transactionID={item.transactionID}
                                platform={item.platform}
                                date={item.date}
                                time={item.time}
                                isConversion={item.isConversion}
                                bgColor={earningCardsColorsArray[index % earningCardsColorsArray.length]}
                            />
                        ) :
                        (
                            <CoinEarning
                                key={index}
                                coinsEarningAvailabilityID={item.coinsEarningAvailabilityID}
                                providerName={item.providerName}
                                logoURL={item.logoURL}
                                description={item.description}
                                referral={item.referral}
                                referralType={item.referralType}
                                amount={item.amount}
                                USDAmount={item.USDAmount}
                                date={item.date}
                                time={item.time}
                                transactionID={item.transactionID}
                                referralEarningID={item.referralEarningID}
                                coinsEarningValidity={item.coinsEarningValidity}
                                appName={item.appName}
                                position={item.position}
                                bgColor={earningCardsColorsArray[index % earningCardsColorsArray.length]}
                                isGold={item.isGold}
                                AssetCollection={item.AssetCollection}
                                AssetName={item.AssetName}
                                AssetSerialNumber={item.AssetSerialNumber}
                                AssetPrice={item.AssetPrice}
                                InvoiceUniqueNumber={item.InvoiceUniqueNumber}
                            />
                        ))}
                    {coinsEarnings.length > 0 && (
                        <Waypoint
                            onEnter={() => {
                                if (!isAllFetched) {
                                    setFilter(filter => ({ ...filter, page: filter.page + 1 }));
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }


}

export default CoinEarningsList;