import React from 'react';

import Content from 'utils/Content.js';

function StepProgress({ stepNumber = 1, totalSteps = 5, questionNumber = 1, totalQuestions = 8 }) {

    return (
        <div className='step_progress'>
            <h2 className='intro-survey-quiz-header-font step'>
                {Content.getValue("step")} {stepNumber} {Content.getValue("of")} {totalSteps} 
            </h2>
            {
                stepNumber !== 5 ?
                <h2 className='intro-survey-quiz-header-font question'>
                    {Content.getValue("question")} {questionNumber} {Content.getValue("out_of")} {totalQuestions}
                </h2> :
                <h2 className='intro-survey-quiz-header-font question'>
                    {Content.getValue("intro_survey_final_step")}
                </h2>
            }
        </div>
    )
}

export default StepProgress;