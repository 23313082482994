import { useState, useEffect } from 'react';


const Countdown = ({ seconds, onComplete }) => {

    const [counter, setCounter] = useState(seconds);

    useEffect(() => {
        if(counter > 0){
            setTimeout(() => setCounter(counter - 1), 1000);
        }else{
            onComplete(true)
        }
    }, [counter]);

    return (
        <>
            {counter}
        </>
    );

}


export default Countdown;