import React from 'react';
import ScrollToTop from 'components/widgets/Scroll_To_Top.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import CoinEarningsList from 'components/earnings/Coin_Earnings_List.js';
import Footer from 'components/sections/Footer.js';
import NewsTrack from 'components/live_news/News_Track.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

class MyTreatCoinEarnings extends React.Component {

    componentDidMount() {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.my_treatcoin_earnings });
    }

    render() {
        return (
            <>
                <div id="main">

                    <div id="content">

                        <ContentPageHeader />
                        <NewsTrack />
                        <section className="content-page-section">
                            <div>
                                <div className="title-container">
                                    <h1 className="page-title earnings-page desktop">{Content.getValue('menu_title_my_treat_coin_earnings')}</h1>
                                    <div className="my-treat-coin-earnings-description desktop">{Content.getValue("my_treat_coin_earnings_description")}</div>
                                </div>
                                <div className="page-container coins-history-container desktop">
                                    <CoinEarningsList  />
                                    <ScrollToTop containerClass="scroll-to-top-container desktop my-treat-coin-earnings mobile" />
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

export default MyTreatCoinEarnings;