import React from 'react';
import { useNavigate } from 'react-router-dom';
import Images from 'utils/Images.js';
import StartNowButton from 'components/buttons/Start_Now_Button.js';
import TopRightCloseIcon from 'components/widgets/Top_Right_Close_Icon.js';
import IntroSurveyAvailableCall from 'components/survey/Intro_Survey_Available_Call.js';

import Content from 'utils/Content.js';

function IntroSurveyStartingSection({ quizStartHandler }) {
    const navigate = useNavigate();

    return (
        <div className='intro-survey-starting-section-container desktop'>
            <IntroSurveyAvailableCall navigate={navigate} />
            <div className='intro-survey-starting-section-absolute desktop' />
            <div className='intro-survey-starting-section-absolute-shadow desktop' />
            <TopRightCloseIcon url={configuration.paths.playground} />
            <div className='intro-survey-starting-section desktop'>
                <div className='intro-survey-starting-section-info desktop'>
                    <div className='intro-survey-starting-section-info-logo desktop'>
                        <img 
                            src={Images.imageURL('treat-coin-logo-new.png')}
                            alt='Logo'
                            height='32'
                        /> 
                    </div>
                    <div>
                        <h1 className='intro-survey-starting-section-header desktop'>{ Content.getValue("intro_survey_starting_section_header") }</h1>
                        <h4 className='intro-survey-starting-section-content desktop'>
                            { Content.getValue("intro_survey_starting_section_content") }
                        </h4>
                    </div>
                    <StartNowButton quizStartHandler={quizStartHandler} />
                </div>

            </div>
        </div>
    )
}

export default IntroSurveyStartingSection;