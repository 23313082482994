import React from 'react';

import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import CompetitionInterval from 'utils/Competition_Interval.js';
import Content from 'utils/Content.js';
import ShowMore from 'components/wrappers/Show_More';

const date = new Date();
const timeDiff = date.getTimezoneOffset() * 60000;


const badgeDimensions = {
    1: { width: 196, height: 196 }, 2: { width: 71, height: 80 }, 3: { width: 63.5, height: 70 }
};

const badgeClasses = {
    1: 'first-position-badge', 2: 'second-position-badge', 3: 'third-position-badge'
};

let thisComponent;

class CurrentRewards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            currentRewards: {}
        };
        thisComponent = this;
    }

    componentDidMount() {
        let preferredLanguage = Content.getPreferredLanguage();
        const req = ApiFacade.currentRewards(preferredLanguage);
        req.then(function (res) {
            if (res.ok){
                let result = res.response;
                thisComponent.setState({
                    isLoaded: true,
                    currentRewards: { top3: [result.rewards[1], result.rewards[0], result.rewards[2]], prizeFundAmount: result.prizeFundAmount, prizeFundCurrency: result.prizeFundCurrency }
                });
            } else {
                thisComponent.setState({
                    isLoaded: true,
                    error: res.error
                });
            }
        });
    }

    render() {
        const thisYear = new Date(date.getTime() + timeDiff).toLocaleString(Content.getValue("date_localization"), { year: 'numeric' });
        const { error, isLoaded, currentRewards } = this.state;

        if (error) {
            return <section className="error-message">{Backend.loadBackendMessages().errorMessage}</section>;
        } else if (!isLoaded) {
            return <section className="loading-message">{Backend.loadBackendMessages().loadingMessage}</section>;
        } else {
            return (
                <section>
                    <h2 className="top-monthly-prizes" id="current-rewards-title">{Formatting.format(Content.getValue("prizes_for_month"), ["(thisMonth)", "(thisYear)"], [CompetitionInterval(), thisYear])}</h2>
                    <ShowMore sectionName='currentRewards'>
                    <div className="homepage-rewards desktop">
                        <div className="total-reward-wrapper">
                            <div className="treatcoin-rewards-description-container">
                                <img 
                                    src={Images.imageURL('prizes-section-background.png')} 
                                    alt="Prizes background" 
                                    className="mobile-img"
                                    width="370" 
                                />
                            </div>
                        </div>

                        <div>
                            <div className="total-reward-description-div homepage">
                                <div className="total-reward-title"></div>
                                <div className="total-reward-description-wrapper"></div>
                                <div className="divided-between-text">{Content.getValue("divided_between_top_100")}</div>
                                <div className="total-reward-text">
                                {`${Formatting.formatTreatCoinAmount(currentRewards.prizeFundAmount)} ${Content.getValue("treat_coin_abbreviation")}`}
                                </div>
                            </div>
                            <h2 className="first-three-places">{Content.getValue("top_3_prizes_label")}</h2>
                            <div className="first-three-places-container gap-10">
                                <div className="reward-wrapper">
                                    <img 
                                        src={currentRewards.top3?.[0]?.rewardImageURL}
                                        alt={Content.getValue("highscore_first_prize")} 
                                        width={64} 
                                        height={70} 
                                    />
                                     <div>  
                                       { Formatting.formatTreatCoinAmount(currentRewards.top3?.[0]?.amount) } 
                                     </div>
                                    <div>  
                                        { Content.getValue('treat_coins') } 
                                    </div> 
                                </div>
                                <div className="reward-wrapper">
                                    <img 
                                        src={currentRewards.top3?.[1]?.rewardImageURL}
                                        alt={Content.getValue("highscore_second_prize")} 
                                        className="first-image"
                                        width={160} 
                                        height={160} 
                                    />
                                     <div>  
                                       { Formatting.formatTreatCoinAmount(currentRewards.top3?.[1]?.amount) } 
                                     </div>
                                    <div>  
                                        { Content.getValue('treat_coins') } 
                                    </div>  
                                </div>
                                <div className="reward-wrapper">
                                    <img 
                                        src={currentRewards.top3?.[2]?.rewardImageURL}
                                        alt={Content.getValue("highscore_third_prize")} 
                                        width={64} 
                                        height={70} 
                                    />
                                      <div>  
                                       { Formatting.formatTreatCoinAmount(currentRewards.top3?.[2]?.amount) } 
                                     </div>
                                    <div>  
                                        { Content.getValue('treat_coins') } 
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    </ShowMore>
                </section>
            );
        }
    }

    renderBadgeImage(position) {
        const imageURL = Images.imageURL('place-' + position + '-reward.png');
        const description = 'position ' + position + ' badge';
        const dimensions = badgeDimensions[position];
        const imageClass = badgeClasses[position];
        
        return (
            <img src={imageURL} width={dimensions.width} height={dimensions.height} alt={description} className={imageClass}/>
        );
    }
}

export default CurrentRewards;