import React from 'react';
import Images from 'utils/Images.js';

import Content from 'utils/Content.js';


const OfferDifficultyIndicator = ({ imageType, difficultyLevel, difficultyLevelID }) => {
    return (
        <>
            <img
                className="list-offer-difficulty-image"
                src={Images.imageURL('difficulties/' + difficultyLevel?.toLowerCase() + '-' + imageType + '.png')}
                alt={difficultyLevel}
                width="50"
                height="auto"
            />
            <span className="difficulty-popup-image-container" >
                <div className="difficulty-popup-text">{Content.getValue("offer_difficulties")[difficultyLevelID?.toString()]}</div>
                <img className="difficulty-popup-image" src={Images.imageURL('difficulty-popup.png')} alt="Difficulty popup" width="79" height="28" />
            </span>
        </>
    );
}

export default OfferDifficultyIndicator;