import React from 'react';

import UserData from 'User_Data.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';


const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;


class ConnectToDiscordUsername extends React.Component {

constructor(props) {
    super(props);
}

render() {

        return (
           <div className="title-container welcome-page">
                <h1 className="page-title welcome-title">{Formatting.format(Content.getValue("welcome_to_treatcoin_title_username"), ["(username)"], [UserData.getItem(userDataKeys.username)])}</h1>
            </div>
        );

}

}

export default ConnectToDiscordUsername;