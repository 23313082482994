import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Alerts from 'utils/Alerts.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import Platform from 'utils/Platform.js';
import { redirectWithLangFunc } from './RedirectWithLang';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;

const MySwal = withReactContent(Swal);


let introSurveyQuestions;

class IntroSurveyAnswersStorage {

    static initializeAnswersStorage() {
        introSurveyQuestions = Content.getValue("intro_survey_questions");
        UserData.setItem(userDataKeys.intro_survey_answers, JSON.stringify([]));
    }

    static addSingleSelectionAnswer(questionIndex, answerIndex) {
        let introSurveyQuestion = introSurveyQuestions[questionIndex];
        let availableAnswers = introSurveyQuestion.answers;
        this.addAnswer(questionIndex, { questionType: introSurveyQuestion.type, questionContent: introSurveyQuestion.content, questionAvailableAnswers: availableAnswers, answer: answerIndex, answerContent: availableAnswers[answerIndex] });
    }

    static addMultipleSelectionAnswer(questionIndex, answerIndexesArray) {
        let introSurveyQuestion = introSurveyQuestions[questionIndex];
        let availableAnswers = introSurveyQuestion.answers;
        this.addAnswer(questionIndex, { questionType: introSurveyQuestion.type, questionContent: introSurveyQuestion.content, questionAvailableAnswers: availableAnswers, answer: answerIndexesArray, answerContent: answerIndexesArray.map(answerIndex => availableAnswers[answerIndex]) });
    } 

    static addDropdownSelectionAnswer(questionIndex, dropdownIndex) {
        let introSurveyQuestion = introSurveyQuestions[questionIndex];
        let availableAnswers = introSurveyQuestion.answers;
        this.addAnswer(questionIndex, { questionType: introSurveyQuestion.type, questionContent: introSurveyQuestion.content, questionAvailableAnswers: availableAnswers, answer: dropdownIndex, answerContent: availableAnswers[dropdownIndex] });
    }

    static addRangeSelectionAnswer(questionIndex, value) {
        let introSurveyQuestion = introSurveyQuestions[questionIndex];
        this.addAnswer(questionIndex, { questionType: introSurveyQuestion.type, questionContent: introSurveyQuestion.content, minValue: introSurveyQuestion.minValue, maxValue: introSurveyQuestion.maxValue, answer: value });
    }

    static addOpenQuestionAnswer(questionIndex, surname, country, state, city, dateOfBirth, phoneNumber, gender) {
        let introSurveyQuestion = introSurveyQuestions[questionIndex];
        this.addAnswer(questionIndex, { questionType: introSurveyQuestion.type, questionContent: introSurveyQuestion.content, answer: { surname: surname, country: country, state: state, city: city, dateOfBirth: dateOfBirth, phoneNumber: phoneNumber, gender: gender } });
    }

    static addAnswer(questionIndex, data) {
        let answers = JSON.parse(UserData.getItem(userDataKeys.intro_survey_answers));
        answers = answers.filter(answer => (answer.questionIndex !== questionIndex) && !((answer.questionIndex === 2 || answer.questionIndex === 3) && (questionIndex === 2 || questionIndex === 3)));
        data.questionIndex = questionIndex;
        answers.push(data);
        UserData.setItem(userDataKeys.intro_survey_answers, JSON.stringify(answers));
    }

    static fetchAnswer(questionIndex) {
        let answers = JSON.parse(UserData.getItem(userDataKeys.intro_survey_answers));
        let selection = answers.filter(answer => answer.questionIndex === questionIndex);
        if (selection.length === 1 && selection[0] !== null) return selection[0].answer;
        else return null;
    }

    static async sendAnswersToServer(nextUrl, setStatus) {
        const answers = JSON.parse(UserData.getItem(userDataKeys.intro_survey_answers));
        const startingTime = UserData.getItem(userDataKeys.intro_survey_starting_time);
        const timeTaken = Math.floor((Date.now() - startingTime) / 1000);

        let robotIconURL = Images.imageURL('robot-icon.png');

        const platform = Platform.getPlatformName();

        const req = ApiFacade.submitIntroSurvey(platform, answers, timeTaken);
        req.then(
                function (res) {
                    if (res.ok){
                        let result = res.response;
                        if (nextUrl !== null && result.tokenValid === true && result.accountSuspended === false) {
                            UserData.setItem(userDataKeys.intro_survey_available, false);
                        }
                        setStatus(result.legitimate);
                        UserData.removeItem(userDataKeys.intro_survey_starting_time);
                        if (!result.legitimate) {
                            Alerts.introSurveyLegitimacyWarning();
                        }    
                        else redirectWithLangFunc(nextUrl);
                    } else if (res.unauthorized){
                        //...
                    } else {
                        MySwal.fire({
                            title: '<p>' + Content.getValue("error_submitting_survey_title") + '</p>',
                            html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                            showCancelButton: false,
                            confirmButtonText: Content.getValue("close_button_text"),
                            customClass: {
                                confirmButton: 'standard-confirm-button'
                            }
                        });
                    }
                },
                () => {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("error_submitting_survey_title") + '</p>',
                        html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: false,
                        confirmButtonText: Content.getValue("close_button_text"),
                        customClass: {
                            confirmButton: 'standard-confirm-button'
                        }
                    });
                }
            );
    }

    static clearAnswersStorage() {
        UserData.removeItem(userDataKeys.intro_survey_answers);
    }
}

export default IntroSurveyAnswersStorage;