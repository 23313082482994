import React, { useState, useEffect, forwardRef } from 'react';

const VerificationCodeInput = forwardRef(({ onChange, fields, disabled, errorMsg, isInvalidCode, numbersOnly }, ref) => {

    const fieldsNumber = fields || 6;
    const [code, setCode] = useState([...Array(fieldsNumber).fill('')]);


    useEffect(() => {
        if(ref?.current){
            ref.current[0].focus();
        }
    }, []);

    const handleChange = (index, value) => {
        if (numbersOnly) {
            if (!/^\d$/.test(value)) return;
        } 
        else {
            if (!/^[0-9A-Za-z]$/.test(value)) return;
        }
          
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value !== '' && index < (fieldsNumber - 1)) {
            ref.current[index + 1].focus();
        }
        let verificationCode = newCode.join('');
        if (verificationCode?.length === fieldsNumber && value !== '') {
            onChange(verificationCode);
        } else {
            onChange(false);
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace') {
            if (code[index] !== '') {
                const newCode = [...code];
                newCode[index] = '';
                setCode(newCode);
                onChange(false);
            }
            if(index > 0) {
                ref.current[index - 1].focus();
            }
        }
    };

    const handlePaste = async (index) => {
        const clipboard = await navigator.clipboard.read();
        const blob = await clipboard[0].getType("text/plain");
        const clipboardText = await blob.text();
        if (clipboardText.length > fieldsNumber) {
            return;
        }
        if (numbersOnly) {
            if (!/^\d+$/.test(clipboardText)) {
                return;
            }
        } 
        else {
            if (!/^[0-9A-Za-z]+$/.test(clipboardText)) {
                return;
            }
        }
        const clipboardTextArr = clipboardText.split('');
        ref.current[index].focus();
        let newCode = [...code];
        let counter = 0;
        newCode.map((c, idx) => {
            if(idx < index){
                return;
            }
            newCode[idx] = clipboardTextArr[counter];
            counter++;
        })
        setCode(newCode);
        let verificationCode = newCode.join('');
        if (verificationCode?.length === fieldsNumber && clipboardText !== '') {
            onChange(newCode);
        }else{
            onChange(false);
        }
    };


    return (
        <>
            <div className={`code-verification-wrap ${isInvalidCode ? 'invalid-verification' : ''}`}>
                {code.map((digit, index) => (
                    !disabled
                        ? (
                            <input
                                key={index}
                                ref={(el) => (ref.current[index] = el)}
                                type="text"
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                className="code-verification-input"
                                onFocus={(e) => e.target.select()}
                                onPaste={() => handlePaste(index)}
                            />
                        )
                        : <div key={index} className='disabled-verification-input'></div>
                ))}
            </div>
            {errorMsg && (<p className='color-red'>{errorMsg}</p>)}
        </>
    );

})

VerificationCodeInput.displayName = 'VerificationCodeInput';


export default VerificationCodeInput;