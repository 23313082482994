import ApiFacade from 'ApiFacade.js';
import Alerts from 'utils/Alerts.js';
import Content from 'utils/Content.js';

import UserData from 'User_Data.js';

const configuration = require('configuration.json');

const businessLogic = configuration.business_logic;
const liveNotificationTypes = configuration.live_notification_types;
const broadcastChannels = configuration.broadcast_channels;
const userDataKeys = configuration.user_data_keys;

const liveNotificationsFetchingInterval = businessLogic.live_notifications_fetching_interval;
const liveNotificationsFetchingDelay = businessLogic.live_notifications_fetching_delay;

export default class NotificationsFetcher {
    constructor() {
        this.interval = null;
        this.notificationsProcessed = [];

        const handleEvent = (event) => {
            try {
                const message = event.data;
                if (message.receiveLiveNews === true) {
                    this.newsChannel = new BroadcastChannel(broadcastChannels.news_channel);
                    this.incomingHotOfferChannel = new BroadcastChannel(broadcastChannels.incoming_hot_offer_channel);
                    this.coinsEarningChannel = new BroadcastChannel(broadcastChannels.coins_earning_channel);
                    this.setLiveInterval();
                }
                else if (message.receiveLiveNews === false) {
                    this.removeActions();
                }
            } catch {
                this.removeActions();
            }
        };
    
        let liveNewsPreferencesChannel = new BroadcastChannel(broadcastChannels.live_news_preferences_channel);

        liveNewsPreferencesChannel.addEventListener('message', handleEvent);


        if (ApiFacade.isLoggedIn() && UserData.getItem(userDataKeys.receive_live_news)) {
            this.newsChannel = new BroadcastChannel(broadcastChannels.news_channel);
            this.incomingHotOfferChannel = new BroadcastChannel(broadcastChannels.incoming_hot_offer_channel);
            this.coinsEarningChannel = new BroadcastChannel(broadcastChannels.coins_earning_channel);
            this.setLiveInterval();
        }

            
    }

    sendNoteForSuccess(data) {
        const notificationsToSend = data.
        filter((each) => each.ID !== undefined).
        reduce((res, each) => {
            res.push({ ID: each["ID"] });
            return res;
        }, []);
        if (notificationsToSend.length > 0) {
            const req = ApiFacade.saveDeliveredNotifications(notificationsToSend);
            req.catch(() => {});
        }
    }

    getAndProcessNotifications() {
        this.getNotifications()
            .then(res => {
                if (res) {
                    res = res.filter(each => !this.notificationsProcessed.includes(each.ID));
                    res.forEach(element => {
                        this.notificationsProcessed.push(element.ID);
                    });

                    const liveNews = res.find(each => each.NotificationType === liveNotificationTypes.live_news_statistics);
                    if (liveNews) {
                        UserData.setItem(userDataKeys.live_news_content, liveNews.Text);
                        this.newsChannel.postMessage(liveNews.Text);
                    }

                    const incomingHotOffers = res.filter(each => (each.NotificationType === liveNotificationTypes.incoming_hot_offer));
                    let incomingHotOffersCount = incomingHotOffers.length;
                    if (incomingHotOffersCount > 0) {
                        this.incomingHotOfferChannel.postMessage({ newHotOffers: incomingHotOffersCount, text: Content.getValue("incoming_offer_text") });
                    }

                    const coinsEarnedNotifications = res.filter(each => (each.NotificationType === liveNotificationTypes.coins_earned_online || each.NotificationType === liveNotificationTypes.coins_earned_offline));
                    coinsEarnedNotifications.forEach(element => {
                        this.coinsEarningChannel.postMessage({ text: element.Text });
                    });

                    const dailyEarningPlanCompletedNotifications = res.filter(each => each.NotificationType === liveNotificationTypes.daily_earning_plan_completed);
                    dailyEarningPlanCompletedNotifications.forEach(element => {
                        Alerts.displayDailyEarningCompletedPopup(element.DailyEarningPlanDate);
                    });

                    const withdrawalApprovedNotifications = res.filter(each => each.NotificationType === liveNotificationTypes.withdrawal_approved);
                    withdrawalApprovedNotifications.forEach(element => {
                        Alerts.displayWithdrawalApprovedPopup(element.Amount);
                    });
                }
            });
    }

    getNotifications() {
        let enforceLiveNews = UserData.getItem(userDataKeys.enforce_live_news);
        const req = ApiFacade.incomingNotifications(enforceLiveNews);
        return req.then( (res) => {
            if (res.ok){
                const result = res.response;
                if (enforceLiveNews) {
                    UserData.setItem(userDataKeys.enforce_live_news, 0);
                }
                this.sendNoteForSuccess(result.notifications);
                return result.notifications;
            } else {
                this.removeActions();
                return null;
            }
        }).catch(() => this.removeActions());
    }

    setLiveInterval() {
        setTimeout(() => {
            this.getAndProcessNotifications();
        }, liveNotificationsFetchingDelay);
        this.interval = setInterval(() => {
            this.getAndProcessNotifications();
        }, liveNotificationsFetchingInterval);
    }

    removeActions() {
        try {
            UserData.removeItem(userDataKeys.live_news_content);
            this.newsChannel?.postMessage(null);
            clearInterval(this.interval);
        } catch {
            clearInterval(this.interval);
        }
    }
}