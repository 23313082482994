import AccountAccessPageHeader from 'components/sections/Account_Access_Page_Header.js';
import StartYourJourneyForm from 'components/forms/Start_Your_Journey_Form.js';
import Footer from 'components/sections/Footer.js';

import Formatting from 'utils/Formatting.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const globalConf = require('global_conf.json');

const captchaConnection = globalConf.captcha_connection;

const captchaSiteKey = captchaConnection.site_key;

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;
const urls = configuration.urls;

const googleCaptchaURL = urls.google_captcha_url;

const StartYourJourney = () => {
    const navigate = useNavigate();
    const existingScript = document.getElementById('google-captcha');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = Formatting.format(googleCaptchaURL, ["(captchaSiteKey)"], [captchaSiteKey]);
        script.id = 'google-captcha';
        document.body.appendChild(script);
    }

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.start_your_journey });
    }, []);

    return (
        <>
            <div id="main" className="light-mode">
                <div id="content">
                    <AccountAccessPageHeader />
                    <section className="light-mode">
                        <div>
                            <div className="page-container light-mode">
                                <StartYourJourneyForm navigate={navigate} />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer lightMode={true} />
            </div>
        </>
    );
};

export default StartYourJourney;