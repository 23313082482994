import React, { useState, useEffect, memo } from 'react';
import ApiFacade from 'ApiFacade.js';
import Platform from 'utils/Platform.js';
import X2OfferPlayground from 'components/offers/X2_Offer_Playground.js'

const configuration = require('configuration.json');
const incompleteOfferModes = configuration.incomplete_offer_modes;
const secondToMillisecond = 1000;

function PlaygroundDynamicTitle() {
    const [state, setState] = useState({
        x2Offer: null,
        error: null,
        isLoaded: false,
        isRestricted: false,
    })
    const [isFirstTime, setIsFirstTime] = useState(false);

    useEffect(() => {
        if (ApiFacade.isLoggedIn()) {
            const platform = Platform.getPlatformName();
            let req = ApiFacade.x2Offers(platform);
            req.then(function (res) {
                    if (res.ok){
                        let result = res.response;
                        setState({
                            isLoaded: true,
                            x2Offer: result.x2Offer
                        });
                    } else if (res.unauthorized){
                        setState({
                            isLoaded: true,
                            isRestricted: true
                        });
                    } else {
                        setState({
                            isLoaded: true,
                            error: res.error
                        });
                    }
                },
                    (error) => {
                        setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
                .catch(error => setState({ isLoaded: true, error }))
        }
    }, []);

    const { x2Offer } = state;
    const isX2Offer = x2Offer && x2Offer.expireTime * secondToMillisecond >= Date.now();
    const offer =  isX2Offer ? x2Offer :null;
    if (!offer) {
        return (null);
    }
    else {
        return (
            <X2OfferPlayground
                key={`_offer-x2-offer-${offer.ID}_${Date.now()}`}
                offer={offer}
                mode={incompleteOfferModes.recommended_offer}
                isFirstTime={isFirstTime}
                setIsFirstTime={setIsFirstTime}
            />
        );
    }
}

export default memo(PlaygroundDynamicTitle);