import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Footer from "components/sections/Footer.js";
import { useEffect, useState } from 'react';
import { Mixpanel } from "utils/User_Events_Util.js";
import Platform from 'utils/Platform.js';
import Content from 'utils/Content.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import Top12OfferPageCard from 'components/top_12_offers/Top_12_Offer_Page_Card.js';
import Formatting from 'utils/Formatting.js';

const configuration = require('configuration.json');
const routerBackValue = configuration.router_back_value;
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;


function Top12Offers() {
  const [items, setItems] = useState(null);



  useEffect(() => {
    let platform = Platform.getPlatformName();
    let preferredLanguage = Content.getPreferredLanguage();
    let currentCountry = JWTUtils.getJWTCountry();

    const req = ApiFacade.top12Offers(preferredLanguage, currentCountry, platform);
    req.then( (res) => {
      let result = res.response;
      setItems(result.offers);
    });
    const cards = document.querySelectorAll('.top-12-offer-page-card');

    // Find the maximum height among all cards
    let maxHeight = 0;
    cards.forEach(card => {
      const cardHeight = card.getBoundingClientRect().height;
      maxHeight = Math.max(maxHeight, cardHeight);
    });
  
    // Apply the maximum height as minHeight to all cards
    cards.forEach(card => {
      card.style.minHeight = `${maxHeight}px`;
    });
  }, []);

  useEffect(() => {
    Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.top_12_offers });
  }, []);

  return (
    <>
    <div id="main">
        <div id="content">
      <ContentPageHeader />
      <section className="content-page-section">
      <div className="top-12-offers-page-container">
              <div className="top-offers-homepage-header">
                <div className='top-offers-homepage-header-left max-w-half'>
            <h2 className="top-offers-homepage-name">
              {Formatting.format(Content.getValue("earn_from_top_12"), ["(offers)"], [items?.length || ''])}
            </h2>
            <p className='top-offer-page-desc'>{Content.getValue('how_to_earn')}</p>
          </div>
                <RedirectWithLang
                  to={routerBackValue}
                  className="top-offers-homepage-btn hidden-button "
                  isButton={true}
                >
                  <>
                    <KeyboardArrowLeftIcon fontSize='large' />
                    {Content.getValue('back')}</>
                </RedirectWithLang>
        </div>
        <ul className="top-offers-page-list">
          {items && items.map((i, idx) => <Top12OfferPageCard  offer={i} index={idx + 1} key={`top12offerPagecardkey-${idx}`}/>)}
        </ul>
      </div>
      </section>
      </div>
      <Footer />

      </div>
    </>
  );
}

export default Top12Offers;