import React from 'react';
import Joining from 'utils/Joining.js';
import Content from 'utils/Content.js';

import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import { useNavigate } from 'react-router-dom';
import ApiFacade from 'ApiFacade.js';

const ApproveButton = ({ isOneTwoTreatArea }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (ApiFacade.isLoggedIn()) {
            redirectWithLangFunc(configuration.paths.approve_your_identity, navigate);
        } else {
            Joining.showPopup(navigate);
        }
    };

    return (
        <span>
            <button

                className={`approve-button ${isOneTwoTreatArea ? "one-two-treat-area-btn" : ""}`}
                onClick={handleButtonClick}
            >
                {Content.getValue("approve")}
            </button>
        </span >
    );
};

export default ApproveButton;
