import Content from 'utils/Content.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import UserData from 'User_Data.js';

const configuration = require('configuration.json');

const welcomeUrl = configuration.paths.welcome;
const userDataKeys = configuration.user_data_keys;

class Redirects {

    static recommendedOrEmailOfferSet() {
       return (UserData.getItem(userDataKeys.recommended_offer) !== null && UserData.getItem(userDataKeys.offer_recommender) !== null ||
               UserData.getItem(userDataKeys.email_offer));
    }

    static isCurrentWebpage(webpageUrl) {
        return window.location.pathname.endsWith(webpageUrl);
    }

    static makeRedirectAfterLogin(existingAccount, navigate) {

        if (Redirects.recommendedOrEmailOfferSet()) {
            redirectWithLangFunc(configuration.paths.playground, navigate);
        }
        else if (existingAccount) {
            redirectWithLangFunc(configuration.paths.home, navigate);
        }
        else {
            redirectWithLangFunc(welcomeUrl);
        }
    }

    static thisPageIsGoingToRedirectToDifferentLanguage() {
        const url = window.location.pathname;
        let match = url.match(/^\/([a-z]{2})\//);
        if (!match) {
            match = url.match(/^\/([a-z]{2})$/);
        }
        const langData = configuration.languages;
        const langCode = match ? langData.find(i => i.url_code === match[1])?.code : '';
        const storedLangCode = Content.getPreferredLanguage();
        const hasCodeMatch = langData.some(i => i.code === storedLangCode);
        let willRedirect = (!langCode && storedLangCode !== null && storedLangCode !== langData[0].code && hasCodeMatch);
        return willRedirect;
    }


}

export default Redirects;