import React, { Component } from 'react';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';

const configuration = require('configuration.json');
const privacyPolicyPath = configuration.paths.privacy_policy;
const cookiePolicyPath = configuration.paths.cookie_policy;
class TermsText extends Component {

    render() {
        return (
            <div>
                <p className="legal-last-updated"><span className="bold-text">Last Update:</span> October 2024</p>
                <p className="long-content">These Terms of Use (<span className="bold-text">"Terms"</span>) constitute a binding legal agreement between Pythia Plus Ltd., (<span className="bold-text">"Company"</span>, <span className="bold-text">"us"</span>, or <span className="bold-text">"we"</span>) and yourself (<span className="bold-text">"End User"</span>, <span className="bold-text">"you"</span> or <span className="bold-text">"your"</span>), and govern your access and use of all features and other services provided by the Company, including without limitation our website <RedirectWithLang props={{ className: "legal-link" }} to={`/`}>www.TreatCoins.com</RedirectWithLang> (<span className="bold-text">"Website"</span>; collectively the <span className="bold-text">"Services"</span>).</p>
                <p className="long-content">By registering an account and accessing the Services, you affirmatively signify that you have read, understood, and agreed to be bound by these Terms as well as our <i><RedirectWithLang props={{ className: "legal-link" }} to={privacyPolicyPath}>Privacy Policy</RedirectWithLang></i> and <i><RedirectWithLang props={{ className: "legal-link" }} to={cookiePolicyPath}>Cookies Policy</RedirectWithLang></i>, which we may update from time to time, each incorporated herein by reference (<span className="bold-text">"Policies"</span>). If you do not agree to these Policies, you must not register an account or otherwise use or access the Services.</p>
                <p className="long-content">We may revise the Terms at any time by updating the Terms on our Services. You should periodically visit these Terms to review the current terms that apply to your use of the Services. Any use of the Services by you after our publication of any such changes shall constitute your acceptance of these Terms as modified. We may, at our sole discretion and at any time, discontinue providing the Services, or any part thereof without notice.</p>
                <p className="long-content">THE SERVICES ARE STRICTLY FOR ENTERTAINMENT PURPOSES ONLY. THE SERVICES ARE FREE OF CHARGE, NO ACTUAL MONEY OR PURCHASE IS REQUIRED IN ORDER TO PLAY OR TO ENGAGE WITH THE SERVICES.</p>
                <p className="long-content">THE OFFERS ON THIS WEBSITE ARE PROVIDED BY THIRD PARTIES WHICH ARE NOT CONTROLLED BY US. OUR SERVICES, THE INFORMATION PROVIDED ON THIS WEBSITE, OR ANY RELATED ACTION, DO NOT CONSTITUTE AN INVESTMENT ADVICE OR INVESTMENT SERVICES OR ACTIVITIES, OR ANCILLARY SERVICES, OR ANY AN ADVICE OF ANY KIND OR ANY OTHER FINANCIAL SERVICE, OR GAMBLING SERVICES, OR AN OFFER OR SOLICITATION OF ANY KIND TO BUY OR SELL ANY THEREOF, OR ANY OTHER REGULATED ACTIVITY, UNDER ANY APPLICABLE LAW OR REGULATION, AND SHOULD NOT BE SEEN AS SUCH.</p>
                <p className="long-content">By registering an account or otherwise accessing the Services, to the maximum extent permitted under Applicable Law (as defined further below), you agree that any claim, dispute or controversy of whatever nature arising out of or relating to these Terms, or your use of the Services shall be resolved accordance with the process described in Section 16. "Miscellaneous" below.</p>

                <div className="content-subtitle">1. DEFINITIONS</div>
                <p className="long-content">For the purpose of this Agreement, the following capitalized terms shall have the meaning ascribed to them hereunder:</p>
                <ol className="legal-list">
                    <li><p className="long-content"><span className="bold-text">"Account"</span> means an account you created via our Website, including through any third party (such as Facebook, Telegram, Google), that allows you to access the Services.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Account Information"</span> means any type of information that we may require from End User at the registration process, including but not limited to email address, phone number and/or WhatsApp account details, username, password and your social accounts ID details.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Affiliate"</span> means Company's subsidiaries, joint ventures and other corporate entities and their agents, consultant, employees, officers and directors.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Applicable Law"</span> means any law, statute, rule, regulation, order, circular, decree, directive, judgment, decision or other similar mandate of any applicable central, national, state or local governmental authority having competent jurisdiction over, or application, related to the End-User, or subject matter in question.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Intellectual Property Rights"</span> means any patents, trademarks, copyrights, design rights (whether registrable or not), logos, copyright, trade, business and domain names, moral rights, know-how, trade secrets, rights in databases, rights in computer software and any other similar rights or obligations whether registrable or not (and including all applications and renewals or extensions of such rights) in any country.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Quarterly Treat Coins Program"</span> means any ongoing periodical programs, <span className="underlined-text">as presented onsite from time to time</span>, awarding Treat Coins to the top one hundred (100) End Users who earned the most Treat Coins during a specific month (such as by participating in Offers and/or by referring Referred Users). The Company may change the number of winners for each program, as well as the program’s winning threshold and/or the period of the program, at any time and at its sole discretion. The program does not require payment or purchase of any kind.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Offers"</span> means special online programs, offers, activities (e.g., commercials to watch, surveys to fill out, apps to download, etc.) as presented onsite and may change from time to time, by the Partners on the Services in which End User can participate to earn Treat Coin. The rules for participating in each Offers are available on the Partner's websites.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Partner(s)"</span> means Affiliates as well as any third-party service provider that provide Offers to the End User on or through the Services.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Personal Wallet"</span> as defined in Section 7.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Prizes" or "Payouts"</span> as defined in Section 6.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Provider"</span> as defined in Section 6.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Referred User(s)"</span> means a natural person and/or legal entity who is referred directly by an End User and who registers to the Services via our Website, Facebook, Telegram, Google or email.</p></li>
                    <li>
                        <p className="long-content"><span className="bold-text">"Restricted Territories"</span> means
                            <ol className="legal-list list-style-alpha">
                                <li><p className="long-content">territories listed below; and/or</p></li>
                                <li><p className="long-content">a country or territory that is the subject of country wide or territory wide sanctions, embargoes, or other restrictive measures administered or enforced by any country or government or inter-governmental organization; and/or</p></li>
                                <li><p className="long-content">any other jurisdiction which prohibits the use of the Services or receipt of Services or any such similar activity.</p></li>
                            </ol>
                            <p className="long-content">The Restricted Territories shall include Crimea, Donetsk, Luhansk, Kherson and Zaporizhia regions of Ukraine, Russia, Lebanon, Cuba, Sudan, Myanmar, Iran, North Korea, Syria, Mainland China, Algeria, Egypt, Bangladesh, Afghanistan, Bolivia, Iraq, Kuwait, North Macedonia, Nepal, Morocco, Qatar, and any other jurisdiction which is subject to sanctions, embargoes or trade restrictions by any applicable body or authority.</p>
                        </p>
                    </li>
                    <li><p className="long-content"><span className="bold-text">"Restricted Persons"</span> means any restricted persons who are not permitted to use or access the Services and shall refer to any person, firm, company, partnership, trust, corporation, entity, government, state or agency of a state or any other incorporated or unincorporated body or association, association or partnership (whether or not having separate legal personality) that is (a) established or lawfully existing under the laws of a Restricted Territories or (b) is listed on a sanctions list by the governments of the United States or Israel.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Team"</span> means a group created and led by an End User (<span className="bold-text">"Prime End User"</span>) and which may contain up to three (3) Referred Users, whereby the 1st Referred User was referred by the Prime End User, the 2nd Referred User was referred by the 1st Referred User, and the 3rd Referred User was referred by the 2nd Referred User.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Team Group"</span> means a group comprised of a Team (<span className="bold-text">"Prime Team"</span>) whereby each of the Referred Users in the Prime Team has composed a Team. A Team Group is limited to up to 9 Referred Users (i.e. three rounds of generated Referred Users).</p></li>
                    <li><p className="long-content"><span className="bold-text">"Treat Coins"</span> means virtual credits, including without limitation, virtual points which can be earned for example by participating in and completing Offers or winning Quarterly Treat Coins Program or as detailed in these Terms <span className="bold-text underlined-text">and</span> which are exclusively for use in the Services.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Use Period"</span> as defined in Section 5.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Xoxoday/Plum"</span> means the digital 3rd party payment provider in which the End User can redeem their funds in United States Dollar $USD.</p></li>
                    <li><p className="long-content"><span className="bold-text">"Website(s)"</span> means the site, <RedirectWithLang props={{ className: "legal-link" }} to={`/`}>www.TreatCoin.com</RedirectWithLang> and any other sites as defined by the Company, at its sole discretion, from time to time.</p></li>
                </ol>

                <div className="content-subtitle">2. Use of our Services</div>
                <p className="long-content">To access or participate in the Offers that are made available on our Website by our Partners, you must create an Account with us. You may create an Account to register to our Services via our Website, your Facebook account, Telegram, Google or your email address.</p>
                <p className="long-content">Please note that the Services may not be fully compatible with all platforms, devices, and operating systems, therefore we encourage you to verify all the compatibility requirements yourself and update where it is needed third party software from time to time in order to receive the Services, updates, and to participate in the Offers available on our Website.</p>
                <p className="long-content">When you create an Account, you will be asked to provide Account Information, which may involve:</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Registering a unique username and password;</p></li>
                    <li><p className="long-content">Providing contact information, such as your email address, phone number and/or WhatsApp account details;</p></li>
                    <li><p className="long-content">Providing any other information that we request during the account registration process.</p></li>
                </ol>
                <p className="long-content">By accessing or using our Services, you agree to abide by all the following:</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">You are solely responsible for keeping your Account Information secure and for anything that occurs on your Account, whether or not the actions were taken by you or an unauthorized third party that has gained access to your Account;</p></li>
                    <li><p className="long-content">You shall only register for a single Account and you shall not use or access multiple Accounts at the same time;</p></li>
                    <li><p className="long-content">You shall not create an Account with a false identity or provide incorrect information, or create an Account on behalf of someone other than yourself;</p></li>
                    <li><p className="long-content">You shall not share or give access to your Account Information to anyone, or do anything else that might jeopardize your Account's security;</p></li>
                    <li><p className="long-content">You shall notify us immediately, as detailed in Section 19 "Contact Us" of these Terms, if you have lost control of your password or you suspect there is unauthorized activity in your Account, including theft or unauthorized disclosure of your Account Information;</p></li>
                    <li><p className="long-content">You shall ensure that you log out or exit from your Account (if applicable) at the end of each login to our Services;</p></li>
                    <li><p className="long-content">You have reached the age of majority both in the territory in which you reside;</p></li>
                    <li><p className="long-content">You are legally capable of entering into a binding contract, and you are not aware of any legal, regulatory, commercial, contractual or other restriction against your participation in our Service, the Offers in accordance with these Terms;</p></li>
                    <li><p className="long-content">Your use of the Services does not violate any Applicable Law;</p></li>
                    <li><p className="long-content">You are not a Restricted Person or a citizen, national, resident (tax or otherwise) of Restricted Territories;</p></li>
                    <li><p className="long-content">You will not hold us responsible or liable, for any loss, damage, unauthorized access, or any breach of your Account and the disclosure of your Account Information.</p></li>
                </ol>
                <p className="long-content"><span className="bold-text">As the holder of your Account, you are solely responsible for complying with these Terms, and only you are entitled to all benefits accruing thereto.</span></p>

                <div className="content-subtitle">3. Account Verification</div>
                <p className="long-content">We reserve the right to verify your registration details, such as name, address, age, at any time, by requesting certain documents. In the event that you do not provide the required documentation, we may at our sole discretion refuse to provide you with the Services and withhold any Prizes, funds, Treat Coins where applicable.</p>
                <p className="long-content">You agree to update any information or data you provided or to provide additional documentation as part of ongoing efforts to prevent illegal and fraudulent activities, or to comply with any other policies or protocols we elect to put in place. You agree that all information and data that you provide to us, either at the time you register for an account or at any subsequent time, will be truthful, accurate and verifiable in all respects, and that by providing such information and data, you consent to us submitting it to third party verification providers.</p>
                <p className="long-content">To access or use the Services, including to access certain Offers you may be required at Company's sole discretion, to -</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Undergo and successfully pass know-your-customer (KYC) and customer due diligence procedures and/or screening. In any event, you acknowledge that you may be subject to additional KYC and screening procedure when you withdraw Treat Coins worth more than five thousand dollars (USD $5,000) from your account or in any other circumstances deemed necessary by the Company;</p></li>
                    <li><p className="long-content">Execute and deliver to the Company, or to any other entity on its behalf, documents related to the Services under any Applicable Law, including any anti-money laundering and counterterrorism financing laws in any jurisdiction.</p></li>
                </ol>
                <div className="content-subtitle">4. Device and Personal Information</div>
                <p className="long-content">Using the Services requires an internet connection to our servers and you understand and acknowledge that you are solely responsible for paying any fees, including Internet connection or mobile fees necessary to access or use our Services.</p>
                <p className="long-content">We may need to collect certain information from you and your internet-enabled device in order to make the software and Services available to you, such as your Account Information, hardware system, internet connection data, and any other data related to the operation and provision of the Services. Please note that we will use and retain such information in accordance with our <i><RedirectWithLang props={{ className: "legal-link" }} to={privacyPolicyPath}>Privacy Policy</RedirectWithLang>.</i></p>

                <div className="content-subtitle">5. Offers and Treat Coins</div>
                <p className="long-content">When accessing or using our Services, you may choose to select and participate in different types of Offers, as presented onsite and may change from time to time.
                    <br />You understand and acknowledge that our Services will provide you with Offers, and when you choose to participate in an Offer, you will be redirected to the Partner's website.
                </p>
                <p className="long-content"><span className="bold-text">It is important that you understand that you will be bound by the Partner's terms and conditions as well as the rules that apply to each type of Offer.</span></p>
                <p className="long-content"><span className="bold-text">The amounts and numbers set in this section may be amended from time to time, as presented onsite, as per the Company's sole discretion.</span></p>
                <p className="long-content">You understand and acknowledge that we act solely as an intermediary, providing a platform to display Offers from Providers. We are not responsible for the content, quality, or fulfillment of these Offers.</p>
                <p className="long-content">For each Offer completed, the Company may allocate End Users with Treat Coins for engaging with such Partners' Offers. Such allocation is at the Company's sole discretion, subject to these Terms. We do not guarantee the availability, accuracy, or timeliness of Treat Coins earned through these actions.</p>
                <p className="long-content">Please review and understand the rules of each Offer as well as any related terms and conditions thereof. Upon your use of the Services, you acknowledge and understand that you may -</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Participate in the Offer individually, or collectively as part of a Team or a Team Group;</p></li>
                    <li><p className="long-content">Earn Treat Coins in one of the following ways -
                        <ol className="legal-list list-style-alpha">
                            <li><p className="long-content">In each Team, for each Referred User who completed the registration process successfully in accordance with the Terms, the Prime End User may earn ten (10) Treat Coins for the 1st Referred User, four (4) Treat Coins for the 2nd Referred User, and two (2) Treat Coins for the 3rd Referred User;</p></li>
                            <li><p className="long-content">For each Offer you complete successfully according to the Terms and the terms of the respective Offer, the Partner's rules and policies;</p></li>
                            <li><p className="long-content">In each Team, for each Offer completed successfully by the Referred User according to the Terms, the Partner's rules and policies, the Prime End User shall earn the following percentage of Treat Coins, limited to three (3) Referred Users only -
                                <ul className="legal-list">
                                    <li><p className="long-content"><span className="bold-text underlined-text">1st Referred User:</span> The Prime End User will receive 13% from the total of Treat Coins received by the 1st Referred User;</p></li>
                                    <li><p className="long-content"><span className="bold-text underlined-text">2nd Referred User:</span> The Prime End User will receive 5% from the total of Treat Coins received by the 2nd Referred User;</p></li>
                                    <li><p className="long-content"><span className="bold-text underlined-text">3rd Referred User:</span> The Prime End User will receive 2% from the total of Treat Coins received by the 3rd Referred User;</p></li>
                                </ul>
                            </p></li>
                            <li><p className="long-content">By winning Quarterly Treat Coins Programs. You understand and accept that you -
                                <ul className="legal-list">
                                    <li><p className="long-content">May redeem only Treat Coins earned by winning the Quarterly Treat Coin Program for cash, only after reaching a threshold of $32USD worth of Treat Coins.</p></li>
                                    <li><p className="long-content">Cannot use Treat Coins received as part of a given Quarterly Treat Coins Program for any other Quarterly Treat Coins Program;</p></li>
                                    <li><p className="long-content">Cannot use the Treat Coins received as part of the Quarterly Treat Coins Program for any other use, except as stated otherwise by the Company.</p></li>
                                </ul>
                            </p></li>
                        </ol>
                    </p></li>
                    <li><p className="long-content">Win Prizes as described further below in Section 6.</p></li>
                </ol>
                <p className="long-content">You agree that our Services include an opportunity to earn Treat Coins only, and that we do not require you to buy Treat Coins or to make any purchase of any kind when accessing or using the Services.</p>
                <p className="long-content"><span className="bold-text">However, please note that we/our 3rd party payment provider may be required to collect a fee when you decide to receive USD to your Personal Wallet for your Treat Coins converted, subject to these Terms and additional specific terms which may appear on the Services.</span></p>
                <p className="long-content">You <span className="bold-text underlined-text">may not</span> distribute, transfer, share, sublicense, trade, assign, exchange, purchase or sell Treat Coins to any End User or anyone outside the Services or perform any action with your Treat Coins that is considered illegal by us or contravenes these Terms or any Applicable Law.</p>
                <p className="long-content">We do not recognize any purchase or transfer made outside of our Services, and you acknowledge and accept that we will not be liable for any claims or damages incurred by you or any End User as a result of Treat Coins purchased or obtained from illegitimate sources, rather than through the means provided by the Services. Any attempt to distribute, transfer, share, sublicense, trade, assign, exchange, purchase or sell Treat Coins to any End User or anyone outside the Services or perform any other action related to your Treat Coins in violation of these Terms is prohibited and void and may subject the End User to the termination of his or her Account, a lifetime ban from using the Services and/or any legal action.</p>
                <p className="long-content">Please note that you are able to access all the information about your rank in the Quarterly Treat Coins Program, your Treat Coins balance, the Treat Coins balance of your Referred Users, as well as other statistics in the settings in our Website.</p>
                <p className="long-content">Notwithstanding your possession of Treat Coins, <span className="bold-text underlined-text">you acknowledge and agree that you -</span></p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Do not in fact own Treat Coins, and the amounts of any Treat Coins in your Account balance do not constitute a real world balance, or refer to any stored value, credit balance of fiat currency or its equivalent;</p></li>
                    <li><p className="long-content">Have no right, ownership, title or interest in Treat Coins that appear on your Account balance whether earned or won by you and/or awarded by us.</p></li>
                </ol>
                <p className="long-content">We strongly recommend you to save or screenshot each Offer you completed successfully, as you may be required to provide us with such proof in the event of any issue that may occur or arise related to the Offer, the distribution of Treat Coins and/or payments.</p>
                <p className="long-content">In the event your Account balance with respect to Treat Coins is incorrect due to system maintenance or other technical failures, we will use commercially reasonable efforts to correct your Account balance.</p>
                <p className="long-content">Your use of any Treat Coins must be solely for legitimate purposes within the Services, and must comply with these Terms at any and all times.</p>
                <p className="long-content">You acknowledge and agree that we may change, modify or update the Terms in connection with earning or receiving Treat Coins from time to time, and that such revised terms will apply to your use of the Services as well as all subsequent Offers in which you participate. You further agree that you will review these Terms and any terms regarding how to earn or receive Treat Coins prior to entering or participating in any Offer.</p>
                <p className="long-content">It is important to understand that your Treat Coins have an expiration date ("<span className="bold-text">Use Period</span>") as presented onsite, and which may differ according to the following -</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Treat Coins that you earned through a Referred User are only valid and available for one (1) given Quarterly Treat Coins Program;</p></li>
                    <li><p className="long-content">In any event, please note that we reserve the right to permanently confiscate, forfeit, revoke, remove, or deduct from your Account any Treat Coins not used within the Use Period or in the event that we determine, in our sole discretion, that you have not logged in to your Account during such Use Period. In this case, you acknowledge and accept that we are under no obligation to provide you with a refund for any other reason, and that you will not receive any money or any other compensation for unused Treat Coins whether your Account is closed, terminated, voluntary or involuntary, or the Use Period of your Treat Coins has expired.</p></li>
                </ol>
                <p className="long-content">We take fraud very seriously. Please note that in addition to taking any desirable or necessary legal action, we reserve the right to terminate your use of the Services and/or to permanently confiscate, forfeit, revoke, or remove any Treat Coins from your Account that we determine, in our sole discretion, were acquired using fraudulent or otherwise improper means.</p>
                <p className="long-content">We will notify you prior to such confiscation in order to provide an opportunity for you to make an appeal via the Services; provided, however, that our determination, in our sole discretion, shall be final and binding on you.</p>

                <div className="content-subtitle">6. Prizes and Payouts</div>
                <p className="long-content">By using our Services, you understand and acknowledge that you may select different Prizes by earning Treat Coins as detailed in Section 5 of these Terms and as presented onsite.</p>
                <p className="long-content">The Prizes can be changed from time to time, per the Company's sole discretion.</p>
                <p className="long-content">Each Treat Coin has a virtual value of $0.01 USD, therefore if you have sufficient Treat Coins in your Account balance, you may be able to use these Treat Coins to obtain Prizes.
                    <br />You understand and accept that you may only use your Treat Coins to select one of the following Prizes -
                </p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Receive gift cards (e.g. accessories, electronics, prepaid card, home and living, wellness and sports) or experience vouchers (e.g. tour, arts and culture, adventure, culinary) or certain virtual cards (as offered and available within your region) to be used only on our third-party gift provider or any other third-party gift provider of our choice;</p></li>
                    <li><p className="long-content">Redeem your Treat Coins for cash to your bank account. This will be made by our third-party payment provider Paxum or any other third-party payment provider of our choice;</p></li>
                    <li><p className="long-content">Redeem your Treat Coins by connecting your Personal Wallet to our third-party provider or any other third-party provider of our choice, subject to your compliance with these Terms, as well as Applicable Law (collectively referred to as "<span className="bold-text">Prizes</span>");</p></li>
                    <li><p className="long-content">Your right to redeem or use Treat Coins for Prizes is subject to earning an amount of three thousand two hundred Treat Coins that has an equivalent value of <span className="underlined-text">at least</span> twenty-five USD ($25USD), and contingent upon your compliance with all of the provisions of the Terms and Applicable Law. The Company may amend this threshold from time to time, as presented onsite.</p></li>
                </ol>
                <p className="long-content">Failure to comply with any of the provisions contained in the Terms will result in the termination of your Account and forfeiture of your Treat Coins.</p>
                <p className="long-content">We reserve the right to perform background checks on each End User when the End User has accumulated a certain amount of Treat Coins in their Account balance and wishes to select a Prize, or at any time where we believe that the End User's Account involves suspicious or illegal activities.</p>
                <p className="long-content">We may request the End User to provide us with further documentation, for any reason, including (but not limited to) any investigation into the End User's identity and registration details. We are under no obligation to advise the End User that such investigation is taking place. If the Company's requests for documents are not completed or if the Company suspects that the documents have been tampered with, or are in any way misleading or misrepresenting, the Company shall be under no obligation to accept such documents as valid and may withhold any past or future Treat Coins which have accrued or which shall accrue to the End User's benefit, as well as forfeit any Treat Coins on your Account balance and/or terminate these Terms with immediate effect.</p>
                <p className="long-content">Please note that as described above, some of the Prizes made available on our Services may grant End Users the opportunity to access and use their Treat Coins as credits on our Affiliate websites.</p>
                <p className="long-content">You understand and acknowledge that in such a case, you are solely responsible for the Prize you choose and we will not be liable for any action you undertake that might be considered as illegal in your jurisdiction and/or under Applicable Law.</p>
                <p className="long-content">The information on this Website, our Services, or any associated rewards are not intended for distribution to or use by any person in any country where such distribution or use would be contrary to local laws or regulations, any Applicable Law, or to any person under the age of eighteen (18) or under the age of majority in their country of residence or nationality.</p>
                <p className="long-content">It is the responsibility of the visitors and End Users of this Website to ascertain the terms of and comply with all Applicable Laws and these Terms.</p>
                <p className="long-content">You also agree and confirm that you will not redistribute your Treat Coins and/or your Prizes to anyone and you will not permit any minor or any other person to benefit from the Treat Coins and/or Prizes that you have earned or won through the Services.</p>
                <p className="long-content">You hereby release the Company from all liability relating in any way to any of your actions or activities that may involve the Company or the use of the Services.</p>
                <p className="long-content">As described above, you have the possibility to redeem your Treat Coins for cash in your bank account. Please note that the payments are made through a third-party payment provider. The payment (less any deductions) shall be transferred by us to you through a third-party payment provider of our choice usually within 30 business days, after the completion of such payment. Once the Company has forwarded payment to the third-party service provider, the Company will be deemed to have satisfied all its duties and obligations to make such payment to you.</p>
                <p className="long-content">You accept that when you use your Treat Coins for the purpose of selecting Prizes, upon the selection of your Prize, your action is final and is not refundable, transferable, or exchangeable, except at our sole discretion. It is important to understand and accept that all Prizes may -</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Vary depending on your jurisdiction, Applicable Law, or our own discretion;</p></li>
                    <li><p className="long-content">Be available for a certain period of time;</p></li>
                    <li><p className="long-content">Be changed, modified, removed, suppressed, adapted, or replaced by us with other Prizes of our choice, at any time and at our own discretion;</p></li>
                    <li><p className="long-content">Be provided by other third parties.</p></li>
                </ol>
                <p className="long-content"><span className="bold-text"><span className="underlined-text">Please note:</span> We may use other third parties to perform certain business-related functions such as processing payments or providing gift cards on our behalf ("Providers").</span></p>
                <p className="long-content"><span className="bold-text">You are solely responsible for reviewing the terms and conditions governing your use of the Provider's services. You understand and acknowledge that the Provider's terms of services may differ from these Terms.</span></p>
                <p className="long-content">The Prizes are solely for End Users that have registered successfully on our Services, have a valid active Account, and comply with these Terms. You understand and accept that the Prizes to which you may be entitled are strictly for your own benefit and that no one else is entitled to them or to benefit from them. Failure to comply with these Terms may result in the closing of your Account and the loss of all Treat Coins accumulated through your use of the Services.</p>
                <p className="long-content">Please note that we determine the value (i.e., the exchange rate) of Treat Coins in our sole discretion, and we further reserve the right to modify the exchange rate between actual currency and Treat Coins from time to time, with or without reason, in our sole discretion. By your use of the Services, you confirm your understanding of and accept the modified exchange rate of any Treat Coins at the time you wish to use or redeem Treat Coins for Prizes.</p>
                <p className="long-content">When you redeem Treat Coins for cash, you agree to pay all applicable charges for such actions, including applicable taxes incurred by you or applicable gas fees. We do not provide refunds for any fees which might be related to any transaction.</p>
                <p className="long-content">You agree to notify us about any billing problems or discrepancies within 10 days after they first appear on your Account statement. If you do not bring them to our attention within 10 days, you agree that you waive your right to dispute such problems or discrepancies.</p>
                <p className="long-content">You are solely responsible for and agree to reimburse us for all reversals, charge-backs, claims, fees, fines, penalties, and other liabilities incurred by us (including costs and related expenses) that were caused by you.</p>
                <p className="long-content">You understand that we may be required to ask you to use or redeem your Treat Coins through an alternative payment method of our choice at any time and at our sole discretion.</p>
                <p className="long-content">All Treat Coins may be confiscated, revoked, deleted, or deemed void if your Account is terminated or suspended for any reason, in our sole and absolute discretion, or if the Services are no longer available. If your Account is terminated, suspended, and/or if any Treat Coins in your Account are selectively confiscated, revoked, deleted, or deemed void, no refund will be granted, and no Treat Coins will be credited to you or converted to Prizes, cash, or other forms of reimbursement.</p>

                <div className="content-subtitle">7. Receipt of USD to your Personal Wallet</div>
                <p className="long-content">We may offer you, at the Company's sole discretion and via our 3rd party provider, the possibility to receive USD to your Personal Wallet for your Treat Coins subject to Applicable Laws, these Terms, and to the ratio and additional terms specified on the Website. However, you understand and accept that the Services are not, nor should they be considered as:
                    (1) the provision of investment advice;
                    (2) portfolio management;
                    (3) financial advice;
                    (4) exchange, custody, or transfer of electronic money;
                    (5) money transmission;
                    (6) payment service;
                    (7) investment services or activities;
                    (8) ancillary services under any Applicable Law;
                    (9) any other financial, regulated service, or service the provision of which requires any license, registration, permit or any regulatory or other authorization; or
                    (10) endorsement of any third party providing one of the aforementioned activities, or equivalent of any of the above under any Applicable Law.
                </p>
                <p className="long-content">The Services are not regulated by any financial services or investor protection laws, and you will not benefit from protections and remedies which are available to clients of regulated services against any damages which may arise from your use of the Services.</p>
                <p className="long-content">In addition, with regard to the redemption of Treat Coins for USD to your Personal Wallet from our Services, you understand and acknowledge that -</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Redemption of Treat Coins for USD to your Personal Wallet is not permitted where it is, or where it may be, prohibited or in any way restricted by any Applicable Law, and it is your responsibility to ensure compliance of such transactions with all Applicable Laws;</p></li>
                    <li><p className="long-content">As a prerequisite for receiving such USD, you will receive an email directly from the 3rd party provider: Xoxoday/Plum with the relevant payment information (<span className="bold-text">"Personal Wallet"</span>). The Company, or any Affiliates, accept no responsibility or liability whatsoever in the event you provide the third party chosen by the Company, at its sole discretion, with an incorrect or incompatible Personal Wallet.</p></li>
                    <li><p className="long-content">We do not have any control over or access to your Personal Wallet, or any of your assets thereof.</p></li>
                </ol>

                <div className="content-subtitle">8. Discord</div>
                <p className="long-content">End Users might become a member of the Treat Coin Discord community. By becoming a member of the Treat Coin Discord community, End Users shall comply with Discord community standards found at <a href="https://discord.com/guidelines" className="legal-link">https://discord.com/guidelines</a>.</p>
                <p className="long-content">The Company might conduct flash sales and various kinds of competitions in Discord. The winners of such Discord competitions will be announced by the Company, and they will be chosen according to the rules placed by the Company for each competition.</p>
                <p className="long-content">End Users can level up their Treat Coin account by engaging more actively in the Discord community. The Company may level up or level down a user at its own discretion based on the engagement and behavior of each End User.</p>

                <div className="content-subtitle">9. End Users' Representations and Prohibited Uses</div>
                <p className="long-content">You may use the Services only for lawful purposes. You are solely responsible for all of your activity in connection with the Services.</p>
                <p className="long-content"><span className="bold-text underlined-text">You warrant and represent that you are prohibited to (and shall not permit any third party to) take any action that:</span></p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Is illegal, violent, threatening, abusive, invasive of any person's privacy, harassing, defamatory, libelous, false, deceptive, fraudulent, misleading, untruthful, tortuous, offensive, or otherwise inappropriate, as we may determine in our sole discretion;</p></li>
                    <li><p className="long-content">Infringes, violates, or misappropriates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, or any other intellectual property or proprietary right as may now exist or hereafter come into existence;</p></li>
                    <li><p className="long-content">Violates any right of publicity or other right of any person or entity, or any law or contractual obligation, including without limitation rights pursuant to data protection, anti-spam, and privacy laws and regulations;</p></li>
                    <li><p className="long-content">Copies, modifies, adapts, translates into any language, distributes, or creates derivative works based on the Services;</p></li>
                    <li><p className="long-content">Sublicenses, subleases, leases, lends, assigns, sells, licenses, distributes, rents, exports, re-exports, or grants other rights in the Services, and any attempt by you to take such action shall be void;</p></li>
                    <li><p className="long-content">Decompiles, disassembles, reverse engineers, or attempts to reconstruct, identify, or discover any source code, underlying ideas, underlying user interface techniques, or algorithms of the Services by any means whatsoever;</p></li>
                    <li><p className="long-content">Removes, obscures, or alters any copyright and/or other proprietary notices contained on or in or otherwise connected to the Services;</p></li>
                    <li><p className="long-content">Involves commercial activity not expressly permitted by us;</p></li>
                    <li><p className="long-content">Contains any harmful or malicious software or computer codes, files, or programs that are designed or intended to damage, disrupt, interfere with, or limit the proper functionality of any software or hardware, or that enables obtaining unauthorized access to any system, data, password, or other information of us, our End Users, or any other individual or entity;</p></li>
                    <li><p className="long-content">Uses or launches any automated system, including without limitation, "robots," "spiders," "offline readers," etc., that accesses the Services in a manner that sends more request messages to our servers than a human can reasonably produce in the same period of time;</p></li>
                    <li><p className="long-content">Transmits invalid data, worms, viruses, Trojan horses, or any code of a destructive nature;</p></li>
                    <li><p className="long-content">Transmits spam, chain letters, materials related to any political campaigns, commercial solicitations, mass mailings, or any form of spam or other unsolicited texts or emails;</p></li>
                    <li><p className="long-content">Impersonates any individual or entity, including, without limitation, our employees or representatives;</p></li>
                    <li><p className="long-content">Uses a false phone number or email, identity, or attempts to use another user's Account;</p></li>
                    <li><p className="long-content">Uses the Services for money laundering, terrorist financing, or other illicit finance or activity;</p></li>
                    <li><p className="long-content">Targets children or individuals under the age of majority in any jurisdiction where the Services are made available;</p></li>
                    <li><p className="long-content">Engages in any activity that interrupts or attempts to interrupt the operation of the Services;</p></li>
                    <li><p className="long-content">Performs any activity intended to defraud the Company or any third party or circumvent any contractual or legal restriction, regardless of whether such attempted act has caused the Company any actual harm.</p></li>
                </ol>
                <p className="long-content">Anyone who engages in, participates in, or displays behavior that may be interpreted, in our sole discretion, as unfair methods in participating in the Services, including but not limited to: (i) the opening or use of multiple accounts; (ii) collusion with or harassment of other End Users; (iii) breach of these Terms; (iv) breach of security of your Account; or (v) any other act or omission (whether through the use of automated technology or otherwise), will be subject to immediate sanction (as determined by us in our sole discretion), which may include, without limitation, disabling the ability to use or access our Services in whole or in part, as well as other legal actions. We reserve the right to disclose or report any money laundering or other suspicious activity to law enforcement and regulatory authorities.</p>
                <p className="long-content">Any attempt to deliberately damage the Services or violation of criminal or civil laws will be addressed, and we reserve the right to seek damages and other remedies from you to the fullest extent permitted under Applicable Law.</p>
                <p className="long-content">We reserve the right to exercise whatever lawful means we deem necessary to prevent unauthorized use of the Services, including, but not limited to, technological barriers, IP mapping, and directly contacting your wireless (cellular) carrier regarding such unauthorized use.</p>

                <div className="content-subtitle">10. Third Party Links</div>
                <p className="long-content">Portions of the Services may involve linking to websites belonging to third parties (such as Partners' websites).</p>
                <p className="long-content">We have no control over third-party websites, and all use of third-party websites is at your own risk.</p>
                <p className="long-content">
                    We may have direct relationships with Partners, and we may be compensated by such Partners based on your interaction with their offers, websites, or services.
                    Such compensation is not, and should not be seen as, an endorsement or recommendation by us.
                    We are not liable for any losses or damages, direct or indirect, that may result from the use of or reliance on information contained on any Partner's or third party's websites or services.
                </p>
                <p className="long-content">We encourage you to carefully read the terms of use and privacy policy of such third-party websites and/or services, as their terms and privacy policy, not ours, will apply to any of your interactions with such third parties, as well as any specific requirements or limitations associated with their Offers or services.</p>
                <p className="long-content">You should always review their practices carefully before accepting the provision of any services by these third parties.</p>

                <div className="content-subtitle">11. Treat Coin Rewards Upon Purchase</div>
                <p className="long-content">Regardless of the above, the Company may reward you, from time to time, with additional Treat Coins by carrying out purchases on specific Partners' websites.</p>
                <p className="long-content"><span className="bold-text">You accept and acknowledge all the following:</span></p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">The quantity of Treat Coins you earned through each specific purchase may vary and is subject to the Company's sole discretion;</p></li>
                    <li><p className="long-content">The allocation of Treat Coins depends solely on End Users' compliance with specific purchase conditions defined by the Company, including minimum purchase amounts, product types, or any other conditions defined by the Company from time to time;</p></li>
                    <li><p className="long-content">We may ask you to provide evidence that you have fulfilled the conditions of purchase, including sending us confirmations or receipts. You understand that you may not be entitled to receive the Treat Coins if you do not comply with our request;</p></li>
                    <li><p className="long-content">We may forfeit Treat Coins received in connection with your purchase if we identify any fraudulent or deceptive activity or deem that the confirmation or receipt we received from you is not legitimate;</p></li>
                    <li><p className="long-content">You will not hold us responsible or liable for any product or service purchased on the Provider's website;</p></li>
                    <li><p className="long-content">Any issue or dispute related to any of your purchases on a Partner's website should be resolved directly with that same Partner.</p></li>
                </ol>
                <p className="long-content">In addition, we may display, at our own discretion, Offers from Providers operating in some <span className="bold-text">regulated areas</span> (e.g., forex, finance, gambling).</p>
                <p className="long-content"><span className="bold-text">You accept all the following:</span></p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Any Providers' Offer made available on our Services does not constitute financial, gaming, tax advice, legal advice, or any kind of advice to participate in or purchase Providers' services or products;</p></li>
                    <li><p className="long-content">If you intend to use or purchase Providers' services or products, this is at your own risk, and you should seek professional advice before making any decisions;</p></li>
                    <li><p className="long-content">You are solely responsible for complying with any Applicable Laws if you intend to engage with Providers;</p></li>
                    <li><p className="long-content">The availability of Offers may vary based on your location and eligibility;</p></li>
                    <li><p className="long-content">You are of the legal age in your jurisdiction to participate in or purchase the Partner's services or products;</p></li>
                    <li><p className="long-content">You are responsible for complying with tax laws in your jurisdiction.</p></li>
                </ol>
                <p className="long-content"><span className="bold-text underlined-text">With regard to gambling-related Offers</span> - In addition to the above, you accept and acknowledge that participating in gambling activities, <i>inter alia</i>, betting, staking, wagering, lottery, contests, or any similar activities may carry significant risks and can result, among other things, in substantial financial loss.</p>
                <p className="long-content"><span className="bold-text">You acknowledge and accept the inherent risks associated with any of your gambling activities, and you agree to do so at your own responsibility.</span></p>
                <p className="long-content"><span className="bold-text">You acknowledge that gambling activities are subject to various laws and regulations and may be prohibited or restricted depending on your jurisdiction.</span></p>
                <p className="long-content"><span className="bold-text">You are solely responsible for complying with all Applicable Laws and regulations associated with gambling in your jurisdiction.</span></p>
                <p className="long-content"><span className="bold-text">You certify that you have the legal age in your jurisdiction to participate in gambling activities. Please note that in certain circumstances, there may be no legal recourse for any losses incurred while engaging in gambling activities.</span></p>
                <p className="long-content"><span className="bold-text">We do not provide any financial, gaming, tax, legal, or any type of advice or recommendations regarding any of your gambling activities.</span> Past performance does not guarantee future results.</p>
                <p className="long-content"><span className="bold-text">Before participating in any gambling activity, you accept that it is your sole responsibility to read and understand the rules of the specific gambling activity, carefully evaluate your risk appetite and level of experience, and seek guidance where needed.</span></p>
                <p className="long-content">If you believe that you may have a gambling problem, you should consider seeking assistance from qualified organizations or professionals specializing in gambling addiction and treatment.</p>
                <p className="long-content"><span className="bold-text underlined-text">With regard to Forex CFD-related Offers</span> - In addition to the above, you accept and acknowledge that investment activities, including trading in financial instruments, are very risky and can result in the total loss of the invested amounts.</p>
                <p className="long-content">For this reason, these activities might not be suitable for everyone, so please make sure you understand the risks involved. Past performance does not guarantee future results.</p>
                <p className="long-content">You must not invest money that you can't afford to lose.</p>
                <p className="long-content"><span className="bold-text">Before deciding to undertake these kinds of investments, you should be aware of all the risks, understand how a particular investment instrument works, carefully consider your investment objectives, risk appetite, level of experience, and seek advice from an independent and properly authorized financial advisor.</span></p>
                <p className="long-content">Please note that contracts for differences (CFDs) are complex instruments and come with a high risk of losing money rapidly due to leverage.</p>
                <p className="long-content">Trading platform providers have a large percentage of retail investor accounts that lose money when trading CFDs with their company.</p>
                <p className="long-content">You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.</p>
                <p className="long-content">You acknowledge that engagement in trading and investment activities, including in relation to CFDs, is subject to various laws and regulations and may be prohibited or restricted depending on your jurisdiction.</p>
                <p className="long-content"><span className="bold-text">You are solely responsible for complying with all Applicable Laws associated with trading and investment activities, including in relation to CFDs, in your jurisdiction.</span></p>
                <p className="long-content">You certify that you have the legal age in your jurisdiction to participate in trading and investment activities, including in relation to CFDs.</p>
                <p className="long-content">Please note that in certain circumstances, there may be no legal recourse for any losses incurred while engaging in trading and investment activities, including in relation to CFDs.</p>

                <p className="long-content"><span className="bold-text">Additionally, we <span className="underlined-text">do not</span> accept responsibility for any products, content, or services, of any kind, including but not limited to loans, credit cards, investment products made available, or payments processed or submitted, through such third-party websites, nor for their privacy or any other practices.</span></p>
                <p className="long-content"><span className="bold-text">We <span className="underlined-text">do not</span> endorse any third-party websites, and we urge our End Users to exercise caution in using any third-party websites.</span></p>
                <p className="long-content">Any dispute or issues related to a Provider's Offers should be resolved directly with the Provider.</p>
                <p className="long-content"><span className="bold-text">Therefore, you agree not to hold us responsible for any liability arising from your access to or use of any third-party website, content, or service through the Website.</span></p>

                <div className="content-subtitle">12. License</div>
                <p className="long-content">Subject to your compliance with the Terms, we grant you a personal, limited, non-commercial, non-exclusive, non-sublicensable, non-assignable, non-transferable, freely revocable license to use the Services. The Services include materials such as Treat Coins, that we own or license and are protected by copyrights, trademarks, patents, and other worldwide Intellectual Property Rights and by Applicable Law.</p>
                <p className="long-content">We <span className="bold-text underlined-text">do not</span> grant you or any other party any right, title, or interest in the Services or any materials available on our Services.</p>
                <p className="long-content"><span className="bold-text underlined-text">You understand and acknowledge all of the following:</span></p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">The use of the Services is for personal, non-commercial entertainment only and not for any other purpose;</p></li>
                    <li><p className="long-content">Any material available on our Services, including those appearing on our Website, should not be reproduced, copied, edited, published, transmitted, or uploaded in any way without our written permission. Registering for our Services or participating in Offers does not grant you any express or implied rights under any of the Company's trademarks, copyrights, or other proprietary information;</p></li>
                    <li><p className="long-content">If you violate or do not agree to the Terms, your license to access and/or use the Services shall be immediately terminated, and you will no longer be able to access and/or use our Services, including your Treat Coins.</p></li>
                </ol>
                
                <div className="content-subtitle">13. Assumption of Risk</div>
                <p className="long-content">By using our Services, you accept and acknowledge that:</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">You are solely responsible for determining what, if any, taxes or fees apply to any transactions you made through the Services, including receipt of USD to your Personal Wallet. Company is not responsible for determining the taxes or fees that apply to such transactions;</p></li>
                    <li><p className="long-content">All transactions, including any transaction involving Xoxoday/Plum are irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable. Specifically, the Company will not be able to reverse or recover any transaction or transfer of Treat Coins to Xoxoday/Plum if you provide the third party chosen by the Company at its sole discretion with an incorrect or an incompatible Personal Wallet. Some transactions shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that you initiated the transaction;</p></li>
                    <li><p className="long-content">You accept and acknowledge that there are risks associated with the use of the Internet (e.g., malicious software, unauthorized access), and therefore, the Company will not be responsible for any communications failures, disruptions, errors, distortions or delays you may experience when using the Services or any other third-party service or product, including but not limited to the Personal Wallet, for transactions, however caused;</p></li>
                </ol>

                <div className="content-subtitle">14. No Warranty</div>
                <p className="long-content">You expressly agree that your use of the Services is at your own risk and you are aware that the Services, the Offers, the Treat Coins, and Prizes are provided on an "as is" and "as available" basis without any representation or warranty, whether express, implied (whether by law, statute or otherwise), including but not limited to any implied warranties of merchantability, fitness for a particular purpose, and non-infringement (whether under Applicable Law or otherwise).</p>
                <p className="long-content">The Company does not make any representations or warranties, express or implied, that your access to and/or use of the Services will be accurate, free of error, complete, uninterrupted, continuous, or that any defects will be corrected, or that the Website is free of viruses and bugs.</p>
                <p className="long-content"><span className="bold-text">The Company makes no representation concerning the full or partial functionality, accuracy, or reliability of any information or materials obtained by you through the Services.</span></p>

                <div className="content-subtitle">15. Limitation of Liability</div>
                <p className="long-content">To the maximum extent permitted by Applicable Law, in no event will the Company, its Affiliates, licensors or service providers, or any of their respective officers, directors, agents, joint venturers, employees or representatives, be liable or assume any obligation whatsoever to you or anyone on your behalf, regardless of the form of action, for any direct, indirect, special, incidental, or consequential damages or loss of any kind, including without limitation, loss of business, loss of profits, loss of revenue, loss of data, loss of contracts or loss of anticipated savings, loss of any passphrase (including with regard to Personal Wallet), any loss or any damage arising out of or relating to your authorized or unauthorized use of the Services, of the Treat Coins, Prizes, any service, product or content provided by any third party, Providers, whether based in contract, tort, negligence, or strict liability. In the event of any complaint, your sole and exclusive remedy is to discontinue using the Services. The Company shall not be liable for any acts or omissions made by your internet service provider or other third party with whom you have contracted to gain access to the Services. Furthermore, the Company shall not be liable in the event you provide the Company with an incorrect or an incompatible Personal Wallet, including but without limitation if Treat Coins are transferred by the service payment provider to such incorrect or incompatible Personal Wallet.</p>
                <p className="long-content">You agree and confirm that: (i) you are free to choose whether to use the Services and do so at your sole option, discretion, and risk; (ii) the Company shall not be liable to you or any third party for any modification to, suspension of, or discontinuance of the Services; (iii) any claim or cause of action, regardless of the form of action, which you may have arising out of or related to the use of the Services or these Terms, must be filed within one (1) year after such claim or cause of action arose.</p>

                <div className="content-subtitle">16. Indemnification</div>
                <p className="long-content">You hereby agree to indemnify and hold the Company, its officers, directors, employees, or agents harmless for any claims, matters, complaints, costs, liabilities, and actions arising out of or related to your use of the Services, including but not limited to:</p>
                <ol className="legal-list list-style-roman">
                    <li><p className="long-content">Your failure to comply with any Applicable Law;</p></li>
                    <li><p className="long-content">Any claim of infringement or misappropriation of any third-party rights, including but not limited to any copyright, privacy rights, or Intellectual Property Rights in the Services;</p></li>
                    <li><p className="long-content">Your breach of any provision of these Terms; or</p></li>
                    <li><p className="long-content">Your use or misuse of the Services.</p></li>
                </ol>
                <p className="long-content">You agree to promptly notify the Company of any third-party claims and fully cooperate with the Company in defending such claims at your sole expense. You further agree that at Company's sole discretion, the Company shall have control of the defense or settlement of any such claims.</p>

                <div className="content-subtitle">17. Miscellaneous</div>
                <p className="long-content">These Terms shall be governed by and interpreted in accordance with the law of the Republic of Cyprus, without giving effect to its principles or rules of conflict of laws.</p>
                <p className="long-content">Any action to be brought in connection with these Terms shall be brought exclusively in the courts of the Republic of Cyprus, and you irrevocably consent to their jurisdiction.</p>
                <p className="long-content">In the event that any provision of these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision that most closely achieves the effect of the original provision, and the remaining provisions of these Terms shall remain in full force and effect.</p>
                <p className="long-content">We may assign our rights and obligations hereunder to any third party without prior notice.</p>
                <p className="long-content">You shall not assign any of your rights or obligations hereunder, and any assignment shall be null and void. Any provisions hereof which expressly or by their nature are required to survive termination or expiration of these Terms in order to achieve their purpose shall survive the termination or expiration, including without limitation Sections 2/5/6/7/8/9/10/11/12/13/14/15 of these Terms.</p>
                <p className="long-content">Any translation of these Terms of Use is provided solely for your convenience.</p>
                <p className="long-content">The meanings of terms, conditions, and representations herein are subject to definitions and interpretations in the English language and in the event of a dispute, the English language version shall prevail.</p>
                <p className="long-content">Any translation provided may not accurately represent the information in the original English language version of these Terms.</p>

                <div className="content-subtitle">18. Terms and Termination</div>
                <p className="long-content">These Terms apply to you and to us from the date that you accept them as provided above. We reserve the right, without notice and in our sole discretion, to terminate these Terms, revoke or forfeit your Treat Coins you accumulated, or terminate or suspend your access to or use of all or any part of the Services effective immediately, at any time including, inter alia, if we suspect in our sole discretion that (i) you were engaged or are being engaged in fraudulent activity or any illegal activity under any Applicable Law; (ii) you have provided false information to, or concealed any information from us; or (iii) you have engaged in activity in violation (including alleged violations) of these Terms.</p>

                <div className="content-subtitle">19. Changes</div>
                <p className="long-content">We reserve the right to amend any of the Terms at any time by publishing the revised versions on our Website or by otherwise providing notice of such amendment by email, via the Services, or posting on our Website. Notice will be deemed given twenty-four (24) hours after email is sent to you. Notice posted on our Website is deemed given ten (10) days following the initial posting.</p>
                <p className="long-content">We reserve the right to determine the form and means of providing notifications to our End User.</p>
                <p className="long-content">The revised versions shall become effective following the applicable notice period, unless you expressly accept the revised versions earlier by clicking on the accept button. Your express acceptance or continued use of the Services after the applicable notice period shall constitute your acceptance to be bound by the revised Terms, as applicable.</p>
                <p className="long-content">If any provision of the Terms is held to be unenforceable, such provision shall be modified only to the extent necessary to make it enforceable and shall not affect the enforceability or validity of the remaining provisions, which shall remain in full force and effect.</p>

                <div className="content-subtitle">20. Contact Us</div>
                <p className="long-content">If you have any questions, would like to provide us feedback or receive more information about us, please feel free to email us.</p>
            </div>
        );
    }

}

export default TermsText;