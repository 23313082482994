import Joining from 'utils/Joining.js';
import ApiFacade from 'ApiFacade.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import { useNavigate } from 'react-router-dom';

const configuration = require('configuration.json');

const websiteLocations = configuration.website_locations;
const mixpanelEvents = configuration.mixpanel_events;

const OffersPageLinkButton = (props) => {
    const navigate = useNavigate();

    if (!ApiFacade.isLoggedIn()) {
        return (
            <span className="earn-coins-button-container">
                <button
                    data-event={mixpanelEvents.join_now_click}
                    className="offers-page-link" onClick={(event) => {
                        Joining.showPopup(navigate);
                        Mixpanel.trackByEvent(event);
                    }
                    }>
                    {(props.page === websiteLocations.home) ? Content.getValue("join_treatcoin_now") : Content.getValue("join_now")}
                </button>
            </span>
        );
    }
    else {
        return (
            <span className="earn-coins-button-container" >
                <RedirectWithLang to={configuration.paths.playground} text={
                    (props.page === websiteLocations.home) ?
                        Content.getValue("gain_treat_coins") :
                        Content.getValue("earn_more_treat_coins")}
                    props={{ className: "offers-page-link" }}>
                </RedirectWithLang>
            </span >
        );
    }
};

export default OffersPageLinkButton;