import React, { Component } from 'react';
import Content from 'utils/Content.js';

class TreatCoinsMissionText extends Component {

    render() {
        return (
            <div
            dangerouslySetInnerHTML={{__html: Content.getValue("treatcoins_mission_static")}}
                  />
        )
    }
}

export default TreatCoinsMissionText;