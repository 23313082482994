import Content from 'utils/Content.js';
import JWTUtils from 'utils/JWT_Utils.js';

class Formatting {

    static format(text, formats, values) {
        for (let i = 0, len = Math.min(formats.length, values.length); i < len; i++) {
            if(text.indexOf("{{") > -1){
                if(formats[i].startsWith("(") && formats[i].endsWith(")")){
                    formats[i] = `{{${(formats[i].slice(1, -1))}}}`
                }
            }
            text = text?.replace(formats[i], (values[i] !== null) ? values[i]: '');
        }

        return text;
    }

    static formatUSDAmount(number, showDecimals = false) {
        let amountStr;
        if (showDecimals) amountStr = number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        else amountStr = number.toLocaleString();
        return this.format(Content.getValue("usd_amount_label"), ["(amount)"], [amountStr]);
    }

    static currencyLabel() {
        let currentCountry = JWTUtils.getJWTCountry();
        let isBrazil = (currentCountry === 'BR');
        return isBrazil ? 'BRL' : 'USD';
    }   

    static currencySymbol() {
        let currentCountry = JWTUtils.getJWTCountry();
        let isBrazil = (currentCountry === 'BR');
        return isBrazil ? 'R$' : 'US$';
    }   

    static formatThousandAmount(number, currency) {
        let amountStr;
        if (number > 1000) {
            let amount = number/1000;
            amountStr = this.format(
                Content.getValue("amount_label_thousand"), 
                ["(currency)", "(amount)"], 
                [currency, Math.round(amount * 10) / 10]
            );
        }
        else amountStr = this.format(
            Content.getValue("amount_label"),
            ["(currency)", "(amount)"], 
            [currency, number]
        );

        return amountStr;
    }

    static formatTreatCoinAmount(number) {
        return (number !== undefined && number !== null) ? number.toLocaleString() : null;
    }

    static createOrdinal(number) {
        let j = number % 10,
            k = number % 100;
        if (j === 1 && k !== 11) {
            return number + "st";
        }
        if (j === 2 && k !== 12) {
            return number + "nd";
        }
        if (j === 3 && k !== 13) {
            return number + "rd";
        }
        return number + "th";
    }

    static shortenText(str, maxLength) {
        let wideLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        let strToCompare = str;
        if (!strToCompare) return '';
        let wideLetter;
        for (let i = 0, len = wideLetters.length; i < len; i++) {
            wideLetter = wideLetters[i];
            strToCompare = strToCompare.replaceAll(wideLetter, wideLetter + wideLetter);
          }
        if (strToCompare.length > maxLength) {
          let stringToReturn = strToCompare.substring(0, maxLength) + '...';
          for (let i = 0, len = wideLetters.length; i < len; i++) {
            wideLetter = wideLetters[i];
            stringToReturn = stringToReturn.replaceAll( wideLetter + wideLetter, wideLetter);
          }
          return stringToReturn;
        }
        else {
            return str;
        }
    }
    
    static removeDuplicateSegments(path) {
        const segments = path.split('/');
        const uniqueSegments = segments.filter((segment, index, array) => segment !== '' && array.indexOf(segment) === index);
        const uniquePath = '/' + uniqueSegments.join('/');
        return uniquePath;
    }

    static floorWithConditions(number, decimalPlaces) {
        if (Number.isInteger(number)) {
            return number;
        } else if (number > 100) {
            return Math.floor(number);
        } else if (number >= 0.05 && number < 0.1) {
            return 0.1;
        } else {
            const factor = Math.pow(10, decimalPlaces);
            return Math.floor(number * factor) / factor;
        }
    }

}

export default Formatting;