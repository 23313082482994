import React, { useState } from 'react';
import Images from 'utils/Images.js';

import Content from 'utils/Content.js';

function Select({ value, placeholder, items=[], optionChangeHandler }) {
    const [open, setOpen] = useState(false);
    const clickHandler = () => setOpen(prev => !prev);
    const changeHandler = (value) => {
        optionChangeHandler(value);
        setOpen(false);
    }

    return (
        <div className='select-container desktop'>
            <h2 className='quiz-option-label black-label'>{ Content.getValue("intro_survey_dropdown_label") }</h2>
            <div className='select-container-static'>
                <span>{ items[value] || placeholder }</span>
                <div 
                    className='select-option-icon' 
                    onClick={clickHandler} >
                    <img 
                        src={Images.imageURL('select-option-icon.png')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    /> 
                </div>
            </div>
            {open && items.length>0 ?
                <div className='select-items'>
                    { items.map((each, idx) => (
                        <p key={each} onClick={() => changeHandler(idx)}> { each } </p>
                    ))}
                </div> :
                null
            }
        </div>
    )
}

export default Select;