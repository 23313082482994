import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Dialog } from "@mui/material";
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import OffersPageLinkButton from 'components/buttons/Offers_Page_Link_Button.js'
import TotalRewardsFund from 'components/prizes/Total_Rewards_Fund.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';
import ConvertButton from 'components/buttons/Convert_Button.js';
import ConversionCalculator from 'components/payouts/Conversion_Calculator.js';
import Payments from 'utils/Payments.js';
import CompetitionInterval from 'utils/Competition_Interval.js';

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');

const UISettings = configuration.ui_settings;
const websiteLocations = configuration.website_locations;

const historySwipeSensitivity = UISettings.history_swipe_sensitivity;
let touchStart;
let touchEnd;

let thisComponent;

class Highscore extends React.Component {

    constructor(props) {
        super(props);
        this.language = props.language;
        this.isLeaderboards = props.isLeaderboards;
        this.isHomepage = props.isHomepage;
        this.currentYear = new Date().getUTCFullYear();
        this.currentMonth = new Date().getUTCMonth() + 1;
        this.scoreYear = this.currentYear;
        this.scoreMonth = this.currentMonth;
        if (props.isLeaderboards) {
            this.startMonth = globalConf.start_date.month;
            this.startYear = globalConf.start_date.year;
        }
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            myRanking: null,
            myScore: null,
            myPrizeAmount: null,
            myPrizeCurrency: null,
            prizeFundAmount: null,
            prizeFundCurrency: null,
            coins: null,
            conversionRate: null,
        };
        thisComponent = this;
    }

    componentDidMount() {
        this.loadScore();
        if (ApiFacade.isLoggedIn()) {
            this.loadRate();
        }
    }

    handleStart(e) {
        touchStart = e.targetTouches[0].clientX;
    }

    handleMove(e) {
        touchEnd = e.targetTouches[0].clientX;
    }

    handleEnd() {
        if (touchStart - touchEnd > historySwipeSensitivity) {
            thisComponent.increaseScoreMonth();
        }

        if (touchStart - touchEnd < - historySwipeSensitivity) {
            thisComponent.decreaseScoreMonth();
        }
    }

    loadRate() {
        ApiFacade.conversionRate().then(function (res) {
                if (res.unauthorized){
                    thisComponent.setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else if (!res.ok) {
                    thisComponent.setState({
                        isLoaded: true,
                        error: res.error
                    });
                } 
                else {
                    thisComponent.setState(prev => ({
                        ...prev,
                        coins: res.response.coins,
                        conversionRate: res.response.conversionRate,
                    }));
                }
            },
                (error) => {
                    thisComponent.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    loadScore() {
        //let fetchURL;
        if (!this.language){
            this.language = Content.getPreferredLanguage();
        }

        let resPromise = null;
        if (this.scoreMonth === this.currentMonth && this.scoreYear === this.currentYear) {
            resPromise = ApiFacade.top100Users(this.language);
        }

        resPromise.then(async function (res) {
            if (res.ok){
                let result = res.response;
                thisComponent.setState({
                    isLoaded: true,
                    items: result.scores,
                    myRanking: result.myRanking,
                    myScore: result.myScore,
                    myPrizeAmount: result.myPrizeAmount,
                    myPrizeCurrency: result.myPrizeCurrency,
                    prizeFundAmount: result.prizeFundAmount,
                    prizeFundCurrency: result.prizeFundCurrency,
                    scoreMonthDisplay: CompetitionInterval() + ' ' + thisComponent.scoreYear
                });
            } else {
                thisComponent.setState({
                    isLoaded: true,
                    error: res.error
                });
            }
        });   
    }

    render() {
        const { open, error, isLoaded, items, myRanking, myScore, myPrizeAmount, prizeFundAmount, prizeFundCurrency, scoreMonthDisplay, coins, conversionRate } = this.state;
        const itemsToDraw = items.slice(3);
        const [firstReward, secondReward, thirdReward] = items;

        if (error) {
            return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
        } else if (!isLoaded) {
            return <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>;
        } else {
            return (
                <>
                    <div className="highscores-display-container">
                        <TransitionGroup component={null}>
                            <CSSTransition
                                key={`${this.scoreMonth}_${this.scoreYear}`}
                                classNames="highscores-display"
                                timeout={500}
                            >
                                <div>
                                    {
                                        (this.isLeaderboards) ?
                                            (
                                                <TotalRewardsFund prizeFundAmount={prizeFundAmount} prizeFundCurrency={prizeFundCurrency} month={scoreMonthDisplay} />
                                            ) : (null)
                                    }
                                    {
                                        (myRanking !== undefined && myRanking !== null) ?
                                            (
                                                <>
                                                    <div className="my-score-div">
                                                        <table className={(this.scoreYear === this.currentYear && this.scoreMonth === this.currentMonth) ? 'current-leaderboards' : 'current-leaderboards history'} cellSpacing="0" cellPadding="0">
                                                            <colgroup>
                                                                <col span="1" className="rank-column" />
                                                                <col span="1" className="username-column" />
                                                                <col span="1" className="coins-column" />
                                                                <col span="1" className="reward-column" />
                                                            </colgroup>
                                                            <thead>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="my-score">
                                                                    <td className="highscore-rank-cell"><div className="highscore-rank"><img className="highscore-star" alt="Highscore star" src={Images.imageURL('highscores-star.png')} /><span className="rank-number">#{myRanking}</span></div></td>
                                                                    <td className="highscore-user-cell"><span className="highscore-user">{Content.getValue("highscore_you")}</span></td>
                                                                    <td>{Formatting.formatTreatCoinAmount(myScore)} <p className="highscore-indicator">{Content.getValue("highscore_coins")}</p></td>
                                                                    <td>{Formatting.formatTreatCoinAmount(myPrizeAmount)} {Content.getValue("treat_coin_abbreviation")}<p className="highscore-indicator">{Content.getValue("highscore_prize")}</p></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="text-center margin-top_20">
                                                        <div className="relative">
                                                        {/^\d+$/.test(myRanking) && (
                                                            <img
                                                                src={Images.imageURL('providers-homepage-card3-image.png')}
                                                                alt="Broken line"
                                                                width="230"
                                                                height="200" 
                                                            />
                                                            )}
                                                            <ConvertButton clickHandler={() => thisComponent.setState(prev => ({...prev, open: true}))} />

                                                            <Dialog
                                                                sx={{
                                                                    "& .MuiPaper-root": {
                                                                        backgroundColor: "rgb(20, 22, 41)",
                                                                        paddingTop: 16,
                                                                    },
                                                                }}
                                                                fullScreen
                                                                classes={{
                                                                    container: 'overflow-scroll'
                                                                }}
                                                                open={open || false}
                                                            >
                                                                <div>
                                                                    <img src={Images.imageURL('close-welcome.png')} alt="Close withdrawal" width="30" className="close-convert" onClick={() => thisComponent.setState(prev => ({...prev, open: false}))} />
                                                                    <div className="withdraw-text-container margin-top-0">
                                                                        <span className="withdraw-text">{Content.getValue("convert_to_competitions_label")}</span>
                                                                        <img src={Images.imageURL('withdraw-hint.png')} alt="Withdrawal hint" width="20" className="withdraw-hint" onClick={() => Payments.showConvertHint()} />
                                                                    </div>
                                                                    <ConversionCalculator 
                                                                        coins={coins}
                                                                        conversionRate={conversionRate}
                                                                    />
                                                                </div>
                                                            </Dialog>
                                                        </div>
                                                        <p className="convert-button-description">{ 
                                                            Formatting.format(Content.getValue("convert_button_description"), ["(rate)"], [conversionRate])
                                                        }</p>
                                                    </div>
                                                </>
                                            )
                                            : (null)
                                    }
                                    {
                                        (this.isLeaderboards) ?
                                            (
                                                <OffersPageLinkButton page={websiteLocations.leaderboards} />
                                            ) : (null)
                                    }
                                    <div className="top3-container margin-top-30 padding-bottom-30">
                                        <span className="second-place-container">
                                            <div className="highscore-place second margin-bottom-20"> {Content.getValue("second_label")} </div>
                                            <div className="second-place-container-img-container">
                                                <img 
                                                    src={secondReward?.rewardImageURL}
                                                    alt="Second place"
                                                    width={60}
                                                    height={60}
                                                    className="place-highscore second" 
                                                />
                                            </div>
                                            <div className="highscore-data-container">
                                                <div className="highscore-data margin-top-20"> {secondReward?.user} </div>
                                                <div className="highscore-data"> {Formatting.formatTreatCoinAmount(secondReward?.score)} {Content.getValue("highscore_coins")}</div>
                                                <div className="highscore-data margin-top-8">  
                                                    { Formatting.formatTreatCoinAmount(secondReward?.rewardAmount) } 
                                                </div>
                                                <div className="highscore-data">  
                                                    { Content.getValue('treat_coins') } 
                                                </div>
                                            </div>
                                        </span>
                                        <span className="first-place-container">
                                            <img 
                                                src={Images.imageURL("crown.png")}
                                                alt="First place" 
                                                width={28}
                                                height={28}
                                                className="margin-bottom-30" 
                                            />
                                            <div className="first-place-container-img-container">
                                                <img 
                                                    src={firstReward?.rewardImageURL}
                                                    alt="First place" 
                                                    width={100}
                                                    height={100}
                                                    className="place-highscore first" 
                                                />
                                            </div>
                                            <div className="highscore-data-container">
                                                <div className="highscore-data margin-top-20"> {firstReward?.user} </div>
                                                <div className="highscore-data"> {Formatting.formatTreatCoinAmount(firstReward?.score)} {Content.getValue("highscore_coins")}</div>
                                                <div className="highscore-data margin-top-8">  
                                                    { Formatting.formatTreatCoinAmount(firstReward?.rewardAmount) } 
                                                </div>
                                                <div className="highscore-data">  
                                                    { Content.getValue('treat_coins') } 
                                                </div>
                                            </div>
                                        </span>
                                        <span className="third-place-container">
                                            <div className="highscore-place third margin-bottom-20"> {Content.getValue("third_label")} </div>
                                            <div className="third-place-container-img-container">
                                                <img 
                                                    src={thirdReward?.rewardImageURL}
                                                    alt="Third place" 
                                                    width={60}
                                                    height={60}
                                                    className="place-highscore third"
                                                />
                                            </div>
                                            <div className="highscore-data-container">
                                                <div className="highscore-data margin-top-20"> {thirdReward?.user} </div>
                                                <div className="highscore-data"> {Formatting.formatTreatCoinAmount(thirdReward?.score)} {Content.getValue("highscore_coins")}</div>
                                                <div className="highscore-data margin-top-8">  
                                                    { Formatting.formatTreatCoinAmount(thirdReward?.rewardAmount) } 
                                                </div>
                                                <div className="highscore-data">  
                                                    { Content.getValue('treat_coins') } 
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="highscores-div">
                                        {
                                            (this.isLeaderboards) ?
                                                (
                                                    <span className="highscore-month text-center w-full margin-top_20">{scoreMonthDisplay}</span>
                                                ) : (null)
                                        }
                                        {
                                            (this.isLeaderboards && (this.scoreYear !== this.startYear || this.scoreMonth !== this.startMonth)) ?
                                                (
                                                    (null)
                                                ) : (null)
                                        }
                                        {
                                            (this.isLeaderboards && (this.scoreYear !== this.currentYear || this.scoreMonth !== this.currentMonth)) ?
                                                (
                                                    (null)
                                                ) : (null)
                                        }
                                        <div className={`table-div ${this.isHomepage ? "homepage" : ""}`}>
                                            <table className={(this.scoreYear === this.currentYear && this.scoreMonth === this.currentMonth) ? 'current-leaderboards' : 'current-leaderboards history'} cellSpacing="0" cellPadding="0">
                                                <colgroup>
                                                    <col span="1" className="rank-column" />
                                                    <col span="1" className="username-column" />
                                                    <col span="1" className="coins-column" />
                                                    <col span="1" className="reward-column" />
                                                </colgroup>
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    {itemsToDraw.map(item => (
                                                        <tr key={`_score-${item.rank}`}
                                                            className={item.rank === 4 ? 'other-score' : 'other-score bordered'}>
                                                            <td className="highscore-rank-cell"><div className="highscore-rank"><img className="highscore-star" alt="Highscore star" src={Images.imageURL('highscores-star.png')} /><span className="rank-number">#{item.rank}</span></div></td>
                                                            <td className="highscore-user-cell"><span className="highscore-user">{item.user}</span></td>
                                                            <td>{Formatting.formatTreatCoinAmount(item.score)} <p className="highscore-indicator">{Content.getValue("highscore_coins")}</p></td>
                                                            <td>{Formatting.formatTreatCoinAmount(item.rewardAmount)} {Content.getValue("treat_coin_abbreviation")}<p className="highscore-indicator">{Content.getValue("highscore_prize")}</p></td>
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </>
            );
        }
    }
}

export default Highscore;