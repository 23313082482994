import React from "react";
import Images from "utils/Images.js";
import OfferEvents from "utils/Offer_Events.js";
import Sharing from "utils/Sharing.js";
import LinkCreator from "utils/Link_Creator.js";

import Content from 'utils/Content.js';

let thisComponent;

class OfferShareButton extends React.Component {
    constructor(props) {
        super(props);
        this.userID = props.userID;
        this.offerID = props.offerID;
        this.appName = props.appName;
        this.offerDescription = props.offerDescription;
        this.imageURL = props.imageURL;

        thisComponent = this;
    }

    componentWillUnmount() {
        OfferEvents.recordOfferPageVisit();
    }

   
    render() {
        return (
            <div className='offer-popup-grey-container flex justify-between share-offer-container' onClick={async () => {
                let offerShareLink = LinkCreator.createOfferShareLink(this.offerID, this.userID);
                await Sharing.shareLink(
                    {
                        title: Content.getValue("check_offer_on_treatcoin"),
                        text: thisComponent.appName + ": " + thisComponent.offerDescription,
                        url: offerShareLink,
                        fallbackTitle: Content.getValue("share_offer_fallback_title"),
                        fallbackMessage: Content.getValue("share_offer_fallback_text"),
                        detailsImageURL: Images.imageURL('offers/TreatCoin_800x712.png')
                    }
                );
            }
            }>
               <span className='offer-popup-white-heading-14 '>{Content.getValue('share_offer_with_friends')}</span>
               <img className="share-offer-button" id="share-offer-widget" alt="shareOffer" width="52" height="52" src={Images.imageURL('share-offer-new.png')} />                   
            </div>
       
        );
    }
}

export default OfferShareButton;