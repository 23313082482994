import React, { Component } from 'react';
import * as queryString from 'query-string';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Backend from 'Backend.js';
import UserData from 'User_Data.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';
import Platform from 'utils/Platform.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import Redirects from 'utils/Redirects.js';

const MySwal = withReactContent(Swal);

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');

const urls = configuration.urls;
const userDataKeys = configuration.user_data_keys;
const loginTypes = configuration.login_types;

const facebookConnection = globalConf.facebook_connection;

const facebookAuthURL = urls.facebook_auth_url;

const facebookAppID = facebookConnection.app_id;
const facebookRedirectUrl = facebookConnection.redirect_url;

class LoginFacebook extends Component {

    static handleFacebookLogin(facebookCode, gclient_id, fbclid, fbp, navigate, updateIsVisibleSpinner) {

        updateIsVisibleSpinner({ isVisible: true, contentKey: 'logging_in_with_facebook' });

        let robotIconURL = Images.imageURL('robot-icon.png');

        let ref = UserData.getItem(userDataKeys.referrer_code);
        const platform = Platform.getPlatformName();
        const directToDiscordCommunity = UserData.getItem(userDataKeys.redirect_to_discord_community_after_auth) ? 1 : 0;

        const postBody = {
            facebookCode: facebookCode,
            ref: ref,
            platformName: platform,
            gclient_id: gclient_id, 
            fbclid: fbclid,
            fbp: fbp,
            directToDiscordCommunity
        }

        fetch(Backend.backendURLs.facebookLoginURL, {
            credentials: "include",
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(postBody)
        }).then(async function (res) {
            updateIsVisibleSpinner({ isVisible: false });
            if (res.status === Backend.backendResponseCodes.internalServerErrorCode || res.status === Backend.backendResponseCodes.serviceUnavailableCode) {
                MySwal.fire({
                    title: '<p>' + Content.getValue("facebook_login_failed_title") + '</p>',
                    html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                    iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                    background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("close_button_text"),
                    customClass: {
                        confirmButton: 'standard-confirm-button'
                    }
                });
            }
            else if (res.status === Backend.backendResponseCodes.badRequestCode) {
                const result = await res.json();
                if (result.accountSuspended === true) {
                    redirectWithLangFunc(configuration.paths.account_suspended, navigate);
                }
                else {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("facebook_login_failed_title") + '</p>',
                        html: '<p class="swal2-content">' + Content.getValue(result.message) + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: false,
                        confirmButtonText: Content.getValue("close_button_text"),
                        customClass: {
                            confirmButton: 'standard-confirm-button'
                        }
                    });
                }
            }
            else {
                const result = await res.json();
                await Backend.setUserSessionData(result, loginTypes.facebook);
                Redirects.makeRedirectAfterLogin(result.existingAccount, navigate);
            }

        });
    }

    render() {
        const stringifiedParams = queryString.stringify({
            client_id: facebookAppID,
            redirect_uri: facebookRedirectUrl,
            scope: ['email'].join(','),
            response_type: 'code',
            auth_type: 'rerequest',
            display: 'popup',
        });

        const facebookLoginUrl = Formatting.format(facebookAuthURL, ["(stringifiedParams)"], [stringifiedParams]);

        return <a href={facebookLoginUrl} className="facebook-signup-menu-link">
            {Content.getValue("login_with_facebook_button_text")}
        </a>
    }
}

export default LoginFacebook;