import React from 'react';
import Marquee from 'react-fast-marquee';

export default function LiveNews({ news }) {

    return news ?
        (
            <div className='live-news-container' >
                <div className='live-news' id='live-news-track'>
                    <Marquee
                        loop={0}
                        speed={50}
                        delay={1}
                        gradient={false}
                        pauseOnHover
                    >
                        {
                            news.map((item, idx) => (
                                <p key={idx} className='live-news-item'>
                                    {item}
                                    <span className='entity'>{ '•' }</span>
                                </p>
                            ))
                        }
                        {
                            news.map((item, idx) => (
                                <p key={idx} className='live-news-item'>
                                    {item}
                                    <span className='entity'>{ '•' }</span>
                                </p>
                            ))
                        }
                    </Marquee>
                    
                </div>
            </div>
        ) :
        null
}
