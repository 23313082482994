import React from 'react';
import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import Survey from 'utils/Survey.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';


const configuration = require('configuration.json');


const userDataKeys = configuration.user_data_keys;

class IntroSurveyAvailableCall extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { navigate } = this.props;
        if (UserData.getItem(userDataKeys.intro_survey_available) === false) {
            Survey.displaySurveyAlreadyCompleted(navigate);
        }
        else { 
            const req = ApiFacade.introSurveyAvailable();
            req.then(function (res) {
                    if (res.ok) {
                        let result = res.response;
                        let introSurveyAvailable = result.introSurveyAvailable;
                        if (introSurveyAvailable === false) {
                            UserData.setItem(userDataKeys.intro_survey_available, introSurveyAvailable);
                            Survey.displaySurveyAlreadyCompleted(navigate);
                        }
                    } else {
                        redirectWithLangFunc(configuration.paths.playground, navigate);
                    }
                },
                () => {
                    redirectWithLangFunc(configuration.paths.playground, navigate);
                }
            );
        }
    }

    render() {
        return (null);
    }

}

export default IntroSurveyAvailableCall;