import React from 'react';

function QuizOption({ 
    data, 
    checked, 
    changeCheckboxHandler
}) {
    return (
        <div 
            className={`quiz-option-container desktop ${checked ? 'checked' : ''}`}
            onClick={changeCheckboxHandler} 
        >
            <div className='quiz-option-data desktop'>
                <label onClick={evt => evt.preventDefault()}>
                    <input type="checkbox" />
                    <span className="checkmark" />
                    <span className="text">{ data }</span>
                </label>
            </div>
        </div>
    )
}

export default QuizOption;