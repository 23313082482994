import ContentPageHeader from "components/sections/Content_Page_Header.js";
import PrivacyText from 'components/text/Privacy_Text.js';
import Footer from 'components/sections/Footer.js';

import Content from 'utils/Content.js';
import { useEffect } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const PrivacyPolicy = () => {

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.privacy_policy });
    }, []);

    return (
        <div>
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 className="legal-title">{Content.getValue('menu_title_privacy_policy')}</h1>
                            </div>
                            <div className="page-container">
                                <PrivacyText />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;