import React, { useState, useEffect } from 'react';
import OfferDifficultyIndicator from 'components/offers/Offer_Difficulty_Indicator.js';
import useTimer from 'utils/hooks/useTimer.js';
import OfferThemeIndicator from 'components/offers/Offer_Theme_Indicator.js'

import Content from 'utils/Content.js';
import BlurProvider from 'components/offer_providers/Blur_Provider.js';
import { useContext } from 'react';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';

const secondToMillisecond = 1000;

const X2OfferPlayground = ({
    offer,
    isFirstTime,
    setIsFirstTime
}) => {
    const [state, setState] = useState({
        time: null,
        limited: false,
    });
    const { handleOfferIsOpened } = useContext(OfferPopupContext);

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const { hours, minutes, seconds } = useTimer(offer.expireTime * secondToMillisecond);
    useEffect(() => {
        const isLimited = (hours <= 0) && (minutes <= 0) && (seconds <= 0);
        if (isLimited) {
            setState(prev => ({
                ...prev,
                limited: true
            }));
            return;
        }
        const time = hours > 0 ?
            (padTo2Digits(hours) + ':' + padTo2Digits(minutes) + ':' + padTo2Digits(seconds)) :
            (padTo2Digits(minutes) + ':' + padTo2Digits(seconds));
        setState(prev => ({
            ...prev,
            time
        }))
        if (time && !isFirstTime) setIsFirstTime(true);
    }, [seconds]);

    const offerElement = (
        <div className='x2-offer-container-second-container new'>
            <div className='x2-offer-container-second new'>
                <div className="list-offer-container-image x2-offer-container-image" onClick={() => { handleOfferIsOpened({ offer }); }}>
                    <OfferDifficultyIndicator difficultyLevel={offer.difficultyLevel} difficultyLevelID={offer.difficultyLevelID} imageType="white" />
                    <img className="hot-offer-image" src={offer.imageURL} alt={offer.description} width="36" height="auto" />
                    <OfferThemeIndicator offer={offer} />
                </div>
                <div className="list-offer-container-titles x2-offer-container-titles">
                    <div>
                        <div className="list-offer-app-name" onClick={() => { handleOfferIsOpened({ offer }); }}>{offer.appName}</div>
                        <div className="list-offer-description hot-offer-description" onClick={() => { handleOfferIsOpened({ offer }); }}>{offer.MainTitle}</div>
                        <div className='list-offer-hash-tags'></div>
                    </div>
                </div>
            </div>
            <button
                className="hot-offer-button playground x2 flex-column justify-center items-center"
                onClick={() => { handleOfferIsOpened({ offer }); }} >
                <div className="hot-offer-button-div">
                    <span>
                        {
                            !offer.allowsUsableAndCompetition ?
                            offer.coinsCompetition * 2 :
                            offer.coins * 2
                        }
                    </span>
                </div>
                <span className="hot-offer-button-span">{Content.getValue("treat_coins")}</span>
            </button>
        </div>
    );

    return (
        (!state.limited && isFirstTime) ?
            (
                <div className="x2-offer-container playground relative">
                    <div id="x2-offer-container-first new" className="x2-offer-container-first new">
                        <h2 className="x2-offer-container-first-header-playground">
                            {Content.getValue("x2offer")}
                        </h2>
                        <h2 className="x2-offer-container-first-description-new">
                            {Content.getValue("x2offer_award")}
                        </h2>
                        <h2 id="hot-offer-time" className="hot-offer-time-new margin-top-10">
                            {state.time}
                        </h2>
                        <h2 className="hot-offer-left-new">
                            {Content.getValue("minutes_left")}
                        </h2>
                    </div>

                    {
                        !offer.isAvailable ?
                        (
                            <BlurProvider count={1}>
                                { offerElement }
                            </BlurProvider>
                        ) :
                        offerElement
                    }
                </div>
            ) :
            null
    );
}

export default X2OfferPlayground;