import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Flags from 'country-flag-icons/react/3x2';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserData from 'User_Data.js';
import ApiFacade from 'ApiFacade.js';
import Content from 'utils/Content.js';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpinnerContext } from 'context/Global_Spinner.js';

const configuration = require('configuration.json');
const languageItems = configuration.languages;
const languageUrlCodes = languageItems.map(lang => lang.url_code);
const userDataKeys = configuration.user_data_keys;

const StyledMenuItem = styled(MenuItem)`
        background-color: #afaeff;
        display: 'flex';
        align-items: 'start';
    `;

const StyledSelect = styled(Select)`
        background-color: #afaeff;
        width: 100%;
        margin-top: 15px;
        max-height: 300px;
        border-radius: 8px;
   `;

const StyledFlagContainer = styled('div')`
        width: 30px;
        height: 30px;
        margin-top: 5px;
        margin-right: 10px;
    `;

const StyledLabel = styled('div')`
        font-family: Poppins;
        font-weight: 400;
        font-size: 18px;
        color: #15182A;
    `;

const LanguageSelect = ({ toggleMenu }) => {
    const navigate = useNavigate();
    const { updateIsVisibleSpinner } = useContext(SpinnerContext);
    const defaultLanguage = Content.getPreferredLanguage();
    const userToken = UserData.getItem(userDataKeys.jwt_token);

    useEffect(() => {
        if (defaultLanguage === null || defaultLanguage === undefined) {
            UserData.setItem(userDataKeys.language, languageItems[0].code);
        }
    }, [defaultLanguage]);


    const Flag = ({ countryCode }) => {
        const FlagComponent = Flags[countryCode.slice(3, 6)];
        return FlagComponent ?
            <StyledFlagContainer>
                <FlagComponent />
            </StyledFlagContainer>
            : <></>;

    };

    const updateBrowserLanguage = async (newValue) => {
        await Content.updatePreferredLanguage(newValue);
        let normalizedPath = window.location.pathname.split('/').pop();
        if (languageUrlCodes.includes(normalizedPath)) {
            normalizedPath = '';
        }
        redirectWithLangFunc('/' + normalizedPath, navigate);
    };

    const handleLanguageSelect = async (newValue) => {
        updateIsVisibleSpinner({ isVisible: true });
        const req = ApiFacade.updateLanguage(newValue);
        const res = await req;
        let result = res.response;
        UserData.setItem(userToken ? userDataKeys.jwt_token: userDataKeys.guest_token, result.token);
        await updateBrowserLanguage(newValue);
        updateIsVisibleSpinner({ isVisible: false });
        toggleMenu();
    };

    return (
        <StyledSelect
            defaultValue={defaultLanguage ? defaultLanguage : languageItems[0].code}
            labelId="simple-select-label"
            id="simple-select"
            IconComponent={KeyboardArrowDownIcon}
            onChange={e => {
                handleLanguageSelect(e.target.value);
            }}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 300,
                        backgroundColor: '#afaeff'
                    },
                },
            }}
        >
            {languageItems.map(({ code, label, native_label }) => (
                <StyledMenuItem key={code + label} value={code}>
                    <Flag countryCode={code} />
                    <StyledLabel>{native_label}</StyledLabel>
                </StyledMenuItem>
            ))}
        </StyledSelect>
    );
};


export default LanguageSelect;
