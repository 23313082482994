import React from 'react';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images';
import { RedirectWithLang, redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import { useNavigate } from 'react-router-dom';
import Alerts from 'utils/Alerts';

const configuration = require('configuration.json');

const ContentPageLogo = props => {
    const navigate = useNavigate();

    const {layout} = props

    let logoSrc = "treat-coin-logo-new.png"
    let infoIcon;

    if(layout == "gold"){
        logoSrc = "treat-coin-logo-gold.png"
        infoIcon = <span className='info-icon' onClick={()=> Alerts.showTreatcoinGoldBenefitsPopup()}></span>
    }

    return (
        <div className='header-logo'>
            <RedirectWithLang
                props={{
                    className: "flex"
                }}
                to={configuration.paths.home}
                onClick={() => redirectWithLangFunc(configuration.paths.home, navigate)}
            >
                <img
                    src={Images.imageURL(logoSrc)}
                    width="184"
                    alt="Home"
                    className={`content-page-logo${ApiFacade.isLoggedIn() ? " new" : ""}`}
                    style={{maxHeight: 49}}
                />
                
            </RedirectWithLang>
            {infoIcon}
        </div>
    );
};

export default ContentPageLogo;
