import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import jwt from 'jwt-decode';
import queryString from 'query-string';
import Backend from '../Backend';
import JWTUtils from 'utils/JWT_Utils.js';

const configuration = require('configuration.json');

const eventTypes = configuration.event_types;
const businessLogic = configuration.business_logic;
const userDataKeys = configuration.user_data_keys;

const uniqueIDLength = businessLogic.unique_id_length;


class OfferEvents {


    static generateOfferWallVisitID() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < uniqueIDLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        UserData.setItem(userDataKeys.offer_wall_visit_id, result);
    }

    static async sendEvent(offerEvent, redirectUrl = null) {
        const postBody = {
            eventType: offerEvent.eventType,
            offerWallVisitID: offerEvent.offerWallVisitID
        };

        if (offerEvent.offerID !== undefined) {
            postBody.offerID = offerEvent.offerID;
        }
        if (offerEvent.impressions !== undefined) {
            postBody.impressions = offerEvent.impressions;
        }
        if (offerEvent.duration !== undefined) {
            postBody.duration = offerEvent.duration;
        }
        if (offerEvent.impressionIDs !== undefined) {
            postBody.impressionIDs = offerEvent.impressionIDs;
        }
        if (offerEvent.offerStartType !== undefined) {
            postBody.offerStartType = offerEvent.offerStartType;
        }
        if (offerEvent.platformName !== undefined) {
           postBody.platformName = offerEvent.platformName;
        }
        if (offerEvent.provider !== undefined) {
           postBody.provider = offerEvent.provider;
        }
        if (offerEvent.category !== undefined) {
           postBody.category = offerEvent.category;
        }
        if (offerEvent.payout !== undefined) {
           postBody.payout = offerEvent.payout;
        }
        if (offerEvent.UserPayout !== undefined) {
            postBody.UserPayout = offerEvent.UserPayout;
         }
        if (offerEvent.isFree !== undefined) {
           postBody.isFree = offerEvent.isFree;
        }
        if (offerEvent.offerType !== undefined) {
            postBody.offerType = offerEvent.offerType;
         }
        if (offerEvent.appName !== undefined) {
            postBody.appName = offerEvent.appName;
         }
        if (offerEvent.uniqueID !== undefined) {
            postBody.uniqueID = offerEvent.uniqueID;
        }
        if (offerEvent.eventType === eventTypes.clickthrough) {
            const queryParams = queryString.stringify({
                a:1,
                eventType: offerEvent.eventType,
                offerID: offerEvent.offerID,
                offerType: offerEvent.offerType,
                offerStartType: offerEvent.offerStartType,
                offerWallVisitID: offerEvent.offerWallVisitID || 'unknown',
                platformName: offerEvent.platformName,
                stockCombinationID: offerEvent.stockCombinationID || 0,
                uniqueUserID: UserData.getItem(userDataKeys.jwt_token) ? jwt(UserData.getItem(userDataKeys.jwt_token)).uniqueUserID : '',
                country: JWTUtils.getJWTCountry()
            }, { skipNull: true });
            const constructedURL = Backend.backendURLs.offerEventsURL + '?' + queryParams;
           if (offerEvent.isSystem) {
                await ApiFacade.recordOfferEventNew(queryParams);
                if (redirectUrl){
                    window.location.href = redirectUrl;
                }
            } else {
                window.open(constructedURL, '_blank');
            }
        } else {
            await ApiFacade.recordOfferEvent(postBody);
        }
    }

    static recordOfferPageVisit() {
        const visitStartEpochTime = UserData.getItem(userDataKeys.offer_page_visit_start);
        if (visitStartEpochTime !== null) {
            UserData.removeItem(userDataKeys.offer_page_visit_start);
            const currentEpochTime = Math.round(new Date().getTime() / 1000);
            this.sendEvent({
                offerID: document.getElementById('offer-details-id').value,
                offerType: document.getElementById('offer-details-type').value,
                duration: currentEpochTime - visitStartEpochTime,
                eventType: eventTypes.offer_page_visit,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            });
        }
    }

    static recordOfferWallVisit() {
        const visitStartEpochTime = UserData.getItem(userDataKeys.offer_wall_visit_start);
        if (visitStartEpochTime !== null) {
            UserData.removeItem(userDataKeys.offer_wall_visit_start);
            const currentEpochTime = Math.round(new Date().getTime() / 1000);           
            this.sendEvent({
                duration: currentEpochTime - visitStartEpochTime,
                eventType: eventTypes.offer_wall_visit,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            });
            UserData.removeItem(userDataKeys.offer_wall_visit_id);
        }
    }

    static storeEventsOnRefresh() {
        let offerTrackingEvents = [];
        let event;
        const currentEpochTime = Math.round(new Date().getTime() / 1000);
        if (UserData.getItem(userDataKeys.offer_wall_visit_start) !== null) {
            event = {
                duration: currentEpochTime - UserData.getItem(userDataKeys.offer_wall_visit_start),
                eventType: eventTypes.offer_wall_visit,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            };
            offerTrackingEvents.push(event);
            UserData.removeItem(userDataKeys.offer_wall_visit_start);
        }
        if (UserData.getItem(userDataKeys.offer_page_visit_start) !== null) {
            let event = {
                offerID: document.getElementById('offer-details-id').value,
                offerType: document.getElementById('offer-details-type').value,
                duration: currentEpochTime - UserData.getItem(userDataKeys.offer_page_visit_start), eventType: eventTypes.offer_page_visit,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            };
            offerTrackingEvents.push(event);
            UserData.removeItem(userDataKeys.offer_page_visit_start);
        }
        UserData.removeItem(userDataKeys.offer_wall_visit_id);
        UserData.setItem(userDataKeys.offer_events, JSON.stringify(offerTrackingEvents));
    }

}

export default OfferEvents;