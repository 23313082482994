import UserData from 'User_Data.js';
import ActionButton from 'components/buttons/Action_Button.js';
import ReportInput from 'components/offers/popup/Report_Input.js';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import platform from 'platform';
import { useContext, useEffect, useState } from 'react';
import hasFalseValues from 'utils/Check_False_Values.js';
import Content from 'utils/Content.js';
import ApiFacade from 'ApiFacade.js';
import Backend from 'Backend.js';
import { SpinnerContext } from 'context/Global_Spinner.js';
import BackButton from 'components/buttons/Back_Button.js';
import Formatting from 'utils/Formatting.js';
import AddIcon from '@mui/icons-material/Add';
import ReportFormError from './Report_Form_Error.js';
import DataValidator from 'utils/Data_Validator.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

let reportUsername = UserData.getItem(userDataKeys.report_username);

const retryCount = 3; // Define how many times you want to retry
const retryDelay = 1000; // Define the delay between retries in milliseconds

const retryFailedPromises = async (failedPromises) => {
    const retryablePromises = failedPromises.filter(
        (promiseResult) =>
            promiseResult.status === "rejected" &&
            promiseResult.reason &&
            promiseResult.reason.statusCode === 503
    );

    for (const promiseResult of retryablePromises) {
        let retryAttempts = 0;
        while (retryAttempts < retryCount) {
            try {
                await Backend.sendFileViaLink(
                    promiseResult.reason.image,
                    promiseResult.reason.url
                );
                break; // If successful, break the retry loop
            } catch (error) {
                if (error.statusCode === 503) {
                    retryAttempts++;
                    await new Promise((resolve) => setTimeout(resolve, retryDelay)); // Wait before retrying
                } else {
                    break; // If the error is not 503, do not retry
                }
            }
        }
    }
};

function ReportForm({ handleOpenSubmitForm, offerName, uniqueOfferID, ProviderID, handleError }) {
    let reportEmail = UserData.getItem(userDataKeys.report_email);
    const [reportStep, setReportStep] = useState(1);
    const { offer, setOffer } = useContext(OfferPopupContext);
    const { updateIsVisibleSpinner } = useContext(SpinnerContext);
    const [formData, setFormData] = useState({
        ans2: 0,
        ans8: offerName,
        ans9: '',
        ticketReportEmail: reportEmail || '',
        ticketReportIdentifier: '', 
        ticketReportName: '',
        device: '',
        browserVersion: '',
        uniqueOfferID,
        providerID: ProviderID,
    });
    const [images, setImages] = useState([]);
    const [fileInputs, setFileInputs] = useState(2);
    const [error, setError] = useState(null);

    const handleDeviceOsSet = () => {
        setFormData(prev => ({ ...prev, device: (platform.description || 'none'), browserVersion: (platform.version || 'none') }));
        setReportStep(1);
    };
    const getReportProps = () => {

        if (!reportEmail || !reportUsername) {
            const req = ApiFacade.getReportTicketProperties();
            req.then((res) => {
                const body = res?.response?.body ? JSON.parse(res.response.body) : {};
                if (body?.anonymizedEmail) {
                    setFormData(prev => ({ ...prev, ticketReportEmail: body?.anonymizedEmail }));
                    UserData.setItem(userDataKeys.report_email, body?.anonymizedEmail);
                }
                if (body?.reportUserName) {
                    setFormData(prev => ({ ...prev, ticketReportName: body?.reportUserName }));
                    UserData.setItem(userDataKeys.report_username, body?.reportUserName);
                }
            }).finally();
        }
    };
    useEffect(() => {
        handleDeviceOsSet();
        getReportProps();
    }, []);

    const confirmButtonHandler = () => {
        if (reportStep !== 3) {
            setReportStep(prev => prev + 1);
        } else if (hasFalseValues({ 1: images[0], 2: images[1] })) {
            setError(2);
            setTimeout(() => {
                setError(false);
            }, 2000);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const handleClose = () => {
            updateIsVisibleSpinner({ isVisible: false });
            handleOpenSubmitForm({ open: false });
        };
        const handleErrorReq = () => {
            handleError();
            handleOpenSubmitForm({ open: false });
            updateIsVisibleSpinner({ isVisible: false });
        };
        updateIsVisibleSpinner({ isVisible: true, contentKey: 'loading' });
        //send images
        const imagesList = images.reduce((accumulator, image) => {
            const file = image?.file;
            if (file) {
                accumulator.push({ img: `type-${Content.getValue(image['type'])}-id-${image['id']}-usersFileName-${file['name']}` });
            }
            return accumulator;
        }, []);
        const getURLsRequest = ApiFacade.getReportTicketLinks({
            uniqueOfferID,
            imagesList,
        });
        try {

            const { urls } = await getURLsRequest.then(res => {
                return res.response;
            });

            // send report text and images
            const promises = images?.map((image, index) =>
                Backend.sendFileViaLink(image['file'], urls[index]?.url).catch((error) => error)
            );
            Promise.allSettled(promises).then((results) => {
                retryFailedPromises(results).then(() => {
                    ApiFacade.submitTicketReport({
                        ...formData,
                        imagesList: urls.map(({ suffix, fileName }) => ({ suffix, fileName })),
                    }).then((res) => {
                        if (res.ok) {
                            handleClose();
                            setOffer({ ...offer, ReportStatus: 0 });
                        } else {
                            handleErrorReq();
                        }
                    });
                });
            });
        } catch (error) {
            handleErrorReq();
        }
    };

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const dropValue = (fileId) => {
        setImages(images.filter(image => image['id'] !== fileId));
    };
    return (
        <div className="offer-popup-report">
            <div className="offer-popup-report-form padding-20">
                <h4 className="offer-popup-gradient-heading-20 margin-bottom-5">{`${Content.getValue('submit_proof')} (${reportStep}/3)`}</h4>
                <p className="offer-popup-white-heading-14 text-center-i text-16-i margin-bottom-15">
                    {(() => {
                        switch (reportStep) {
                            case 1:
                                return Formatting.format(Content.getValue('submit_proof_step_1_desc'), ["{{appname}}"], [`"${offerName}"`]);
                            case 2:
                                return Formatting.format(Content.getValue('submit_proof_step_2_desc'), ["{{appname}}"], [`"${offerName}"`]);
                            case 3:
                                return Formatting.format(Content.getValue('submit_proof_step_3_desc'), ["{{appname}}"], [`"${offerName}"`]);
                            default:
                                return '';
                        }
                    })()}
                </p>
                {reportStep === 1 ?
                    <p className="offer-popup-white-heading-14 text-center-i text-16-i margin-bottom-15">
                        {Content.getValue('submit_proof_step_1_desc_2')}
                    </p>
                    : <></>
                }
                {reportStep === 3 ?
                    <p className="offer-popup-grey-text-12 margin-bottom-15">{Content.getValue('submit_proof_step_3_desc_sub')}</p>
                    : <></>}
                {
                    (() => {
                        switch (reportStep) {
                            case 1:
                                return (
                                    <ReportInput
                                        onChange={(value) => { handleInputChange('ticketReportEmail', value); }}
                                        disabled={reportEmail ? true : false}
                                        value={formData['ticketReportEmail']}
                                        label={Content.getValue('your_email')}
                                        type={'short-text'}
                                    />
                                );
                            case 2:
                                return (
                                    <>
                                        <ReportInput
                                            onChange={(value) => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    ticketReportName: value,
                                                    ticketReportIdentifier: value,
                                                }));
                                            }}
                                            value={formData['ticketReportIdentifier']}
                                            label={Content.getValue('submit_proof_step_2_identifier')}
                                            placeholder={Content.getValue('submit_proof_step_2_identifier_placeholder')}
                                            type={'short-text'}
                                        />
                                        <ReportInput
                                            value={formData['ans9']}
                                            placeholder={Content.getValue('submit_proof_step_2_notes_placeholder')}
                                            label={Content.getValue('submit_proof_step_2_notes')}
                                            type={'long-text'}
                                            onChange={(value) => { handleInputChange('ans9', value); }}
                                        />
                                    </>
                                );
                            case 3:
                                return (
                                    <>
                                        {Array.from({ length: fileInputs })?.map((item, index) =>
                                            <ReportInput
                                                onChange={async (name, file, id) => {
                                                    await setImages(prev => {
                                                       const result = [...prev, { id, type: name, file }];
                                                        return result;
                                                    });
                                                }}
                                                mandatory={index < 2}
                                                fileId={index + 1}
                                                key={index}
                                                images={images}
                                                type={'choose-file'}
                                                dropValue={dropValue}
                                            />
                                        )}
                                    </>
                                );
                            default:
                                return <></>;
                        }
                    })()
                }

            </div>
            <div className='w-full'>
                <div className='offer_popup_error_catcher'>
                    <ReportFormError style={{transform: 'translateX(-50%)'}} typeError={error} handleClose={() => setError(null)} />
                </div>
                {reportStep === 3 ?
                    <ActionButton
                        onClick={() => {
                            setFileInputs(prev => {
                                if (prev >= 2 && prev < 6) {
                                    return prev + 1;
                                } else {
                                    return prev;
                                }
                            });
                        }}
                        content={Content.getValue('add_more')}
                        style={{ fontSize: window.innerWidth < 528 ? 10 : 20, padding:  window.innerWidth < 528 ? '6px 17px' : '12px 35px', maxWidth: '207px', marginBottom: 30 }}
                    >
                        <AddIcon />
                    </ActionButton>
                    : <></>}
                <ActionButton
                    style={{ maxWidth: reportStep === 3 ? '100%' : '' }}
                    disabled={
                        ((reportStep === 1 && !DataValidator.isValidEmail(formData['ticketReportEmail']) && !reportEmail)) ||
                        (hasFalseValues({ 1: formData['ans9'], 2: formData['ticketReportIdentifier'] }) && reportStep === 2) ||
                        (reportStep === 3 && hasFalseValues(formData) )
                         || (reportStep===3 && DataValidator.hasDuplicatesArrObj(images) )
                    }
                    onClick={confirmButtonHandler} content={reportStep === 3 ? Content.getValue('submit_report') : Content.getValue('continue_button_text')}
                />
                <BackButton
                    enabled={true}
                    className={'button-back-bordered hidden-button '}
                    onClick={() => {
                    if (reportStep > 1) {
                        setReportStep(prev => prev - 1);
                    } else {
                        handleOpenSubmitForm({ open: false });
                    }
                }} />
            </div>
        </div>
    );
}

export default ReportForm;