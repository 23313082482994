import React from 'react';
import Images from 'utils/Images.js';

const SocialLoginOverlay = ({ text }) => {
  return (
    <div className="loading-overlay" id="social-login-overlay">
      <img src={Images.imageURL('loader.png')} width="240" className='rotate-image ' />
      {text && <div className="loading-text">{text}</div>}
    </div>
  );
};

export default SocialLoginOverlay;