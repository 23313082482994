const configuration = require('configuration.json');
const emailTypes = Object.keys(configuration.email_signup_types);

function isGmail(email) {
    const domain = email.substring(email.lastIndexOf("@") + 1).toLowerCase().split('.')[0];
    if (emailTypes[0].includes(domain)) {
        return true;
    } else {
        return false;
    }
}

export default isGmail;