import ApproveButton from 'components/buttons/Approve_Button.js';
import React, { useState } from 'react';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';

const BlurProvider = ({ 
    children, 
    count, 
    fullWidth, 
    isOneTwoTreatArea,
    isCashbackList,
    isHelpInvisible,
}) => {
    const [isInfoVisible, setIsInfoVisible] = useState(false);

    return (
        <div className="relative">
            <div className={`absolute-blurred-offer ${count===1 ? "row" : ""} ${fullWidth ? "full-width" : ""} padding-top-10 padding-bottom-10`}>
                <div className='text-center'>
                    <img 
                        src={Images.imageURL('lock-purple.png')}
                        alt='Locked'
                        width={20}
                        height={25}
                    /> 
                </div>
                <img 
                    src={Images.imageURL('help-gray.png')}
                    alt='Offer info help'
                    width={20}
                    height={20}
                    className={`absolute 
                              ${isHelpInvisible ? "hidden-element" : ""} 
                              ${isCashbackList ? "right-0" : ""}`
                    }
                    onClick={() => setIsInfoVisible(!isInfoVisible)}
                /> 
                <p className={`bold ${!isOneTwoTreatArea ? "font-18" : "font-13"} smaller`}>
                    { 
                        count===1 ?
                        Content.getValue("offer_blurred_text") :
                        Content.getValue("offers_blurred_text") 
                    }
                </p>
                <div>
                    <ApproveButton isOneTwoTreatArea={isOneTwoTreatArea} />
                </div>
            </div>
            {
                isInfoVisible ?
                (
                    <div className='offer-blurred-info'>
                        <div className='offer-blurred-info-text'>
                            { Content.getValue("offer_blurred_info_text") }
                        </div>
                    </div>
                ) : 
                ( null )
            }
            { children }
        </div> 
    );
};

export default BlurProvider;