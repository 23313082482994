import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Content from 'utils/Content.js';
import { SpinnerContext } from 'context/Global_Spinner.js';
import SignupInput from './Signup_Input.js';
import Images from 'utils/Images.js';
import isGmail from 'utils/Email_Type.js';
import ApiFacade from 'ApiFacade.js';
import DataValidator from 'utils/Data_Validator.js';
import Formatting from 'utils/Formatting.js';
import Redirects from 'utils/Redirects.js';
import UserData from 'User_Data.js';
import Platform from 'utils/Platform.js';
import LinkCreator from 'utils/Link_Creator.js';
import * as queryString from 'query-string';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const globalConf = require('global_conf.json');
const configuration = require('configuration.json');

const captchaConnection = globalConf.captcha_connection;
const urls = configuration.urls;
const emailSignupModes = configuration.email_signup_modes;

const captchaSiteKey = captchaConnection.site_key;
const userDataKeys = configuration.user_data_keys;
const googleCaptchaURL = urls.google_captcha_url;

// const facebookConnection = globalConf.facebook_connection;
const discordConnection = globalConf.discord_connection;

// const facebookAppID = facebookConnection.app_id;
// const facebookRedirectUrl = facebookConnection.redirect_url;

const discordClientID = discordConnection.client_id;
const discordRedirectUrl = discordConnection.redirect_url;

// const facebookAuthURL = urls.facebook_auth_url;
const discordAuthURL = urls.discord_auth_url;

/*
const stringifiedFacebookParams = queryString.stringify({
    client_id: facebookAppID,
    redirect_uri: facebookRedirectUrl,
    scope: ['email'].join(','),
    response_type: 'code',
    auth_type: 'rerequest',
    display: 'popup',
});
*/

const stringifiedDiscordParams = queryString.stringify({
    client_id: discordClientID,
    response_type: 'code',
    redirect_uri: discordRedirectUrl,
    scope: ['identify', 'email', 'guilds.join', 'guilds.members.read'].join(' '),
    prompt: 'none'
});

const BgDecor = ({ addedStyles, addedClassNames }) => {
    return (
        <div className={`join-bg-decor ${addedClassNames}`} style={{ ...addedStyles }}>
            <img src={Images.imageURL('confeti-login-mobile.png')} alt="Homepage robot" className="confeti-1" />
            <img src={Images.imageURL('confeti-login-desktop.png')} alt="Homepage robot" className="confeti-2" />
        </div>
    );
};

window.startTelegramLogin = function () {
    let telegramLoginLink = LinkCreator.createTelegramLink(); 
    window.location.href = telegramLoginLink; 
};

// const facebookLoginUrl = Formatting.format(facebookAuthURL, ["(stringifiedParams)"], [stringifiedFacebookParams]);
const discordLoginUrl = Formatting.format(discordAuthURL, ["(stringifiedParams)"], [stringifiedDiscordParams]);

let registerEmail = UserData.getItem(userDataKeys.email_register);

function Join({ variant, regEmail = '', setRegEmail, confirmationCode, isLogin, showSignUp }) {
    const navigate = useNavigate();
    const { updateIsVisibleSpinner } = useContext(SpinnerContext);
    const existingScript = document.getElementById('google-captcha');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = Formatting.format(googleCaptchaURL, ["(captchaSiteKey)"], [captchaSiteKey]);
        script.id = 'google-captcha';
        document.body.appendChild(script);
    }

    if (isLogin) {
        registerEmail = true;
    }

    const [error, setError] = useState(null);

    const errorSetter = (type) => {
        setError(type);
        setTimeout(() => {
            setError(null);
        }, 2000);
    };

    const handleEmailRegister = async (email) => {

        if (DataValidator.isValidEmail(email)) {
            updateIsVisibleSpinner({ isVisible: true });
            let postBody = {
                email,
                receiveNotifications: true,
                ref: UserData.getItem(userDataKeys.referrer_code),
                platformName: Platform.getPlatformName()
            };

            const directToDiscordCommunity = UserData.getItem(userDataKeys.redirect_to_discord_community_after_auth) ? 1 : 0;
            const utm_source = UserData.getItem(userDataKeys.utm_source);
            const utm_medium = UserData.getItem(userDataKeys.utm_medium);
            const utm_campaign = UserData.getItem(userDataKeys.utm_campaign);
            const utm_content = UserData.getItem(userDataKeys.utm_content);
            const utm_term = UserData.getItem(userDataKeys.utm_term);
            const fbclid = UserData.getItem(userDataKeys.fbclid);
            const device_id = Mixpanel.getDistinctId();

            postBody.directToDiscordCommunity = directToDiscordCommunity;
            postBody.utm_source = utm_source;
            postBody.utm_medium = utm_medium;
            postBody.utm_campaign = utm_campaign;
            postBody.utm_content = utm_content;
            postBody.utm_term = utm_term;
            postBody.fbclid = fbclid;
            postBody.device_id = device_id;

            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(captchaSiteKey, { action: 'submit' }).then(function (token) {
                    postBody.captchaToken = token;
                    let req = ApiFacade.registerWithEmail(postBody);
                    req.then(res => {
                        if (res.ok) {
                            setRegEmail(email);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        } else {
                            errorSetter(5);
                        }
                        updateIsVisibleSpinner({ isVisible: false });
                    });

                });
            });
        } else {
            errorSetter(4);
        }
    };

    const handleLogIn = async (email) => {
        if (DataValidator.isValidEmail(email)) {
            updateIsVisibleSpinner(true);
            let postBody = {
                email,
                receiveNotifications: true,
                ref: UserData.getItem(userDataKeys.referrer_code),
                platformName: Platform.getPlatformName()
            };
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(captchaSiteKey, { action: 'submit' }).then(function (token) {
                    postBody.captchaToken = token;
                    updateIsVisibleSpinner(true);
                    let req = ApiFacade.loginWithEmail(postBody);
                    req.then(res => {
                        updateIsVisibleSpinner({ isVisible: false });
                        if (res.ok) {
                            setRegEmail(email);
                        } else {
                            errorSetter(5);
                        }
                    }).catch(() => {});
                });
            });
        } else {
            errorSetter(5);
        }
    };

    const handleVerification = () => {
        updateIsVisibleSpinner({ isVisible: true });
        ApiFacade.processVerification({ registerToken: confirmationCode, navigate, updateIsVisibleSpinner });
    };

    useEffect(() => {

        if (!Redirects.thisPageIsGoingToRedirectToDifferentLanguage() && confirmationCode) {
            handleVerification();
        }
    }, []);


    if (variant === 'short') {
        return (
            (regEmail || showSignUp) ?
                <SignupInput handler={handleEmailRegister} text={Content.getValue('sign_up_cta')} />
                :
                (<>

                    {
                        (isGmail(regEmail)) ?
                            <a
                                href='https://mail.google.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className="join-treatcoin-button margin-top-20 flex-row-centered ">
                                <img src={Images.imageURL('gmail-icon.png')} width="22" className='margin-right-10' />
                                {Content.getValue('open')} gmail
                            </a>
                            : <p className='join-content-desc bold margin-top-20 '>
                                {Content.getValue("email_verification_check_email_app")}
                            </p>
                    }
                </>));
    } else if (variant === 'signup-page') {
        return (!regEmail) ? (
            <>
                <BgDecor addedClassNames={'login'} />
                <div className='join-content-signup'>
                    <h4 className="join-content-heading margin-bottom-20 dark-blue ">{Content.getValue("sign_up_to")}
                        <span className='text-purple'>Treat</span>
                        <span className='text-pink'>Coin</span>
                    </h4>
                    <SignupInput error={error} text={Content.getValue('sign_up_cta')} handler={handleEmailRegister} />
                    {
                        (UserData.getItem(userDataKeys.email_signup) !== emailSignupModes.join_button) ? (
                            <p className='join-content-desc dark-blue margin-bottom-10'>
                                {Content.getValue('already_have_an_account')} &nbsp;
                                <RedirectWithLang
                                    to={configuration.paths.login}
                                    props={{
                                        className: "join-content-desc-link "
                                    }}
                                    text={Content.getValue('login_button_text')}>
                                </RedirectWithLang>
                            </p>) : (null)
                    }
                    <p className=" join-signup-policy-desc ">{Content.getValue('signup_policy')}
                        <RedirectWithLang
                            to={configuration.paths.terms_of_use}
                            props={{
                                className: " join-signup-policy-desc-link"
                            }}
                            text={Content.getValue('menu_title_terms_of_use')}>
                        </RedirectWithLang>,&nbsp;
                        <RedirectWithLang
                            to={configuration.paths.privacy_policy}
                            props={{
                                className: " join-signup-policy-desc-link"
                            }}
                            text={Content.getValue('menu_title_privacy_policy')}>
                        </RedirectWithLang>&nbsp;
                        <RedirectWithLang
                            to={configuration.paths.cookie_policy}
                            props={{
                                className: " join-signup-policy-desc-link"
                            }}
                            text={Content.getValue('menu_title_cookie_policy')}>
                        </RedirectWithLang>.
                    </p>
                </div >
            </>
        ) :
            (<>
                <BgDecor addedStyles={{ bottom: regEmail ? '200px' : '-30px' }} />
                <div className={`join-content signup-page ${(registerEmail && !regEmail) ? 'join-with-reg-email' : ''}`} >
                    <img src={Images.imageURL('mail_signup.png')} width="60" className='margin-bottom-15' />
                    <h4 className="join-content-heading margin-bottom-5">{Content.getValue("email_verification")}</h4>
                    <p className='join-content-desc'>
                        {Content.getValue("email_verification_desc")}
                        <span className="bold grey-blue">{regEmail}</span>
                    </p>

                    {
                        (isGmail(regEmail)) ?
                            <a
                                href='https://mail.google.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className="join-treatcoin-button margin-top-20 flex-row-centered ">
                                <img src={Images.imageURL('gmail-icon.png')} width="22" className='margin-right-10' />
                                {Content.getValue('open')} gmail
                            </a>
                            : <p className='join-content-desc bold margin-top-20 '>
                                {Content.getValue("email_verification_check_email_app")}
                            </p>
                    }
                </div></>);
    }
    else {
        return (
            <>
                {
                    isLogin ?
                        <BgDecor addedClassNames={'login'} />
                        : <></>
                }
                <div className={`join-content 
            ${(registerEmail && !regEmail) ? 'join-with-reg-email' : 'bottom-45'}
            ${isLogin ? 'bottom-165' : ''}
            bottom-240-iOS
            `} >

                    <div className="treatcoin-description">
                        {
                            (registerEmail && !regEmail) ? <>
                                <h4 className="join-content-heading margin-bottom-20">{Content.getValue("log_in_to")}
                                    <span className='text-purple'>Treat</span>
                                    <span className='text-pink'>Coin</span>
                                </h4>

                                <SignupInput error={error} handler={handleLogIn} text={Content.getValue('login_button_text')} />
                                <p className='join-content-desc'>
                                    {Content.getValue('dont_have_an_account_yet')} &nbsp;
                                    <RedirectWithLang
                                        to={configuration.paths.email_signup}
                                        props={{
                                            className: "join-content-desc-link "
                                        }}
                                        text={Content.getValue('sign_up_cta')}>
                                    </RedirectWithLang>
                                </p>
                            </> :


                                (regEmail) ?
                                    (<>
                                        <img src={Images.imageURL('mail_signup.png')} width="60" className='margin-bottom-15' />
                                        <h4 className="join-content-heading margin-bottom-5">{Content.getValue("email_verification")}</h4>
                                        <p className='join-content-desc'>
                                            {Content.getValue("email_verification_desc")}
                                            <span className="bold grey-blue">{regEmail}</span>
                                        </p>
                                        {
                                            (isGmail(regEmail)) ?
                                                <a
                                                    href='https://mail.google.com/'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className="join-treatcoin-button margin-top-20 flex-row-centered ">
                                                    <img src={Images.imageURL('gmail-icon.png')} width="22" className='margin-right-10' />
                                                    {Content.getValue('open')} gmail
                                                </a>
                                                : <p className='join-content-desc bold margin-top-20 grey-blue'>
                                                    {Content.getValue("email_verification_check_email_app")}
                                                </p>
                                        }
                                    </>)

                                    : variant !== "expanded" ? (<>
                                        <h4 className="join-content-heading">{Content.getValue("sign_up_to")}
                                            <span className='text-purple'>Treat</span>
                                            <span className='text-pink'>Coin</span>
                                        </h4>
                                        <p className='margin-bottom-20' dangerouslySetInnerHTML={{ __html: Content.getValue("intro_content_text") }}></p>

                                        <SignupInput error={error} text={Content.getValue('sign_up_cta')} handler={handleEmailRegister} />
                                    </>) : <>
                                        <h4 className="join-content-heading margin-bottom-20">{Content.getValue("log_in_to")}
                                            <span className='text-purple'>Treat</span>
                                            <span className='text-pink'>Coin</span>
                                        </h4>

                                        <SignupInput error={error} handler={handleLogIn} text={Content.getValue('login_button_text')} />
                                        <p className='join-content-desc'>
                                            {Content.getValue('dont_have_an_account_yet')} &nbsp;
                                            <RedirectWithLang
                                                to={configuration.paths.email_signup}
                                                props={{
                                                    className: "join-content-desc-link "
                                                }}
                                                text={Content.getValue('sign_up_cta')}>
                                            </RedirectWithLang>
                                        </p>
                                    </>
                        }
                    </div>
                    {(registerEmail && !regEmail) ?
                        <div className='join-login-buttons-container ' style={{
                            display: isLogin ? 'inline-block' : 'none'
                        }}>
                            <button
                                className="login-with-button"
                                onClick={() => document.getElementById("google-signup").click()}
                            >
                                <img src={Images.imageURL('login-google-icon.png')} alt="Google" width="19" height="22" />
                                <span>{Content.getValue("login_with_google_button_text")}</span>
                            </button>
                            {/*
                            <button
                                className="login-with-button" >
                                <a href={facebookLoginUrl}>
                                    <img src={Images.imageURL('login-facebook-icon.png')} alt="Facebook" width="14" height="22" />
                                    <span>{Content.getValue("login_with_facebook_button_text")}</span>
                                </a>
                            </button>
                            */}
                            <button
                                className="login-with-button" >
                                <div onClick={async (event) => { event.preventDefault(); await window.startTelegramLogin(); }}>
                                    <img src={Images.imageURL('login-telegram-icon.png')} alt="Telegram" width="20" height="22" />
                                    {Content.getValue("login_with_telegram_button_text")}
                                </div>
                            </button>
                            <button
                                className="login-with-button" >
                                <a href={discordLoginUrl}>
                                    <img src={Images.imageURL('login-discord-icon.png')} alt="Telegram" width="20" height="22" />
                                    {Content.getValue("login_with_discord_button_text")}
                                </a>
                            </button>
                        </div>
                        : <></>}
                </div></>
        );
    }

}

export default Join;