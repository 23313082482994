import React, { Component } from 'react';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';

const configuration = require('configuration.json');
const privacyPolicyPath = configuration.paths.privacy_policy;

class CookieText extends Component {


    render() {

        return (
            <div>
                <p className="legal-last-updated">
                    <span className="bold-text">Last Updated:</span> October 2023
                </p>
                <div className="content-subtitle top-title">
                    1.	Introduction
                </div>

                <p className="long-content">
                    When you visit or access our websites, or when you interact or engage with our content ("Services"), we use (and authorize third parties to use) cookies, pixels, beacons, local storage and similar technologies (<span className="bold-text">"Tracking Technologies"</span>).
                </p>

                <p className="long-content">
                    These allow us to automatically collect information about you, your device, and your online behavior, in order to enhance your navigation on our Services, improve our Services' performance, perform analytics, customize your experience and offer you, for example, tailored content and advertisements that better correspond with your interests.
                </p>
                <p className="long-content">
                    This Cookie Policy is integrated into and forms part of Company's
                    <RedirectWithLang
                        to={privacyPolicyPath}
                        props={{
                            className: "legal-link"
                        }}> Privacy Policy</RedirectWithLang>.
                </p>

                <div className="content-subtitle top-title">
                    2.	What are cookies?
                </div>
                <p className="long-content">
                    Cookies are small text files (composed only of letters and numbers) that a web server places on your computer or mobile device when you visit a webpage. When used, the cookie can help make our Services more user-friendly, for example by remembering your language preferences and settings. You can find more information about cookies at  <a target="_blank" className="legal-link" href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.
                </p>
                <p className="long-content">
                    Cookies are widely used in order to make websites work in an efficient way. The use of cookies allows you to navigate between pages efficiently. Cookies remember your preferences, and make the interaction between you and the Services smoother and more efficient. Cookies are also used to help ensure that the advertisements you see online are relevant to you and your interests.
                </p>
                <div className="content-subtitle top-title">
                    3. Storing Tracking Technologies
                </div>
                <p className="long-content">
                    We store Tracking Technologies when you visit or access our Services (for example when you are visiting our Website)   these are called "First Party Tracking Technologies". In addition, Tracking Technologies are stored by other third parties (for example our analytics service providers, business partners and advertisers) who run content on our Services   these are called "Third Party Tracking Technologies".
                </p>
                <p className="long-content">
                    Both types of Tracking Technologies are stored either for the duration of your visit on our Services or for repeat visits.
                </p>
                <div className="content-subtitle top-title">
                    4.	What types of Tracking Technologies do we use?
                </div>
                <p className="long-content">
                    When you use or access our Services, we use the following categories of Tracking Technologies:
                </p>
                <ul className="legal-list">
                    <li>
                        <p className="long-content">
                            <span className="underlined-text">Strictly Necessary Tracking Technologies</span> - these Tracking Technologies are automatically placed on your computer or device when you access our Services. These Tracking Technologies are essential to enable you to navigate around and use the features of our Services. We do not need to obtain your consent in order to use these Tracking Technologies;
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            <span className="underlined-text">Tracking and advertising Tracking Technologies</span> - these Tracking Technologies collect information about your browsing habits and are used to make advertising more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            <span className="underlined-text">Functionality Tracking Technologies</span> - these Tracking Technologies allow our Services to remember choices you make (such as your language) and provide enhanced and personalized features. For example, these Tracking Technologies are used for authentication (to remember when you are logged-in) and support other features of our Services;
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            <span className="underlined-text">Performance Tracking Technologies</span> - these Tracking Technologies collect information about your online activity (for example the duration of your visit on our Services), including behavioral data and content engagement metrics. These Tracking Technologies are used for analytics, research and to perform statistics (based on aggregated information).
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            <span className="underlined-text">Social media Tracking Technologies</span> - Our website includes social media features, such as the Facebook "Like" or "Share" buttons. These features are either hosted by a third party or hosted directly on our Services. Your interactions with these features are governed by the privacy statement of the company providing these features.
                        </p>
                    </li>

                </ul>
                <p className="long-content">
                    The following Tracking Technologies are used in connection with our Services. For additional information about any of the Third Party Tracking Technologies, please visit the links of those companies provided.
                </p>
                <table className="legal-table" cellSpacing="0" cellPadding="0">
                    <colgroup>
                        <col span="1" className="cookie-tracking-name-column" />
                        <col span="1" className="cookie-tracking-type-column" />
                        <col span="1" className="cookie-tracking-purpose-column" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Tracking Technologies</th>
                            <th>Type</th>
                            <th>Purpose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Analytics Cookies</td>
                            <td>First party Tracking Technology</td>
                            <td><p><b>Performance and Functionality Tracking Technologies</b></p>
                                <p>These Tracking Technologies are used to collect information regarding how you interact with the content on our Services, attribution purposes (for example, the referral URL), and to remember your language preferences and authentication (remember you are logged-in).</p>
                                <p>We use the information to compile reports, calculate the revenues due to us, help us improve the Services and to offer personalized products and content. </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Other cookies</td>
                            <td>First and Third party Tracking Technology</td>
                            <td><p><b>Necessary Tracking Technologies</b></p>
                                These unlisted cookies might be in use on internal sections of the Services, in order to customize and simplify the user experience on the site by remembering choices you made and your log in credentials.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="long-content">
                    <span className="underlined-text">Cookies allow us to:</span>
                </p>
                <ul className="legal-list">
                    <li>
                        <p className="long-content">
                            Improve the use of the Service and to make the content more interesting and relevant to the wishes of users, like offering you more relevant promotional offers.
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            Distinguish you from other users of our sites to grant a good user experience.
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            Identify your settings and preferences regarding customized content or functionality.
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            Your log-in details for the confirmation as a registered user for our Service.
                        </p>
                    </li>
                    <li>
                        <p className="long-content">
                            Collect statistical analysis on the use of our Service.
                        </p>
                    </li>
                </ul>


                <div className="content-subtitle top-title">
                    5.	How to manage and control Tracking Technologies
                </div>
                <p className="long-content">
                    There are various ways in which you can manage and control your Tracking Technologies settings. Please note that we do not recognize or respond to automated browser signals regarding Tracking Technologies, including "Do Not Track" requests. However, there are various methods of managing your Tracking Technology preferences includes: setting your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. Please note that certain features of the Services could not work properly or effectively if you delete or disable cookies or other tracking technologies.
                </p>
                <p className="long-content">
                    To learn more about how can manage your cookies, below is a list of useful links that can provide you with more information on how to manage your cookies:
                </p>
                <ul className="legal-list">
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://support.microsoft.com/en-us/help/260971/description-of-cookies">Internet Explorer</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari (Desktop)</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://support.apple.com/en-us/HT201265">Safari (Mobile)</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://support.google.com/nexus/answer/54068?visit_id=637249861772874734-2281104728&hl=en&rd=1">Android Browser</a></p>
                    </li>
                </ul>
                <p className="long-content">
                    You can learn more and turn off certain third party targeting and advertising cookies by visiting the following third-party webpages:
                </p>
                <ul className="legal-list">
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://www.iab.com/">The Interactive Advertising Bureau (US)</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://iabeurope.eu/">The Interactive Advertising Bureau (EU)</a></p>
                    </li>
                    <li>
                        <p className="list-item"><a target="_blank" className="legal-link" href="https://www.youronlinechoices.com/">European Interactive Digital Advertising Alliance (EU)</a></p>
                    </li>
                </ul>
            </div>
        );
    }

}

export default CookieText;