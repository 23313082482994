import React from 'react';
import Images from 'utils/Images';
import Content from 'utils/Content';

function StatsRow({ gen, refCount, earned }) {
    return (
        <div className='stats-row'>
            <div className="stats-card">
                <div className="stats-image-container" style={{ backgroundImage: `url(${Images.imageURL(`referrals-page/team-stats-${gen}.png`)})` }}>
                </div>
                <p className="stats-row-desc">{Content.getValue(`${gen}_gen_ref`)}</p>
                <p className="stats-row-ref-count">{refCount}</p>
            </div>
            <div className="stats-card">
                <div className="stats-image-container" style={{ backgroundImage: `url(${Images.imageURL(`referrals-page/team-stats-${gen}.png`)})` }}>
                    <img
                        className='stats-row-ref-image-tc'
                        src={Images.imageURL('stats-header-money-icon-new.png')} alt="" />
                </div>
                <p className="stats-row-desc">{Content.getValue(`earned_${gen}_gen_ref`)}</p>
                <div className="flex">
                    <img
                        className='stats-row-ref-earnings-count-tc'
                        src={Images.imageURL('stats-header-money-icon-new.png')} alt="" />
                    <p className="stats-row-ref-earnings-count">
                        {earned}</p>
                </div>
            </div>
        </div>
    );
}

export default StatsRow;