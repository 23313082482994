import Images from 'utils/Images';

import UserData from 'User_Data.js';
import Formatting from 'utils/Formatting.js';
import { useEffect } from 'react';
import { useState } from 'react';
import ApiFacade from 'ApiFacade.js';
import Content from 'utils/Content.js';
import Platform from 'utils/Platform';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;

function LoggedInHeading(props) {

    const { layout } = props
    const [coins, setCoins] = useState(null)

    const currentYear = new Date().getUTCFullYear();
    const currentMonth = new Date().getUTCMonth() + 1;
    const coinsYear = currentYear;
    const coinsMonth = currentMonth;
    const minutesOffset = new Date().getTimezoneOffset() * (-1);
    
    useEffect(() => {
        let platform = Platform.getPlatformName();
        let req = ApiFacade.myEarningsHistory({ coinsMonth, coinsYear, minutesOffset, platform, excludeList: 1 });
        req.then(function (res) {
            if (res.ok){
                const result = res.response;
                setCoins({
                    isLoaded: true,
                    filter: null,
                    availableForWithdrawal: result.availableForWithdrawal,
                });
            } else if (res.unauthorized){
                setCoins({
                    isLoaded: true,
                    isRestricted: true
                });
            } else {
                setCoins({
                    isLoaded: true,
                    error: res.error
                });
            }
        },
        (error) => {
            setCoins({
                isLoaded: true,
                error
            });
        }
        );
    }, [])

    return (
        <div className={`logged-in-heading logged-in-heading-layout-${layout}`}>
            <p className='logged-in-heading-nickname'>{UserData.getItem(userDataKeys.username)}</p>
            <img src={Images.imageURL('stats-header-money-icon-new.png')}
                width='20' height='20'
                alt="Burger" className='' />
            <p>{Formatting.formatTreatCoinAmount(coins?.availableForWithdrawal)}</p>
            <p className="logged-in-heading-tooltip">{Content.getValue('header_tooltip')}</p>
        </div>
    );
}

export default LoggedInHeading;