import ReportFormError from 'components/offers/popup/Report_Form_Error.js';
import { useRef, useState } from 'react';
import Content from 'utils/Content.js';
import Formatting from 'utils/Formatting.js';
import Images from 'utils/Images.js';

function ReportInput({
  type,
  label,
  value,
  onChange,
  options,
  placeholder,
  dropValue,
  yesNoScreenShot,
  disabled,
  images,
  mandatory,
  fileId,
}) {
  const inputRef = useRef(null);
  const [fileTypeModalOpened, setFileTypeModalOpened] = useState(false);
  const [fileType, setFileType] = useState('');
  const [typeError, setTypeError] = useState(null);

  const currentFile = images?.find(image => image['id'] === fileId);

  const handleInputClick = () => {
    inputRef.current.click();
  };

  const Checkbox = ({ label, value, type }) => (
    <div className='flex justify-between '>
      <div className='offer-popup-report-input checkbox margin-bottom-0'>
        <label className='offer-popup-dark-text-12'>{label}</label>
        <label className='offer-popup-report-checkbox-label'>
          <input
            className='offer-popup-report-yes-no-toggle-checkbox'
            type="checkbox"
            value={value}
            onChange={() => {
              setFileType(type);
              setTimeout(() => {
                setFileTypeModalOpened(false);
              }, 200);
            }}
          />
          <div className='margin-top_15'>
            <div className='wh-31px'>
              {(fileType !== type) &&
                <img width={21} height={21} src={Images.imageURL('checkbox-circle-dark4x.png')} alt="" />
              }
              <img
                width={21} height={21}
                className={`offer-popup-report-checked ${fileType === type ? 'displayed' : ''}`}
                src={Images.imageURL('checkbox-circle-checked_dark4x.png')} alt="" />
            </div>
          </div>
        </label>
      </div>
    </div>
  );

  const File = () => {
    return (
      <div className={`offer-popup-report-input ${currentFile ? '' : 'button-upload-bordered'}`}>
        <label className='offer-popup-grey-text-12'>{label}</label>
        <div className="offer-popup-report-input-file-outer">
          <button
            onClick={handleInputClick}
            className='offer-popup-report-input-file-inner button-hidden'>
            {currentFile ?
              <span className='offer-popup-white-heading-14'>{Formatting.shortenText(currentFile?.file?.name, 8)}</span>
              :
              <>
                <span className='offer-popup-white-heading-14'>{Content.getValue('upload_file')}</span>
                <img
                  width={16} height={18}
                  src={Images.imageURL('attach-file-icon.png')} alt="Arrow" />
              </>
            }
            <input
              accept=".jpeg, .jpg, .png"
              ref={inputRef}
              className='offer-popup-report-input-file'
              type="file" onChange={async (e) => {
                if (fileType) {
                  const file = e.target.files[0];
                  if (images?.some(image => image.file.name === file.name)) {
                    setTypeError(3);
                    setTimeout(() => {
                      setTypeError(null);
                    }, 2000);
                  } 
                    await onChange(fileType, file, fileId);
                  
                } else {
                  setTypeError(1);
                  setTimeout(() => {
                    setTypeError(null);
                  }, 2000);
                }
              }} />
          </button>
          {currentFile ?
            <button className="button-hidden drop-value-button"
              onClick={() => {
                dropValue(fileId);
              }}
            >
              <img width={24} height={24} src={Images.imageURL('close-welcome.png')} alt="Close" />
            </button>
            :
            <></>
          }
        </div>

      </div>
    );
  };


  switch (type) {
    case 'short-text':
      return (
        <div className={`offer-popup-report-input ${disabled ? 'disabled' : ''}`}>
          <label className='offer-popup-grey-text-12'>{label}</label>
          <input
            disabled={disabled}
            placeholder={placeholder}
            className='offer-popup-report-input-text'
            type="text"
            value={value}
            onChange={e => onChange(e.target.value)} />
        </div>
      );

    case 'email':
      return (
        <div className='offer-popup-report-input'>
          <label className='offer-popup-grey-text-12'>{label}</label>
          <input
            disabled={disabled}
            placeholder={placeholder}
            className='offer-popup-report-input-text'
            type="text"
            value={value}
            onChange={e => onChange(e.target.value)} />
          {/* {!DataValidator.isValidEmail(value) &&
            <p className="offer-popup-report-input-email-hint">{Content.getValue('invalid_email')}</p>
          } */}
        </div>
      );

    case 'long-text':
      return (
        <div className='offer-popup-report-input'>
          <label className='offer-popup-grey-text-12'>{label}</label>
          <textarea
            maxLength={160}
            className='offer-popup-report-input-textarea'
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)} />
        </div>
      );

    case 'yes-no-toggle':
      return (
        <div className='offer-popup-report-input'>
          <label className='offer-popup-grey-text-12'>{label}</label>
          <div className="offer-popup-report-yes-no-toggle">
            <label className='offer-popup-report-yes-no-toggle-label'>
              <input
                className='offer-popup-report-yes-no-toggle-checkbox'
                type="checkbox"
                value={1}
                checked={value === 1}
                onChange={() => {
                  onChange(1);
                }}
              />
              <span className='wh-21px'>
                {(value === 0 || value === '') &&
                  <img
                  src={Images.imageURL('checkbox-circle4x.png')} alt="" />
                }
                <img
                  width={21} height={21}
                  className={`offer-popup-report-checked ${value === 1 ? 'displayed' : ''}`}
                  src={Images.imageURL('checkbox-circle-checked4x.png')} alt="" />
              </span>
              {Content.getValue('yes')}
            </label>
            <label className='offer-popup-report-yes-no-toggle-label'>
              <input
                className='offer-popup-report-yes-no-toggle-checkbox'
                type="checkbox"
                value={0}
                checked={value === 0}
                onChange={() => {
                  onChange(0);
                }}
              />
              <span className='wh-21px'>
                {(value === 1 || value === '') &&
                  <img
                  src={Images.imageURL('checkbox-circle4x.png')} alt="" />
                }
                <img
                  width={21} height={21}
                  className={`offer-popup-report-checked ${value === 0 ? 'displayed' : ''}`}
                  src={Images.imageURL('checkbox-circle-checked4x.png')} alt="" />
              </span>
              {Content.getValue('no')}
            </label>
          </div>
          <div className="offer-popup-report-input-file-outer">
            {value === 1 ?

              <button
                onClick={handleInputClick}
                className='offer-popup-report-input-file-inner button-hidden'>
                {
                  yesNoScreenShot ?
                    <span className='offer-popup-white-heading-14 '>{yesNoScreenShot.name}</span>
                    :
                    <>
                      <span className='offer-popup-white-heading-14'>{Content.getValue('report_submit_specific_attach_screenshot')}</span>
                      <img
                        width={16} height={18}
                        src={Images.imageURL('attach-file-icon.png')} alt="Arrow" />
                      <input
                        ref={inputRef}
                        accept="image/png"
                        className='offer-popup-report-input-file'
                        type="file"
                      />
                    </>
                }
              </button>
              : <></>
            }
            {yesNoScreenShot ?
              <button
                onClick={dropValue}
                className="hidden-button offer-popup-report-input-file-remove">
                {Content.getValue('report_submit_proof_payment_remove')}
              </button>
              :
              <></>
            }
          </div>
        </div>
      );

    case 'file':
      return (
        <File />
      );

    case 'dropdown':
      return (
        <div className='offer-popup-report-input'>
          <label className='offer-popup-grey-text-12'>{label}</label>
          <select
            className='offer-popup-report-input-dropdown'
            placeholder={placeholder}
            defaultValue={placeholder}
            value={value} onChange={(e) => {
              onChange(e.target.value);
            }}>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      );

    case 'checkbox':
      return (
        <Checkbox label={label} value={value} />

      );

    case 'choose-file':
      return (
        <div className='offer-popup-report-input-choose-file direction-row-wrap'>
          <button
            onClick={() => setFileTypeModalOpened(prev => !prev)}
            name="File Select" id="" className="offer-popup-report-input-file-select"
          >
            <span >
              {Formatting.shortenText(fileType ? Content.getValue(fileType) : Content.getValue('type'), window.innerWidth < 528 ? 13 : 20)}
            </span>
            <img
              width={13.6}
              height={8.5}
              src={Images.imageURL('chevron_down_dark.png')} alt="" />
            {mandatory ?
              <span className={`offer-popup-report-input-file-select-modal-mandatory absolute ${currentFile ? 'success-color' : ''}`}>{Content.getValue('mandatory')}</span>
              : <></>
            }
          </button>
          <File />
            <ul
            className={`offer-popup-report-input-file-select-modal ${fileTypeModalOpened ? 'displayed' : ''} `}>
              {Array.from({ length: 6 }).map((item, idx) =>
                <li key={`report_file_type_${idx + 1}`}>
                  <Checkbox type={`report_file_type_${idx + 1}`} value={images[fileType]} label={Content.getValue(`report_file_type_${idx + 1}`)} />
                </li>
              )}
          </ul>
          <ReportFormError typeError={typeError} handleClose={() => setTypeError(null)} />
        </div>
      );
    default:
      return null;
  }
}

export default ReportInput;