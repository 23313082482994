import Content from 'utils/Content';
import Images from 'utils/Images';

function ReportFormError({ typeError, handleClose, style }) {
    return (
        <div style={style ? style : {}} className={`offer-popup-report-error ${typeError ? 'displayed' : ''}`}>
            <button className="button-hidden"
                onClick={() => {
                    handleClose();
                }}
            >
                <img width={12} height={12} src={Images.imageURL('close.png')} alt="Close" />
            </button>
            <h5 className="offer-popup-report-error-heading">{Content.getValue(`report_error_${typeError}`)}</h5>
            <p className="offer-popup-report-error-desc">{Content.getValue(`report_error_${typeError}_desc`)}</p>
        </div>
    );
}

export default ReportFormError;