import React from 'react';
import UserData from 'User_Data.js';
import ApiFacade from 'ApiFacade.js';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const languages = configuration.languages;

class Content extends React.Component {

    static storeContent(content) {
        if (content) {
            UserData.setItem(userDataKeys.content, JSON.stringify(content));
        } 
    }

    static clearContent() {
        UserData.removeItem(userDataKeys.content);
    }

    static getContent() {
        return UserData.getItem(userDataKeys.content);
    }

    static getValue(key) {
        let content = JSON.parse(UserData.getItem(userDataKeys.content));
        return content[key];
    }

    static getPreferredLanguage() {
        let language = UserData.getItem(userDataKeys.language);
        if (language === null || language === undefined) {
            UserData.setItem(userDataKeys.language, languages[0].code);
            return languages[0].code;
        } else {
            return language;
        }
    }

    static async updatePreferredLanguage(language) {
        UserData.setItem(userDataKeys.enforce_live_news, 1);
        UserData.setItem(userDataKeys.language, language);
        const { response } = await ApiFacade.fetchResourceContent(language);
        Content.clearContent();
        Content.storeContent(response.content);
    }

}

export default Content;