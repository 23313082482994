import React, { useEffect, useState } from 'react';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';
import ChooseButton from 'components/buttons/Choose_Button';
import ActionButton from 'components/buttons/Action_Button.js';
import ApiFacade from 'ApiFacade.js';

function StockPopup({
    handleOpenStockPopup,
    openStockPopup,
    uniqueID,
    CombinationID,
    AttributeMeaning1,
    AttributeMeaning2,
    earnButtonHandler,
    defaultCombination,
    chosenCombination,
    setChosenCombination,
}) {
    const [chosenOptions, setChosenOptions] = useState(['', ',', '']);
    const [stockData, setStockData] = useState(null);
    const [arr1, setArr1] = useState(null);
    const [arr2, setArr2] = useState(null);
    const [disabledMap, setDisabledMap] = useState({});
    const [disabledValues, setDisabledValues] = useState([]);

    const handleChooseValue = (index, value) => {
        setChosenOptions(prev => {
            const newChosenOptions = [...prev];
            newChosenOptions[index] = value;
            stockData.combinations.forEach(item => {
                if (item.ValuesList.replace(/'/g, '').split(',').includes('' + newChosenOptions[0]) &&
                    item.ValuesList.replace(/'/g, '').split(',').includes('' + newChosenOptions[2])) {
                    setChosenCombination(item);
                }
            });
            return newChosenOptions;
        });
        if (index === 0) {
            setDisabledValues(disabledMap[value]);
        } else if (index === 2 && disabledMap) {
            let findDisabledValue = [];
            Object.keys(disabledMap).forEach(key => {
                if (disabledMap[key] && disabledMap[key].includes(value)) {
                    findDisabledValue.push(+key);
                }
            });
            setDisabledValues(findDisabledValue);
        }
    };

    const getStockOfferData = async (uniqueOfferID) => {
        const res = await ApiFacade.getStockProperties({
            uniqueOfferID,
        });
        setStockData(res.response);
        const combinationsValuesPairs = res.response?.combinations.map(i => i.ValuesList);
        const arrayOne = JSON.parse(res.response?.values[0]['ValuesList']);
        const arrayTwo = JSON.parse(res.response?.values[1]['ValuesList']);
        for (const firstValue of arrayOne) {
            for (const secondValue of arrayTwo) {
                if (!combinationsValuesPairs.includes(`${firstValue.id},${secondValue.id}`)) {
                    setDisabledMap(prev => {
                        const newObj = { ...prev };
                        const valueKeyArray = newObj[firstValue.id];
                        if (!valueKeyArray?.includes(secondValue.id)) {
                            if (valueKeyArray?.length) {
                                newObj[firstValue.id] = [...valueKeyArray, secondValue.id];
                            } else {
                                newObj[firstValue.id] = [secondValue.id];
                            }
                            return newObj;
                        }
                    });
                }
            }
        }
        setArr1(arrayOne);
        setArr2(arrayTwo);

        const combination = res.response?.combinations.find(i => i.ID === CombinationID);
        const values = combination.ValuesList.split(",")
        if (combination) {
            setChosenCombination(combination);
            setChosenOptions([+values[0], ",", +values[1]]);
        } else {
            setChosenCombination(defaultCombination)
        }

    };

    useEffect(() => {
        if (openStockPopup) {
            getStockOfferData(uniqueID);
        }
    }, [openStockPopup])

    useEffect(() => {
        if (Object.keys(disabledMap).length > 0) {
            setDisabledValues(disabledMap[chosenOptions[0]]);
        }
    }, [disabledMap])



    return (
        <div
            onScroll={e => {
                e.stopPropagation();
            }}
            className={` offer-popup-stock-wrapper ${openStockPopup ? 'open' : 'closed'}`}
        >
            {(stockData && arr1 && arr2) ?
            <div className={`offer-popup-stock-body offer-popup-grey-container ${openStockPopup ? 'open' : 'closed'}`}>
                <div className="offer-popup-stock-head">
                    <button
                        onClick={handleOpenStockPopup}
                        className="hidden-button">
                        <img src={Images.imageURL('arrow_back.png')} alt="" />
                    </button>
                    <h5 className='text-white-14-700 margin-bottom-0 margin-top-0'>
                            {Formatting.format(Content.getValue("stock_offers_open_popup"), ["(att1)", "(att2)"], [AttributeMeaning1, AttributeMeaning2])}
                    </h5>
                    <button
                        onClick={handleOpenStockPopup}
                        className="hidden-button">
                        <img src={Images.imageURL('close-welcome.png')} alt="" />
                    </button>
                </div>

                    <div className='offer-popup-stock-list-wrapper' >
                        <h6 className="margin-bottom-10 margin-top-10 text-white-14-500 text-left ">{stockData?.values[0]['Heading']}</h6>
                        <ul className="offer-popup-stock-list">
                            {arr1.map(option => <li key={option.value}>
                                <ChooseButton disabled={(disabledValues?.includes(option.id))} chosen={chosenOptions[0] === option.id} onClick={() => { handleChooseValue(0, option.id); }} text={option.value} />
                            </li>)}
                        </ul>
                    </div>
                    <div className='offer-popup-stock-list-wrapper' >
                        <h6 className="margin-bottom-10 margin-top-10 text-white-14-500 text-left ">{stockData?.values[1]['Heading']}</h6>
                        <ul className="offer-popup-stock-list">
                            {arr2.map((option, index) => <li key={option.value + index}>
                                <ChooseButton disabled={(disabledValues?.includes(option.id))} chosen={chosenOptions[2] === option.id} onClick={() => { handleChooseValue(2, option.id); }} text={option.value} />
                            </li>)}
                        </ul>
                    </div>

                    <ActionButton
                        onClick={() => { earnButtonHandler(chosenCombination.ID)}}
                        classNames={'offer-popup-stock-confirm-button'}
                        content={Formatting.format(Content.getValue('stock_offers_popup_button'), ['{tc_payout}', '{value}'],
                            [chosenCombination?.Coins, `${Formatting.currencySymbol()}${(chosenCombination?.Coins * chosenCombination?.treatCoinValue).toFixed(2) }`])
                        } >
                        <img src={Images.imageURL('offers/stats-header-money-icon-new.png')}
                            width={16}
                            height={16}
                            className='offer-popup-stock-tc' />
                    </ActionButton>
                </div>
                : <></>
            }
        </div>
    );
}

export default StockPopup;