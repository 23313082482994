import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Platform from 'utils/Platform.js';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import Offers from 'utils/Offers.js';
import BlurProvider from 'components/offer_providers/Blur_Provider.js';
import IncompleteOffer from 'components/offers/Incomplete_Offer.js';

function CashbackOffersList({ 
    clickHandler, 
    isSearchVisible, 
    isHomepage,
    isPlayground,
    category: selectedCategory = '' 
}) {
    const [state, setState] = useState({
        error: null,
        isLoaded: false,
        offers: []
    });
    const homepageClass = isHomepage ? "homepage" : "";
    const playgroundClass = isPlayground ? "playground" : "";
    
    const [text, setText] = useState('');
    const [category, setCategory] = useState('');
    const categories = useMemo(() => ({
        1: Content.getValue("cashback_categories")["1"],
        2: Content.getValue("cashback_categories")["2"],
        3: Content.getValue("cashback_categories")["3"],
        4: Content.getValue("cashback_categories")["4"],
        5: Content.getValue("cashback_categories")["5"],
    }), []);

    const generateOffersList = (offers) => {
        let filteredOffers=[], data = [];
        if (text) {
            filteredOffers = offers.filter(({ isCashBack, cashbackCategory, description, appName }) => {
                const lowerDescription = description.toLowerCase().replaceAll(" ","");
                const lowerAppName = appName.toLowerCase().replaceAll(" ","");
                const lowerCashbackCategory = cashbackCategory ? cashbackCategory.toLowerCase().replaceAll(" ",""): null;
                const lowerText = text.toLowerCase().replaceAll(" ","");
                const matchedSearch = (
                    lowerDescription.includes(lowerText) || 
                    lowerAppName.includes(lowerText) ||
                    (lowerCashbackCategory && lowerCashbackCategory.includes(lowerText))
                );

                return category ? 
                    isCashBack && matchedSearch && (cashbackCategory === category)
                    : isCashBack && matchedSearch;
            });
        }
        else if (category)
            filteredOffers = offers.filter(({ isCashBack, cashbackCategory }) => (isCashBack && category === cashbackCategory));
        else if (filteredOffers.length === 0)
            return null;

        data = Offers.getBlurredOfferData(filteredOffers);

        return (
            <div>        
                {
                    data.map(item => (item.length===1) ?
                        (
                            <BlurProvider isCashbackList key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} count={1}>
                                <IncompleteOffer 
                                    key={`_offer-cashback-${item.offers[0].ID}_${Date.now()}` + Math.random()} 
                                    offer={item.offers[0]} 
                                />
                            </BlurProvider>
                        ) :
                            (item.length>1) ?
                            (
                                <BlurProvider isCashbackList key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} count={item.length}>
                                    {item.offers.map(offer => (
                                        <IncompleteOffer 
                                            key={`_offer-cashback-${offer.ID}_${Date.now()}` + Math.random()} 
                                            offer={offer} 
                                        />
                                    ))}
                                </BlurProvider>
                            ) :
                                (
                                    <IncompleteOffer 
                                        key={`_offer-cashback-${item.offers[0].ID}_${Date.now()}` + Math.random()} 
                                        offer={item.offers[0]} 
                                    />
                                )
                    )
                }
            </div>
        );
    }

    const handleArrowClick = value => {
        if (clickHandler) clickHandler(categories[value]);
        else setCategory(categories[value]);
    }

    useLayoutEffect(() => {
        if (selectedCategory) setCategory(selectedCategory);
    }, [selectedCategory])

    useEffect(() => {
        const platform = Platform.getPlatformName();
        let preferredLanguage = Content.getPreferredLanguage();
        let currentCountry = JWTUtils.getJWTCountry();

        if (!isHomepage) {
            const req = ApiFacade.offers(preferredLanguage, currentCountry, platform, 1);
            req.then(function (res) {
                if (res.ok){
                    let result = res.response;
                    setState({
                        isLoaded: true,
                        offers: result.apps
                    });
                } else {
                    setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        }
    }, []);

    return (
        <>
            {
                isSearchVisible ?
                <div className='cashback-search'>
                    <img 
                        src={Images.imageURL('cashback-card-logo.png')} 
                        width="24" 
                        height="24" 
                        alt=""
                    />
                    <input 
                        className='cashback-input'
                        type='text'
                        placeholder={Content.getValue("i_want_to")}
                        value={text}
                        onChange={({ target: { value } }) => setText(value)}
                    />
                </div> : 
                null
            }

            <div className={`desktop list-offers-div-wrapper cashback-wrapper ${isHomepage ? "transparent" : ""} ${homepageClass} ${playgroundClass} w-full`}>
                <div className={`cashback desktop ${homepageClass} ${playgroundClass}`}>
                    <div className={`providers-homepage-menu-container cashback desktop ${homepageClass} ${playgroundClass} ${category ? "selected" : ""}`}>
                        {
                            !text ? 
                                <div className={`menu-container desktop ${homepageClass} ${playgroundClass}`}>
                                    {category === categories[1] || !category?
                                    <>
                                        { category ?
                                        <p className='cashback-subtitle menu'>
                                            { Content.getValue("category") }
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                        onClick={() => handleArrowClick('')}
                                                width="30"
                                                height="20" 
                                                alt=""
                                            />
                                        </p> :
                                        null
                                        }
                                        <div className={`providers-homepage-menu desktop cashback ${homepageClass} ${playgroundClass}`}>
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-1.png')}
                                                alt="logo"
                                                className="margin-top_10"
                                                width="70"
                                                height="110" 
                                                />
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => handleArrowClick(1)}

                                                width="30"
                                                height="20" />
                                            <h2 className="providers-homepage-menu-description">{ categories["1"] }</h2>
                                        </div>
                                    </>
                                    : 
                                    null}
                                    {category === categories[2] || !category?
                                    <>
                                        { category ?
                                        <p className='cashback-subtitle menu'>
                                            { Content.getValue("category") }
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => setCategory('')}
                                                alt="arrow"
                                                width="30"
                                                height="20" 
                                            />
                                        </p> :
                                        null
                                        }
                                        <div className={`providers-homepage-menu desktop cashback ${homepageClass} ${playgroundClass}`}>
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-2.png')}
                                                alt="logo"
                                                width="130"
                                                height="56" />
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => handleArrowClick(2)}
                                                alt="arrow"
                                                width="30"
                                                height="20" />
                                            <h2 className="providers-homepage-menu-description">{ categories["2"] }</h2>
                                        </div>
                                    </>
                                    : 
                                    null}
                                    {category === categories[3] || !category?
                                    <>
                                        { category ?
                                        <p className='cashback-subtitle menu'>
                                            { Content.getValue("category") }
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => setCategory('')}
                                                alt="arrow"
                                                width="30"
                                                height="20" 
                                            />
                                        </p> :
                                        null
                                        }
                                        <div className={`providers-homepage-menu desktop cashback ${homepageClass} ${playgroundClass}`}>
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-3.png')}
                                                width="80"
                                                height="100"
                                                alt="" />
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => handleArrowClick(3)}
                                                alt="arrow"
                                                width="30"
                                                height="20" />
                                            <h2 className="providers-homepage-menu-description">{ categories["3"] }</h2>
                                        </div>
                                    </>
                                    : 
                                    null}
                                    {category === categories[4] || !category?
                                    <>
                                        { category ?
                                        <p className='cashback-subtitle menu'>
                                            { Content.getValue("category") }
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => setCategory('')}
                                                alt="arrow"
                                                width="30"
                                                height="20" 
                                            />
                                        </p> :
                                        null
                                        }
                                        <div className={`providers-homepage-menu desktop cashback ${homepageClass} ${playgroundClass}`}>
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-4.png')}
                                                alt="logo"
                                                width="80"
                                                height="100" />
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => handleArrowClick(4)}
                                                alt="arrow"
                                                width="30"
                                                height="20" />
                                            <h2 className="providers-homepage-menu-description">{ categories["4"] }</h2>
                                        </div>
                                    </>
                                    : 
                                    null}
                                    {category === categories[5] || !category?
                                    <>
                                        { category ?
                                        <p className='cashback-subtitle menu'>
                                            { Content.getValue("category") }
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => setCategory('')}
                                                alt="arrow"
                                                width="30"
                                                height="20" 
                                            />
                                        </p> :
                                        null
                                        }
                                        <div className={`providers-homepage-menu desktop cashback ${homepageClass} ${playgroundClass}`}>
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-5.png')}
                                                alt="logo"
                                                width="70"
                                                height="110" />
                                            <img
                                                src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                onClick={() => handleArrowClick(5)}
                                                alt="arrow"
                                                width="30"
                                                height="20" />
                                            <h2 className="providers-homepage-menu-description">{ categories["5"] }</h2>
                                        </div>
                                    </>
                                    : 
                                    null}
                                </div> :
                            null
                        }
                    </div>

                    {
                        state.offers?.length > 0 && !isHomepage ?
                        <>
                            <div className={`offers-div ${playgroundClass}`}>
                                { (text || category) && <p className='cashback-subtitle'>{ Content.getValue("options") }</p> }
                                {
                                    state.offers.map(item => (
                                        <div key={`app-${item.appName}`} id={`${item.appName.replaceAll(" ", "-").toLowerCase()}-section`}>
                                            { generateOffersList(item.offers) }
                                        </div>
                                    ))
                                }
                            </div>
                        </> :
                        null
                    }
                </div>

            </div>
        </>
    );
}

export default CashbackOffersList;