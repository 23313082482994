import React, { useState, useEffect, useRef, memo } from 'react';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import UserData from 'User_Data.js';

import Content from 'utils/Content.js';
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const uiSettings = configuration.ui_settings;

const animationDuration = uiSettings.user_stats_animation_duration;

const UserStatsHomepage = () => {
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [state, setState] = useState({
        error: null,
        isLoaded: false,
        isRestricted: false,
        coinsEarnedToday: 0,
        myTeamMembers: 0,
        refCount: 0,
        refAmount: 0,
        ref2ndGenCount: 0,
        ref2ndGenAmount: 0,
        competitionValidCoins: 0,
        position: 0,
        availableToUse: 0,
        totalCoinsEarned: 0,
        username: '',
    })

    const earnedRef = useRef();
    const membersRef = useRef();
    const refCountRef = useRef();
    const refAmountRef = useRef();
    const ref2ndGenCountRef = useRef();
    const ref2ndGenAmountRef = useRef();
    const ref3rdGenCountRef = useRef();
    const ref3rdGenAmountRef = useRef();
    const competitionValidCoinsRef = useRef();
    const availableToUseRef = useRef();
    const totalCoinsEarnedRef = useRef();

    const { refCount,
            refAmount,
            ref2ndGenCount,
            ref2ndGenAmount,
            ref3rdGenCount,
            ref3rdGenAmount,
            competitionValidCoins,
            position,
            availableToUse,
            totalCoinsEarned,
            username    } = state;

    const animateHandler = (obj, prevValue, currValue) => {
        if (prevValue < currValue) 
            animateValue(obj, prevValue || 0, currValue || 0, animationDuration);
    }

    const animateValue = (obj, start, end, duration) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            const data = Math.floor(progress * (end - start) + start);
            obj.innerHTML = Formatting.formatTreatCoinAmount(data || 0);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
            else window.cancelAnimationFrame(step)
        };
        window.requestAnimationFrame(step);
    }

    const setLocalStorageVisibleData = ({
        coinsEarnedToday,
        myTeamMembers,
    }) => {
        const ext = JSON.parse(UserData.getItem(userDataKeys.stats));
        const data = {
            ...ext,
            [userDataKeys.stats_values.earned_today]: coinsEarnedToday,
            [userDataKeys.stats_values.team_members]: myTeamMembers,
        }
        UserData.setItem(userDataKeys.stats, JSON.stringify(data));
    }

    const setLocalStorageExpandableData = ({
        refCount,
        refAmount,
        ref2ndGenCount,
        ref2ndGenAmount,
        ref3rdGenCount,
        ref3rdGenAmount,
        competitionValidCoins,
        availableToUse,
        totalCoinsEarned,
        username
    }) => {
        const ext = JSON.parse(UserData.getItem(userDataKeys.stats));
        const data = {
            ...ext,
            [userDataKeys.stats_values.ref_count]: refCount,
            [userDataKeys.stats_values.ref_amount]: refAmount,
            [userDataKeys.stats_values.ref_2nd_gen_count]: ref2ndGenCount,
            [userDataKeys.stats_values.ref_2nd_gen_amount]: ref2ndGenAmount,
            [userDataKeys.stats_values.ref_3rd_gen_count]: ref3rdGenCount || 0,
            [userDataKeys.stats_values.ref_3rd_gen_amount]: ref3rdGenAmount || 0,
            [userDataKeys.stats_values.competition_valid_coins]: competitionValidCoins,
            [userDataKeys.stats_values.available_to_use]: availableToUse,
            [userDataKeys.stats_values.total_coins_earned]: totalCoinsEarned,
            [userDataKeys.stats_values.username]: username
        };
        UserData.setItem(userDataKeys.stats, JSON.stringify(data));
    }

    useEffect(() => {
        if (ApiFacade.isLoggedIn()) {
            const req = ApiFacade.userStats();
            req.then(function (res) {
                if (res.ok){
                    let result = res.response;
                    setState({
                        isLoaded: true,
                        ...result.userStats,
                    });

                    const stats = JSON.parse(UserData.getItem(userDataKeys.stats));
                    const prevCoinsEarnedToday = stats?.[userDataKeys.stats_values.earned_today] || 0;
                    const prevMyTeamMembers = stats?.[userDataKeys.stats_values.team_members] || 0;

                    const { coinsEarnedToday, myTeamMembers } = result.userStats;

                    if (prevCoinsEarnedToday < coinsEarnedToday) 
                        animateValue(earnedRef.current, prevCoinsEarnedToday, coinsEarnedToday, animationDuration);
                    if (prevMyTeamMembers < myTeamMembers) 
                        animateValue(membersRef.current, prevMyTeamMembers, myTeamMembers, animationDuration);

                    setLocalStorageVisibleData(result.userStats);
                } else if (res.unauthorized){
                    setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                }
                else {
                    setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
                (error) => {
                    setState({
                        isLoaded: true,
                        error
                    });
                }
            )
            .catch(error => setState({ isLoaded: true, error }))
        }
    }, []);

    useEffect(() => {
        if (isDetailsVisible) {
            const stats = JSON.parse(UserData.getItem(userDataKeys.stats));
            const prevRefCount = +(stats?.[userDataKeys.stats_values.ref_count] || 0);
            const prevRefAmount = +(stats?.[userDataKeys.stats_values.ref_amount] || 0);
            const prevRef2ndGenCount = +(stats?.[userDataKeys.stats_values.ref_2nd_gen_count] || 0);
            const prevRef2ndGenAmount = +(stats?.[userDataKeys.stats_values.ref_2nd_gen_amount] || 0);
            const prevRef3rdGenCount = +(stats?.[userDataKeys.stats_values.ref_3rd_gen_count] || 0);
            const prevRef3rdGenAmount = +(stats?.[userDataKeys.stats_values.ref_3rd_gen_amount] || 0);
            const prevCompetitionValidCoins = +(stats?.[userDataKeys.stats_values.competition_valid_coins] || 0);
            const prevAvailableToUse = +(stats?.[userDataKeys.stats_values.available_to_use] || 0);
            const prevTotalCoinsEarned = +(stats?.[userDataKeys.stats_values.total_coins_earned] || 0);

            animateHandler(refCountRef.current, prevRefCount, refCount);
            animateHandler(refAmountRef.current, prevRefAmount, refAmount);
            animateHandler(ref2ndGenCountRef.current, prevRef2ndGenCount, ref2ndGenCount);
            animateHandler(ref2ndGenAmountRef.current, prevRef2ndGenAmount, ref2ndGenAmount);
            animateHandler(ref3rdGenCountRef.current, prevRef3rdGenCount, ref3rdGenCount);
            animateHandler(ref3rdGenAmountRef.current, prevRef3rdGenAmount, ref3rdGenAmount);
            animateHandler(competitionValidCoinsRef.current, prevCompetitionValidCoins, competitionValidCoins);
            animateHandler(availableToUseRef.current, prevAvailableToUse, availableToUse);
            animateHandler(totalCoinsEarnedRef.current, prevTotalCoinsEarned, totalCoinsEarned);

            setLocalStorageExpandableData({
                refCount,
                refAmount,
                ref2ndGenCount,
                ref2ndGenAmount,
                ref3rdGenCount,
                ref3rdGenAmount,
                competitionValidCoins,
                availableToUse,
                totalCoinsEarned,
                username
            })
        }
    }, [isDetailsVisible]);

    return (
        <div className='user-stats new'>
            <div className='user-stats-header-container homepage desktop new' ref={null}>
                <div className='user-stats-header desktop new white-i'>
                    <p>{ Content.getValue("coins_earned_today") }</p>
                    <div className='user-stats-header-logos new'>
                        <img 
                            src={Images.imageURL('stats-header-money-icon-new.png')}
                            alt='Coins earned today'
                            className='coin'
                            width='27'
                            height='27'
                        />
                        <span ref={earnedRef}>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.earned_today] || 0) }
                        </span>
                    </div>
                </div>
                <div className='user-stats-header desktop new white-i'>
                    <p>{ Content.getValue("my_team_members") }</p>
                    <div className='user-stats-header-logos new'>
                        <img
                            src={Images.imageURL('stats-header-user-icon-new.png')}
                            alt='My team members'
                            className='user'
                            width='20'
                            height='26' 
                            />
                        <span ref={membersRef}>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.team_members] || 0) }
                        </span>
                    </div>
                </div>
            </div>
            <div className='user-stats-expandable new'>
                <button
                    onClick={() => setIsDetailsVisible(prev => !prev)}
                    className="button-hidden user-stats-expandable new">
                    <img
                        src={isDetailsVisible ? Images.imageURL('stats-arrow-up.png') : Images.imageURL('stats-arrow-down.png')}
                        alt='Expand / Collapse'
                        width='45'
                        height='28' />
                </button>
                                {isDetailsVisible ?
            (
                <div className='stats-component-texts new'>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("first_gen_ref") }</span>
                        <span>
                            #
                            <span ref={refCountRef}>
                                { Formatting.formatTreatCoinAmount(
                                    JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                        [userDataKeys.stats_values.ref_count] || 0) }
                            </span>
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{Content.getValue("total_tc_earned") }</span>
                        <span ref={refAmountRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.ref_amount] || 0) }
                        </span>
                    </div>
                    <hr />
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("second_gen_ref") }</span>
                        <span>
                            #
                            <span ref={ref2ndGenCountRef}>
                                { Formatting.formatTreatCoinAmount(
                                    JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                        [userDataKeys.stats_values.ref_2nd_gen_count] || 0) }
                            </span>
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("total_tc_earned") }</span>
                        <span ref={ref2ndGenAmountRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.ref_2nd_gen_amount] || 0) }
                        </span>
                    </div>
                    <hr />
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("third_gen_ref") }</span>
                        <span>
                            #
                            <span ref={ref3rdGenCountRef}>
                                { Formatting.formatTreatCoinAmount(
                                    JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                        [userDataKeys.stats_values.ref_3rd_gen_count] || 0) }
                            </span>
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("total_tc_earned") }</span>
                        <span ref={ref3rdGenAmountRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.ref_3rd_gen_amount] || 0) }
                        </span>
                    </div>
                    <hr />
                    <div className='stats-component-text-item'>
                        <span>{Content.getValue("competition_valid_coins") + ` (#${Formatting.formatTreatCoinAmount(position)})` }</span>
                        <span ref={competitionValidCoinsRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.competition_valid_coins] || 0) }
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("available_to_use") }</span>
                        <span ref={availableToUseRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.available_to_use] || 0) }
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("total_tc_earned") }</span>
                        <span ref={totalCoinsEarnedRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.total_coins_earned] || 0) }
                        </span>
                    </div>
                </div>
            ) :
            ( null )
            }
            </div>
        </div>
    )
};

export default memo(UserStatsHomepage);