import React from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import { BottomSVG, UpperSVG } from './svgs.js';
import { InfoCard } from './Info_Card.js';
import StatsRow from './Stats_Row.js';

function MyTeamInfoModal({ openHowItWorks, onClose, type, userStats }) {

    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                if (e.target.classList[0] === 'how-it-works-popup') {
                    onClose(e);
                }
            }}
            className={`how-it-works-popup ${openHowItWorks ? ' opened' : ''}`}>
            <div className="how-it-works-body">
                <UpperSVG />
                <BottomSVG />
                <button
                    onClick={onClose}
                    className="hidden-button close-button">
                    <img src={Images.imageURL('close-account-suspended.png')} alt="" />
                </button>
                <h4 className="how-it-works-heading">{Content.getValue(type === 'stats' ? 'menu_title_my_team_referrals' : 'how_it_works')}</h4>
                {type === 'stats' ? <>
                    <StatsRow refCount={userStats.refCount} earned={userStats.refAmount} gen={'first'}/>
                    <StatsRow refCount={userStats.ref2ndGenCount} earned={userStats.ref2ndGenAmount} gen={'second'}/>
                    <StatsRow refCount={userStats.ref3rdGenCount} earned={userStats.ref3rdGenAmount} gen={'third'}/>
                </> : <>
                    <img className='how-it-works-line' src={Images.imageURL('referrals-page/connection-line.png')} alt="" />
                    <InfoCard
                        headingKey={'send_link_friends_head'}
                        textKey={'send_link_friends_desc'}
                        icon={'referrals-page/send-link.png'} />
                    <InfoCard
                        headingKey={'menu_title_build_your_team'}
                        textKey={'build_team_desc'}
                        icon={'referrals-page/team.png'} />
                    <InfoCard
                        headingKey={'grow_connections_head'}
                        textKey={'grow_connections_desc'}
                        icon={'referrals-page/add-members.png'} />
                    <InfoCard
                        headingKey={'earn_money_head'}
                        textKey={'earn_money_desc'}
                        icon={'referrals-page/earm-money.png'} />
                </>}
            </div>
        </div>
    );
}

export default MyTeamInfoModal;